"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateLayout = exports.getLayouts = exports.getLayout = exports.deleteLayout = exports.createLayout = exports.UPDATE_LAYOUT_SUCCESS = exports.UPDATE_LAYOUT_REQUEST = exports.UPDATE_LAYOUT_FAILURE = exports.LAYOUT_SUCCESS = exports.LAYOUT_REQUEST = exports.LAYOUT_FAILURE = exports.LAYOUTS_SUCCESS = exports.LAYOUTS_REQUEST = exports.LAYOUTS_FAILURE = exports.DELETE_LAYOUT_SUCCESS = exports.DELETE_LAYOUT_REQUEST = exports.DELETE_LAYOUT_FAILURE = exports.CREATE_LAYOUT_SUCCESS = exports.CREATE_LAYOUT_REQUEST = exports.CREATE_LAYOUT_FAILURE = void 0;

var _utils = require("./utils");

var LAYOUTS_REQUEST = 'LAYOUTS_REQUEST';
exports.LAYOUTS_REQUEST = LAYOUTS_REQUEST;
var LAYOUTS_SUCCESS = 'LAYOUTS_SUCCESS';
exports.LAYOUTS_SUCCESS = LAYOUTS_SUCCESS;
var LAYOUTS_FAILURE = 'LAYOUTS_FAILURE';
exports.LAYOUTS_FAILURE = LAYOUTS_FAILURE;

var getLayouts = function getLayouts() {
  return (0, _utils.thunkCreator)(function (state) {
    return {
      types: [LAYOUTS_REQUEST, LAYOUTS_SUCCESS, LAYOUTS_FAILURE],
      promise: function promise(token) {
        return fetch("".concat(process.env.REACT_APP_API_URL, "/layouts"), (0, _utils.tokenHeader)(token));
      }
    };
  });
};

exports.getLayouts = getLayouts;
var LAYOUT_REQUEST = 'LAYOUT_REQUEST';
exports.LAYOUT_REQUEST = LAYOUT_REQUEST;
var LAYOUT_SUCCESS = 'LAYOUT_SUCCESS';
exports.LAYOUT_SUCCESS = LAYOUT_SUCCESS;
var LAYOUT_FAILURE = 'LAYOUT_FAILURE';
exports.LAYOUT_FAILURE = LAYOUT_FAILURE;

var getLayout = function getLayout(layoutId) {
  return (0, _utils.thunkCreator)(function (state) {
    return {
      types: [LAYOUT_REQUEST, LAYOUT_SUCCESS, LAYOUT_FAILURE],
      promise: function promise(token) {
        return fetch("".concat(process.env.REACT_APP_API_URL, "/layouts/") + layoutId, (0, _utils.tokenHeader)(token));
      }
    };
  });
};

exports.getLayout = getLayout;
var CREATE_LAYOUT_REQUEST = 'CREATE_LAYOUT_REQUEST';
exports.CREATE_LAYOUT_REQUEST = CREATE_LAYOUT_REQUEST;
var CREATE_LAYOUT_SUCCESS = 'CREATE_LAYOUT_SUCCESS';
exports.CREATE_LAYOUT_SUCCESS = CREATE_LAYOUT_SUCCESS;
var CREATE_LAYOUT_FAILURE = 'CREATE_LAYOUT_FAILURE';
exports.CREATE_LAYOUT_FAILURE = CREATE_LAYOUT_FAILURE;

var createLayout = function createLayout(name, data) {
  return (0, _utils.thunkCreator)(function (state) {
    return {
      types: [CREATE_LAYOUT_REQUEST, CREATE_LAYOUT_SUCCESS, CREATE_LAYOUT_FAILURE],
      promise: function promise(token) {
        return fetch("".concat(process.env.REACT_APP_API_URL, "/layouts"), Object.assign({
          method: 'POST',
          body: JSON.stringify({
            name: name,
            data: data
          })
        }, (0, _utils.tokenHeader)(token)));
      }
    };
  });
};

exports.createLayout = createLayout;
var DELETE_LAYOUT_REQUEST = 'DELETE_LAYOUT_REQUEST';
exports.DELETE_LAYOUT_REQUEST = DELETE_LAYOUT_REQUEST;
var DELETE_LAYOUT_SUCCESS = 'DELETE_LAYOUT_SUCCESS';
exports.DELETE_LAYOUT_SUCCESS = DELETE_LAYOUT_SUCCESS;
var DELETE_LAYOUT_FAILURE = 'DELETE_LAYOUT_FAILURE';
exports.DELETE_LAYOUT_FAILURE = DELETE_LAYOUT_FAILURE;

var deleteLayout = function deleteLayout(layoutId) {
  return (0, _utils.thunkCreator)(function (state) {
    return {
      types: [DELETE_LAYOUT_REQUEST, DELETE_LAYOUT_SUCCESS, DELETE_LAYOUT_FAILURE],
      promise: function promise(token) {
        return fetch("".concat(process.env.REACT_APP_API_URL, "/layouts/") + layoutId, Object.assign({
          method: 'DELETE'
        }, (0, _utils.tokenHeader)(token)));
      }
    };
  });
};

exports.deleteLayout = deleteLayout;
var UPDATE_LAYOUT_REQUEST = 'UPDATE_LAYOUT_REQUEST';
exports.UPDATE_LAYOUT_REQUEST = UPDATE_LAYOUT_REQUEST;
var UPDATE_LAYOUT_SUCCESS = 'UPDATE_LAYOUT_SUCCESS';
exports.UPDATE_LAYOUT_SUCCESS = UPDATE_LAYOUT_SUCCESS;
var UPDATE_LAYOUT_FAILURE = 'UPDATE_LAYOUT_FAILURE';
exports.UPDATE_LAYOUT_FAILURE = UPDATE_LAYOUT_FAILURE;

var updateLayout = function updateLayout(layoutId, change) {
  return (0, _utils.thunkCreator)(function (state) {
    return {
      types: [UPDATE_LAYOUT_REQUEST, UPDATE_LAYOUT_SUCCESS, UPDATE_LAYOUT_FAILURE],
      promise: function promise(token) {
        return fetch("".concat(process.env.REACT_APP_API_URL, "/layouts/") + layoutId, Object.assign({
          method: 'POST',
          body: JSON.stringify(change)
        }, (0, _utils.tokenHeader)(token)));
      }
    };
  });
};

exports.updateLayout = updateLayout;