"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useTrackValue = exports.useResize = exports.usePrevious = exports.useKeyPress = void 0;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = require("react");

var useResize = function useResize(_ref) {
  var onResize = _ref.onResize;
  (0, _react.useEffect)(function () {
    if (!onResize) {
      return;
    }

    function handleResize() {
      onResize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    }

    window.addEventListener('resize', handleResize);
    handleResize();
    return function () {
      return window.removeEventListener('resize', handleResize);
    };
  }, []);
}; // taken from https://stackoverflow.com/questions/53446020/how-to-compare-oldvalues-and-newvalues-on-react-hooks-useeffect


exports.useResize = useResize;

var usePrevious = function usePrevious(value) {
  var ref = (0, _react.useRef)();
  (0, _react.useEffect)(function () {
    ref.current = value;
  });
  return ref.current;
}; // taken from https://usehooks.com/useKeyPress/


exports.usePrevious = usePrevious;

var useKeyPress = function useKeyPress(targetKey) {
  // State for keeping track of whether key is pressed
  var _useState = (0, _react.useState)(false),
      _useState2 = (0, _slicedToArray2["default"])(_useState, 2),
      keyPressed = _useState2[0],
      setKeyPressed = _useState2[1]; // If pressed key is our target key then set to true


  function downHandler(_ref2) {
    var key = _ref2.key;

    if (key === targetKey) {
      setKeyPressed(true);
    }
  } // If released key is our target key then set to false


  var upHandler = function upHandler(_ref3) {
    var key = _ref3.key;

    if (key === targetKey) {
      setKeyPressed(false);
    }
  }; // Add event listeners


  (0, _react.useEffect)(function () {
    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler); // Remove event listeners on cleanup

    return function () {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return keyPressed;
};

exports.useKeyPress = useKeyPress;

var useTrackValue = function useTrackValue(value) {
  var ref = (0, _react.useRef)(value);
  ref.current = value;
  return ref;
};

exports.useTrackValue = useTrackValue;