"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.stopPerf = exports.startPerf = void 0;

var startPerf = function startPerf(base, text) {
  var track = {
    start: "".concat(base, ":start"),
    end: "".concat(base, ":end"),
    text: text
  }; // eslint-disable-next-line no-undef

  if (performance && performance.mark && performance.measure) {
    // eslint-disable-next-line no-undef
    performance.mark(track.start);
  }

  return track;
};

exports.startPerf = startPerf;

var stopPerf = function stopPerf(track, text) {
  // eslint-disable-next-line no-undef
  if (performance && performance.mark && performance.measure) {
    // eslint-disable-next-line no-undef
    performance.mark(track.end); // eslint-disable-next-line no-undef

    performance.measure(text || track.text, track.start, track.end);
  }
};

exports.stopPerf = stopPerf;