
import React from 'react'

import { Switch, Route, Redirect } from 'react-router-dom'
import { AuthRoute, Error404, Device, DevicePreview, UserPreview } from '@touchlay/frontend-base'
import App from './components/App'

import Dashboard from './components/pages/Dashboard'
import Source from './components/pages/Source'
import Layout from './components/pages/Layout'
import Style from './components/pages/Style'
import Editor from './components/pages/editor/'

// eslint-disable-next-line react/display-name -- higher-order component
const withApp = (Component, { editor = false, navigation = true, errorSnackbar = true } = {}) => (props) => (
  // here we are extending Component, so we need props spreading
  <App editor={editor} errorSnackbar={errorSnackbar} navigation={navigation}>
    {
      // eslint-disable-next-line react/jsx-props-no-spreading
    }<Component {...props} />
  </App>
)

const RoutedUserPreview = (props) => {
  const id = props.match.params.id
  return (
    <UserPreview
      {...props} // eslint-disable-line react/jsx-props-no-spreading
      configOverride={{
        'router.type': 'browser',
        'router.prefixPath': `/view/${id}`,
      }}
    />
  )
}

const RoutedDevicePreview = (props) => {
  const license = props.match.params.license
  return (
    <DevicePreview
      {...props} // eslint-disable-line react/jsx-props-no-spreading
      configOverride={{
        'router.type': 'browser',
        'router.prefixPath': `/preview/${license}`,
      }}
    />
  )
}

const routes = (
  <Switch>
    <AuthRoute path='/dashboard' render={withApp(Dashboard)} />
    <AuthRoute path='/source/:id' render={withApp(Source)} />
    <AuthRoute path='/layout/:id' render={withApp(Layout)} />
    <AuthRoute path='/style/:id' render={withApp(Style)} />
    <AuthRoute path='/presentation/:id/:tab/:sub/:subid' render={withApp(Editor, { editor: true })} />
    <AuthRoute path='/presentation/:id/:tab/:sub' render={withApp(Editor, { editor: true })} />
    <AuthRoute path='/presentation/:id/:tab' render={withApp(Editor, { editor: true })} />
    <AuthRoute path='/presentation/:id' render={withApp(Editor, { editor: true })} />
    <AuthRoute path='/device/:id' render={withApp(Device)} />

    <AuthRoute component={RoutedUserPreview} path='/view/:id' />
    <Route component={RoutedDevicePreview} path='/preview/:license' />

    <Redirect exact from='/' to='/dashboard' />
    <Route component={Error404} />
  </Switch>
)

export default routes
