"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = devicesReducer;

var _actions = require("../actions");

var _utils = require("./utils");

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function devicesReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case _actions.DEVICES_SUCCESS:
      return action.result;

    case _actions.CREATE_DEVICE_SUCCESS:
      return [].concat(_toConsumableArray(state), [action.result]);

    case _actions.UPDATE_DEVICE_SUCCESS:
    case _actions.DEVICE_SUCCESS:
      return (0, _utils.updateOrInsert)(state, action.result);

    case _actions.DELETE_DEVICE_SUCCESS:
      return state.filter(function (device) {
        return device._id !== action.result._id;
      });
    // Create a preview object with device id and the requested
    // json files content at the specified "propName"
    // [..., {_id: "...", presentationData, presentationTheme, ...}, ...]

    case _actions.GET_DEVICE_PRESENTATION_DATA_SUCCESS:
    case _actions.GET_DEVICE_PRESENTATION_SUCCESS:
    case _actions.GET_DEVICE_LAYOUT_SUCCESS:
    case _actions.GET_DEVICE_STYLE_SUCCESS:
      return state.length > 0 ? state.map(function (device) {
        return device._id === action.deviceId ? Object.assign({}, device, _defineProperty({}, action.propName, action.result)) : device;
      }) : [_defineProperty({
        _id: action.deviceId
      }, action.propName, action.result)];

    case _actions.DEVICE_STATS_SUCCESS:
      return state.length > 0 ? state.map(function (device) {
        return device._id === action._id ? Object.assign({}, device, {
          stats: action.result
        }) : device;
      }) : [action.result];

    default:
      return state;
  }
}