
import React from 'react'

import Switch from '@material-ui/core/Switch'
import { withDefault, asListItem } from './utils'

export const EditItemSwitch = asListItem(({
  label, value, info, extra: _extra,
  setData,
}) => {
  const extra = withDefault(_extra, {
    fromValue: v => v,
    toValue: v => v,
  })
  const val = extra.toValue(value || info.defaultValue)
  const onChange = e => {
    setData({
      [label]: extra.fromValue(e.target.checked),
    })
  }
  return (
    <Switch
      checked={!!val}
      color='primary'
      disabled={info.disabled}
      onChange={onChange}
    />
  )
})

export default EditItemSwitch
