"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.warn = exports.trigger = exports.internal$getCurrentMode = exports.info = exports.error = exports["default"] = exports.debug = exports.critical = exports.changeModeNormalized = exports.changeModeNamed = exports.changeMode = void 0;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

// https://cloud.google.com/logging/docs/reference/v2/rest/v2/LogEntry
var modeNames = {
  debug: 100,
  info: 200,
  notice: 300,
  warning: 400,
  error: 500,
  critical: 600
};
var fullModes = {
  production: {
    "throw": 600,
    logging: 600
  },
  development: {
    "throw": 600,
    logging: 300
  },
  debugging: {
    "throw": 600,
    logging: 100
  },
  testing: {
    "throw": 400,
    logging: 400
  }
};

var normalize = function normalize(obj) {
  var normalizeNamespace = function normalizeNamespace(namespace) {
    if (typeof namespace === 'string') {
      return modeNames[namespace] || modeNames.error;
    } else if (typeof namespace === 'number') {
      return namespace;
    } else {
      return 600;
    }
  };

  var normalizeEntry = function normalizeEntry(entry) {
    if (typeof entry === 'string' || typeof entry === 'number') {
      return {
        "default": normalizeNamespace(entry)
      };
    }

    var ret = {
      "default": 600
    }; // $FlowFixMe

    Object.entries(entry).forEach(function (_ref) {
      var _ref2 = (0, _slicedToArray2["default"])(_ref, 2),
          key = _ref2[0],
          value = _ref2[1];

      /* allow setting null explicitly to make sure it fallbacks to default settings */
      if (value !== null) {
        ret[key] = normalizeNamespace(value);
      }
    });
    return ret;
  };

  if (typeof obj === 'string') {
    return normalize(fullModes[obj] || fullModes.production);
  } else if (obj) {
    return {
      "throw": normalizeEntry(obj["throw"] || 600),
      logging: normalizeEntry(obj.logging || 600)
    };
  } else {
    return normalize(fullModes.production);
  }
};

var currentMode = fullModes.production;

var internal$getCurrentMode = function internal$getCurrentMode() {
  return currentMode;
};

exports.internal$getCurrentMode = internal$getCurrentMode;

var changeModeNormalized = function changeModeNormalized(obj) {
  currentMode = normalize(obj);
};

exports.changeModeNormalized = changeModeNormalized;

var changeMode = function changeMode(category, namespace, ix) {
  currentMode[category][namespace] = ix;
};

exports.changeMode = changeMode;

var changeModeNamed = function changeModeNamed(category, namespace, name) {
  if (typeof modeNames[name] === 'number') {
    changeMode(category, namespace, modeNames[name]);
  }
};

exports.changeModeNamed = changeModeNamed;

var getMode = function getMode(category, namespace) {
  if (namespace && typeof currentMode[category][namespace] === 'number') {
    return currentMode[category][namespace];
  } else {
    return currentMode[category]["default"];
  }
};

var trigger = function trigger(text) {
  var priority = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  var namespace = arguments.length > 2 ? arguments[2] : undefined;
  {
    var wayfinderDevtools = window.__WAYFINDER_DEVTOOLS_EXTENSION__;

    if (wayfinderDevtools && wayfinderDevtools.send) {
      wayfinderDevtools.send('dev', {
        type: 'dev-log',
        devNs: namespace || 'default',
        level: priority,
        text: text
      });
    }
  }
  var loggingMode = getMode('logging', namespace);
  var throwMode = getMode('throw', namespace);

  if (priority >= throwMode) {
    throw new Error(text);
  } else if (priority >= loggingMode) {
    console.log(text);
  }
};

exports.trigger = trigger;

var debug = function debug(text, namespace) {
  return trigger(text, modeNames.debug, namespace);
};

exports.debug = debug;

var info = function info(text, namespace) {
  return trigger(text, modeNames.info, namespace);
};

exports.info = info;

var warn = function warn(text, namespace) {
  return trigger(text, modeNames.warning, namespace);
};

exports.warn = warn;

var error = function error(text, namespace) {
  return trigger(text, modeNames.error, namespace);
};

exports.error = error;

var critical = function critical(text, namespace) {
  return trigger(text, modeNames.critical, namespace);
};

exports.critical = critical;
var _default = {
  debug: debug,
  info: info,
  warn: warn,
  error: error,
  critical: critical
};
exports["default"] = _default;