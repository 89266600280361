"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getPresentationModifications = exports.PRESENTATION_MODIFICATIONS_SUCCESS = exports.PRESENTATION_MODIFICATIONS_REQUEST = exports.PRESENTATION_MODIFICATIONS_FAILURE = void 0;

var _utils = require("./utils");

var PRESENTATION_MODIFICATIONS_REQUEST = 'PRESENTATION_MODIFICATIONS_REQUEST';
exports.PRESENTATION_MODIFICATIONS_REQUEST = PRESENTATION_MODIFICATIONS_REQUEST;
var PRESENTATION_MODIFICATIONS_SUCCESS = 'PRESENTATION_MODIFICATIONS_SUCCESS';
exports.PRESENTATION_MODIFICATIONS_SUCCESS = PRESENTATION_MODIFICATIONS_SUCCESS;
var PRESENTATION_MODIFICATIONS_FAILURE = 'PRESENTATION_MODIFICATIONS_FAILURE';
exports.PRESENTATION_MODIFICATIONS_FAILURE = PRESENTATION_MODIFICATIONS_FAILURE;

var getPresentationModifications = function getPresentationModifications(presentationId) {
  return (0, _utils.thunkCreator)(function (state) {
    return {
      types: [PRESENTATION_MODIFICATIONS_REQUEST, PRESENTATION_MODIFICATIONS_SUCCESS, PRESENTATION_MODIFICATIONS_FAILURE],
      _id: presentationId,
      promise: function promise(token) {
        return fetch("".concat(process.env.REACT_APP_API_URL, "/presentations/").concat(presentationId, "/modifications"), (0, _utils.tokenHeader)(token));
      }
    };
  });
};

exports.getPresentationModifications = getPresentationModifications;