"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useMatchPath = exports.simplifyURL = exports.matchPath = void 0;

var _react = require("react");

var _pathToRegexp = require("path-to-regexp");

function _toArray(arr) { return _arrayWithHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

var simplifyURL = function simplifyURL(path) {
  if (typeof path === 'string') {
    return path.replace(/(\/+)/g, '/');
  }

  return path;
}; // partially based off https://github.com/ReactTraining/react-router/blob/master/packages/react-router/modules/matchPath.js


exports.simplifyURL = simplifyURL;

var useMatchPath = function useMatchPath(path) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var _options$exact = options.exact,
      exact = _options$exact === void 0 ? false : _options$exact,
      _options$strict = options.strict,
      strict = _options$strict === void 0 ? false : _options$strict,
      _options$sensitive = options.sensitive,
      sensitive = _options$sensitive === void 0 ? false : _options$sensitive;
  var ret = (0, _react.useMemo)(function () {
    if (!path) return null;
    var keys = [];
    var regexp = (0, _pathToRegexp.pathToRegexp)(path, keys, {
      end: exact,
      strict: strict,
      sensitive: sensitive
    });
    return {
      regexp: regexp,
      keys: keys
    };
  }, [path, exact, strict, sensitive]);
  var cb = (0, _react.useCallback)(function (pathname) {
    if (!ret) return null;

    if (pathname) {
      pathname = simplifyURL(pathname);
    }

    var regexp = ret.regexp,
        keys = ret.keys;
    var match = regexp.exec(pathname);
    if (!match) return null;

    var _match = _toArray(match),
        url = _match[0],
        values = _match.slice(1);

    var isExact = pathname === url;
    if (exact && !isExact) return null;
    var niceUrl = path === '/' && url === '' ? '/' : url.endsWith('/') ? url.slice(0, -1) : url;
    return {
      path: path,
      // the path used to match
      url: niceUrl,
      // the matched portion of the URL
      isExact: isExact,
      // whether or not we matched exactly
      params: keys.reduce(function (memo, key, index) {
        memo[key.name] = values[index];
        return memo;
      }, {})
    };
  }, [ret]);
  return cb;
};
/* one-off matchPath */


exports.useMatchPath = useMatchPath;

var matchPath = function matchPath(pathname, options) {
  var path = options.path,
      exact = options.exact,
      strict = options.strict,
      sensitive = options.sensitive;
  var keys = [];
  var regexp = (0, _pathToRegexp.pathToRegexp)(path, keys, {
    end: exact,
    strict: strict,
    sensitive: sensitive
  });

  if (pathname) {
    pathname = simplifyURL(pathname);
  }

  var match = regexp.exec(pathname);
  if (!match) return null;

  var _match2 = _toArray(match),
      url = _match2[0],
      values = _match2.slice(1);

  var isExact = pathname === url;
  if (exact && !isExact) return null;
  return {
    path: path,
    // the path used to match
    url: path === '/' && url === '' ? '/' : url,
    // the matched portion of the URL
    isExact: isExact,
    // whether or not we matched exactly
    params: keys.reduce(function (memo, key, index) {
      memo[key.name] = values[index];
      return memo;
    }, {})
  };
};

exports.matchPath = matchPath;