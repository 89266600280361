"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _auth = _interopRequireDefault(require("./auth"));

var _devices = _interopRequireDefault(require("./devices"));

var _sources = _interopRequireDefault(require("./sources"));

var _integrations = _interopRequireDefault(require("./integrations"));

var _presentations = _interopRequireDefault(require("./presentations"));

var _presentationdata = _interopRequireDefault(require("./presentationdata"));

var _styles = _interopRequireDefault(require("./styles"));

var _layouts = _interopRequireDefault(require("./layouts"));

var _error = _interopRequireDefault(require("./error"));

var _customdata = _interopRequireDefault(require("./customdata"));

var _loading = _interopRequireDefault(require("./loading"));

var _users = _interopRequireDefault(require("./users"));

var _files = _interopRequireDefault(require("./files"));

var _modifications = _interopRequireDefault(require("./modifications"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var _default = {
  auth: _auth["default"],
  devices: _devices["default"],
  sources: _sources["default"],
  presentationdata: _presentationdata["default"],
  integrations: _integrations["default"],
  presentations: _presentations["default"],
  styles: _styles["default"],
  layouts: _layouts["default"],
  error: _error["default"],
  customdata: _customdata["default"],
  loading: _loading["default"],
  users: _users["default"],
  files: _files["default"],
  modifications: _modifications["default"]
};
exports["default"] = _default;