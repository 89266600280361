import React from 'react'
import { makeStyles } from '@material-ui/styles'

const useClasses = makeStyles((theme) => ({
  mainPage: {
    marginTop: theme.spacing(7),
  },
}))

const PageContent = ({ children }) => {
  const classes = useClasses()
  return (
    <div className={classes.mainPage}>
      {children}
    </div>
  )
}

export default PageContent
