import * as React from 'react'
import ListSubheader from '@material-ui/core/ListSubheader'
import Button from '@material-ui/core/Button'
import { DnDListInput, useMediaLibrary, HelpTooltip } from '@touchlay/frontend-base'

import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import UploadIcon from '@material-ui/icons/CloudUpload'
import { makeStyles } from '@material-ui/styles'

import { getSizedUrl } from '@touchlay/utils/src/customData'
import { withDefault } from './utils'
import isImage from 'is-image'
import isVideo from 'is-video'

const useStyles = makeStyles((theme) => ({
  addButton: {
    marginLeft: theme.spacing(4),
  },
  uploadIcon: {
    marginRight: theme.spacing(2),
  },
  inputContainer: {
    width: '45%',
  },
  listItemFull: {
    flexDirection: 'column',
    alignItems: 'self-end',
  },
  listSubHeader: {
    alignItems: 'center',
    marginBottom: '8px',
  },
  inputContainerFull: {
    width: '100%',
  },
  dndlist: {
    paddingLeft: '16px',
    paddingRight: '16px',
  },
}))
// generic dropzone item
export const EditItemUploadList$Int = ({ info, label, value, extra: _extra, extendValue, setData, _header }) => {
  const extra = withDefault(_extra, {
    type: 'image',
  })
  const styles = useStyles()
  const tempChildren = [...value]

  const handleDelete = (index) => {
    // remove from PD
    // const deleted = tempChildren.splice(index, 1)
    tempChildren.splice(index, 1)
    setData({
      [label]: tempChildren,
    })
  }

  const handleMove = (newElem) => {
    setData({
      [label]: newElem,
    })
  }

  const openML = useMediaLibrary()
  const openMediaLibrary = () => {
    openML({
      type: extra.type,
      multiple: true,
      onSelect: (fileList) => {
        if (fileList.length > 0) {
          const fileListElems = fileList.flatMap(file => {
            const url = file.url
            const newEntry = extendValue
              ? extendValue(url)
              : null
            if (newEntry) {
              return [newEntry]
            } else {
              return []
            }
          })
          if (fileListElems.length > 0) {
            setData({
              [label]: [ ...value, ...fileListElems ],
            })
          }
        }
      },
    })
  }

  if (_header) {
    return (
      <>
        <ListSubheader className={styles.listSubHeader} color='primary'>
          {'Media'}
          <ListItemSecondaryAction>
            <Button
              aria-label='add file'
              className={styles.addButton}
              disabled={info?.disabled}
              onClick={openMediaLibrary}
              size='small'
              variant='outlined'
            >
              <UploadIcon className={styles.uploadIcon} /> Add new
            </Button>
          </ListItemSecondaryAction>

        </ListSubheader>
        <div className={styles.dndlist}>
          <DnDListInput
            disabled={info?.disabled}
            elements={value}
            onDelete={handleDelete}
            onMove={handleMove}
          />
        </div>
      </>
    )
  } else {
    return (
      <>
        <ListItem>
          <ListItemText>
            {info?.formattedLabel}
            {' '}
            {info?.helpText && <HelpTooltip text={info.helpText} />}
          </ListItemText>
          <ListItemSecondaryAction>
            <Button
              aria-label='add new file'
              className={styles.addButton}
              disabled={info?.disabled}
              onClick={openMediaLibrary}
              size='small'
              variant='outlined'
            >
              <UploadIcon className={styles.uploadIcon} /> Add new
            </Button>
          </ListItemSecondaryAction>
        </ListItem>
        <div className={`${styles.inputContainerFull} ${styles.dndlist}`}>
            <DnDListInput
              disabled={info?.disabled}
              elements={value}
              onDelete={handleDelete}
              onMove={handleMove}
            />
        </div>
      </>
    )
  }
}

export const EditItemContentUpload = (props) => {
  return (
    <>
      <EditItemUploadList$Int
        _header
        {...props}
        extendValue={(url) => (
          isImage(url)
            ? {
                // TODO: should we check for duplicates here? they are very unlikely
                id: `image-${Math.random().toString(36).substr(2, 5)}`,
                type: 'image',
                src: url,
                thumbnailSrc: getSizedUrl(url, 'small'),
              }
            : isVideo(url)
              ? {
                  id: `video-${Math.random().toString(36).substr(2, 5)}`,
                  type: 'video',
                  src: url,
                }
              : null)}
        />
    </>
  )
}

export const EditItemUploadList = (props) => (
  <EditItemUploadList$Int {...props} extendValue={(url) => ({ src: url })} />
)

export default EditItemUploadList
