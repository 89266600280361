"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = handleIncompleteData;
exports.generateError = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

var generateError = function generateError(errorInfo, rest) {
  return _objectSpread({
    type: 'error',
    title: 'We detected an error with your presentation:',
    description: errorInfo
  }, rest);
};

exports.generateError = generateError;

function handleIncompleteData(_ref) {
  var error = _ref.error,
      data = _ref.data,
      layout = _ref.layout,
      device = _ref.device,
      theme = _ref.theme;

  // external errors
  if (error instanceof Error) {
    if (typeof error.message === 'string' && error.message.startsWith('Cannot read property ')) {
      return {
        type: 'error',
        title: 'There seems to be an issue contacting our servers. ',
        description: 'Please check your network connection and make ' + 'sure that the requests are not being blocked by a firewall.'
      };
    }

    if (typeof error.message === 'string') {
      return generateError(error.message, {
        _sentry: error
      });
    }
  }

  if (error && typeof error !== 'string') {
    // safe guard
    return generateError('Internal error', {
      _sentry: error
    });
  }

  if (error && error.startsWith('Cast to ObjectId failed for value "')) {
    return {
      type: 'error',
      title: 'invalid presentation ID or link'
    };
  }

  if (error) {
    return generateError(error, {
      _sentry: error
    });
  } // loading states


  if (!data) {
    return {
      type: 'loading',
      what: 'data'
    };
  }

  if (!layout) {
    return {
      type: 'loading',
      what: 'layout'
    };
  }

  if (!theme) {
    return {
      type: 'loading',
      what: 'style'
    };
  } // heuristics for error states


  if (data && data.error === 'No presentation connected to device.') {
    return {
      type: 'error',
      title: 'no presentation connected to this device yet'
    };
  }

  if (!Array.isArray(layout)) {
    return {
      type: 'error',
      title: 'the assigned presentation layout has an invalid structure'
    };
  }

  if (Array.isArray(layout) && layout.length === 0) {
    return {
      type: 'error',
      title: 'the assigned presentation layout is empty'
    };
  }

  if (!Array.isArray(data)) {
    return {
      type: 'error',
      title: 'invalid data assigned to this presentation'
    };
  }

  if (Array.isArray(data) && data.length === 0) {
    return {
      type: 'error',
      title: 'no data assigned to this presentation'
    };
  } // TODO: consider adding more error states
  // otherwise, everything is fine


  return {
    type: 'success'
  };
}