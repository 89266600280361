"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useSubItems = exports.useComponentPath = exports.findElementReact = void 0;

var _react = _interopRequireWildcard(require("react"));

var _utils = require("@touchlay/utils");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it["return"] != null) it["return"](); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var processSubItems = function processSubItems(res, content) {
  _react["default"].Children.forEach(content, function (ch) {
    res.push([_objectSpread({}, ch.props), ch]);
  });
};

var useSubItems = function useSubItems(content) {
  var val = (0, _react.useMemo)(function () {
    var res = [];
    processSubItems(res, content);
    return res;
  }, [content]);
  return val;
};

exports.useSubItems = useSubItems;

var findElementReact = function findElementReact(content, id) {
  var val = null;

  _react["default"].Children.forEach(content, function (ch) {
    var _ch$props, _ch$props2, _ch$props3;

    if ((ch === null || ch === void 0 ? void 0 : (_ch$props = ch.props) === null || _ch$props === void 0 ? void 0 : _ch$props.id) === id) {
      val = ch;
    }
    /*
      this full blown recursive search is required for cases like ferag where we have:
      - View
        - Card
          - MenuItem
            -View
      [note: this isn't fully handled by HeaderView
       but if we remove the recursive search, ferag would break]
       as an optimization, we assume the only way to get to a child inside a menu.item
      is to go through the menu.item itself, so we can't "skip" over them.
    */


    if (!val && (ch === null || ch === void 0 ? void 0 : (_ch$props2 = ch.props) === null || _ch$props2 === void 0 ? void 0 : _ch$props2.pdType) !== 'menu.item' && ch !== null && ch !== void 0 && (_ch$props3 = ch.props) !== null && _ch$props3 !== void 0 && _ch$props3.content) {
      val = findElementReact(ch.props.content, id);
    }
  });

  return val;
};

exports.findElementReact = findElementReact;

var recursiveFind = function recursiveFind(array, matchFn) {
  var go = function go(xs) {
    if (Array.isArray(xs)) {
      var _iterator = _createForOfIteratorHelper(xs),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var x = _step.value;

          if (matchFn(x)) {
            return x;
          }

          if (Array.isArray(x.content)) {
            var vl = go(x.content);
            if (vl) return vl;
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
    }

    return null;
  };

  return go(array);
};

var useComponentPath = function useComponentPath() {
  var currentPath = (0, _utils.useConfig)('routing.path');
  var rawPD = (0, _utils.usePD)();
  var paths = (currentPath || '/').split('/').filter(function (x) {
    return x !== '';
  }); // this whole messy lookup is done to *try* to find the PD that references to a specific route
  // code adapted from headerview, but generalized and "cleaned up" - still extremely hacky

  var procPath = (0, _react.useMemo)(function () {
    var pd = rawPD;
    var out = [];
    var previous = null;
    paths.forEach(function (dir, ix) {
      var matchingRoute = recursiveFind(pd, function (x) {
        return (x === null || x === void 0 ? void 0 : x.id) === dir;
      }); // console.warn({ matchingRoute, dir, ix, previous, out, pd })

      if (matchingRoute) {
        out.push(matchingRoute);
        previous = pd;
        pd = matchingRoute === null || matchingRoute === void 0 ? void 0 : matchingRoute.content;

        if (pd) {
          if (Array.isArray(pd) && pd.length === 1) {
            var _pd$;

            if (((_pd$ = pd[0]) === null || _pd$ === void 0 ? void 0 : _pd$.type) === 'view') {
              out.push(pd[0]);
              previous = pd[0];
            }
          }
        }
      } else {
        var _previous, _previous2;

        if (previous && ((_previous = previous) === null || _previous === void 0 ? void 0 : _previous.type) === 'view' && ((_previous2 = previous) === null || _previous2 === void 0 ? void 0 : _previous2.view) === 'ToggleView') {
          out.push(_objectSpread(_objectSpread({}, Array.isArray(previous.subViews) && previous.subViews.find(function (x) {
            return (x === null || x === void 0 ? void 0 : x.id) === dir;
          }) || {
            id: dir,
            notFound: true
          }), {}, {
            type: '#subview#'
          }));
          previous = null;
        }
      }
    });
    return out;
  }, [currentPath]);
  return procPath;
};

exports.useComponentPath = useComponentPath;