import * as React from 'react'
import { useState, useEffect } from 'react'
import { useLanguage, usePDTranslation } from '@touchlay/utils'
import { ListEditItem, withDefault } from './utils'
import { useMediaLibrary } from '@touchlay/frontend-base'
import { getSizedUrl } from '@touchlay/utils/src/customData'

import { MissingFilePlaceholder, Video } from '@touchlay/components'

import IconButton from '@material-ui/core/IconButton'

import UploadIcon from '@material-ui/icons/CloudUpload'
import DeleteIcon from '@material-ui/icons/Delete'
import isImage from 'is-image'
import isVideo from 'is-video'

// improve this, this is duplicated between URL and Upload
const imagePreviewStyle = { maxWidth: '100%', padding: '0 16px', border: 0 }

export const EditItemUpload = ({ info, label, node, value, extractValue, setData, extra: _extra }) => {
  const extra = withDefault(_extra, {
    type: 'image',
    extractValue: (result) => result?.url,
    preview: null,
    translated: false,
    thumbnailLabel: null,
  })

  const lang = useLanguage()

  const language = extra.translated
    ? lang
    : false
  const updateLabel = language ? `${label}.${language}` : label
  const setFile = (value) => {
    const updateThumbnailLabel = extra.thumbnailLabel
      ? (language ? `${extra.thumbnailLabel}.${language}` : extra.thumbnailLabel)
      : null
    if (extra.thumbnailLabel && extra.thumbnailLabel in node) {
      setData({
        [updateLabel]: value,
        [updateThumbnailLabel]: value.length > 0 ? getSizedUrl(value, 'small') : '',
      })
    } else {
      setData({ [updateLabel]: value })
    }
  }
  const handleDelete = () => {
    setFile('')
  }

  const openML = useMediaLibrary()
  const openMediaLibrary = () => {
    openML({
      type: extra.type,
      multiple: false,
      onSelect: (fileList) => {
        const file = fileList[0]
        if (!file) return
        setFile(extra.extractValue(file, { node, label, value }))
      },
    })
  }
  return (
    <>
      <ListEditItem info={info}>
        <IconButton aria-label='select file' disabled={info?.disabled} onClick={openMediaLibrary}>
          <UploadIcon />
        </IconButton>
        <IconButton aria-label='delete upload' disabled={info?.disabled || !value} onClick={handleDelete}>
          <DeleteIcon />
        </IconButton>
      </ListEditItem>
      {extra.preview ? <extra.preview label={label} node={node} value={value} /> : null}
    </>
  )
}

const useError = (src) => {
  const [ error, setError ] = useState(false)
  useEffect(() => {
    setError(false)
  }, [src])
  const onError = () => setError(true)
  return [ error, onError ]
}

export const UploadPreviewImage = ({ label, node, value }) => {
  const tPD = usePDTranslation()
  const rV = value && tPD(value)
  const [ error, onError ] = useError(rV)
  if (!rV) return null
  if (error) {
    return <MissingFilePlaceholder style={imagePreviewStyle} />
  }
  return (
    <img alt={label} onError={onError} src={getSizedUrl(rV, 'small')}
         style={imagePreviewStyle} />
  )
}
export const UploadPreviewVideo = ({ label, node, value }) => {
  const tPD = usePDTranslation()
  const rV = value && tPD(value)
  if (!rV) return null

  return (
    <Video muted src={rV} />
  )
}

export const UploadPreviewMedia = ({ label, node, value }) => {
  const tPD = usePDTranslation()
  const rV = value && tPD(value)
  if (!rV) return null

  if (isImage(rV)) {
    return (
      <UploadPreviewImage label={label} node={node} value={rV} />
    )
  } else if (isVideo(rV)) {
    return (
      <UploadPreviewVideo label={label} node={node} value={rV} />
    )
  } else {
    // fallback to iframe, browser might know what to do (e.g. pdf)
    return <UploadPreviewIframe label={label} node={node} value={rV} />
  }
}

export const UploadPreviewIframe = ({ label, node, value }) => {
  const tPD = usePDTranslation()
  const rV = value && tPD(value)
  const [ error, onError ] = useError(rV)
  if (!rV) return null
  if (error) {
    return <MissingFilePlaceholder style={imagePreviewStyle} />
  }
  return (
    <iframe onError={onError} src={rV} style={imagePreviewStyle}
title={label} />
  )
}

export default EditItemUpload
