"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MEDIA_SIZES = void 0;
exports.constructUrl = constructUrl;
exports.destructUrl = destructUrl;
exports.getSizedUrl = getSizedUrl;
exports.getSrcSetUrls = getSrcSetUrls;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

// function that help to follow the structure used for sizing images on customdata
// structure: /files/{:id}-{:size}?-.{:ext|:webp}?
// we need to keep this in sync with customdata config options
var MEDIA_SIZES = {
  medium: 460,
  large: 690,
  xlarge: 960,
  hd: 1920,
  '2k': 2560,
  '4k': 3840
}; // construct url based on parts

exports.MEDIA_SIZES = MEDIA_SIZES;

function constructUrl(_ref) {
  var id = _ref.id,
      size = _ref.size,
      extension = _ref.extension;
  if (!id) return false;
  return '/files/' + id + (size ? '-' + size : '') + (extension ? '.' + extension : '');
} // get parts of the url


function destructUrl(url) {
  var parts = /(\/files\/)([a-f\d]{24})(-\w+)?(\.\w+)?/i.exec(url);

  if (parts) {
    return {
      id: parts[2],
      size: parts[3] && parts[3].replace('-', ''),
      extension: parts[4] && parts[4].replace('.', '')
    };
  } else {
    // console.warn('Supplied URL does not match customdata structure.')
    return false;
  }
} // get url with size parameter based on existing url


function getSizedUrl(url, size, webp) {
  var parts = destructUrl(url); // reuse basepath of url if it's a full path

  var basePath = '';

  if (url.startsWith('http')) {
    basePath = url.split('/files/');
    basePath.pop();
    basePath = basePath.join('/');
  }

  return basePath + constructUrl({
    id: parts.id,
    size: size,
    extension: webp ? 'webp' : parts.extension
  });
} // generate all possible/preset size urls and return them with the width


function getSrcSetUrls(url, webp) {
  return Object.entries(MEDIA_SIZES).map(function (_ref2) {
    var _ref3 = (0, _slicedToArray2["default"])(_ref2, 2),
        name = _ref3[0],
        size = _ref3[1];

    return [size, getSizedUrl(url, name, webp), destructUrl(url).extension];
  });
}