"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _utils = require("@touchlay/utils");

var _genericButtonView = require("./utils/genericButtonView");

var ActionButtonView = (0, _genericButtonView.createButtonView)('ActionButtonView', function (_ref) {
  var options = _ref.options;
  var dispatch = (0, _utils.useActions)('#actionbutton#');
  return {
    onClick: function onClick() {
      dispatch(options.action);
    }
  };
});
var _default = ActionButtonView;
exports["default"] = _default;