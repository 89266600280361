"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getFiles = exports.GET_FILES_SUCCESS = exports.GET_FILES_REQUEST = exports.GET_FILES_FAILURE = void 0;

var _utils = require("./utils");

var _urls = require("../urls");

var GET_FILES_REQUEST = 'GET_FILES_REQUEST';
exports.GET_FILES_REQUEST = GET_FILES_REQUEST;
var GET_FILES_SUCCESS = 'GET_FILES_SUCCESS';
exports.GET_FILES_SUCCESS = GET_FILES_SUCCESS;
var GET_FILES_FAILURE = 'GET_FILES_FAILURE';
exports.GET_FILES_FAILURE = GET_FILES_FAILURE;

var getFiles = function getFiles() {
  return (0, _utils.thunkCreator)({
    types: [GET_FILES_REQUEST, GET_FILES_SUCCESS, GET_FILES_FAILURE],
    promise: function promise(token) {
      return fetch("".concat(_urls.CUSTOMDATA_URL, "/files"), {
        headers: {
          Authorization: (0, _utils.tokenHeader)(token).headers.get('Authorization')
        }
      });
    }
  });
};

exports.getFiles = getFiles;