"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = require("react");

var _utils = require("@touchlay/utils");

var _reactLeaflet = require("react-leaflet");

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

var SizeTracking = function SizeTracking(_ref) {
  var _ref$markerView = _ref.markerView,
      markerView = _ref$markerView === void 0 ? false : _ref$markerView,
      _ref$track = _ref.track,
      track = _ref$track === void 0 ? [] : _ref$track;
  var leaflet = (0, _reactLeaflet.useLeaflet)();
  var map = leaflet && leaflet.map;
  var paddingBottom = (0, _utils.useConfig)('padding.bottom');
  var paddingLeft = (0, _utils.useConfig)('padding.left');
  var paddingMapBottom = (0, _utils.useConfig)('padding.MapView.bottom');
  var paddingMapLeft = (0, _utils.useConfig)('padding.MapView.left');
  var sizeCb = (0, _react.useCallback)(function () {
    if (map) {
      map.invalidateSize({
        animate: false,
        pan: false,
        debounceMoveend: false
      });

      if (markerView) {
        map.setZoom(0, {
          animate: false
        });
      }
    }
  });
  (0, _react.useEffect)(sizeCb, [map, paddingBottom, paddingLeft, paddingMapBottom, paddingMapLeft].concat(_toConsumableArray(track)));
  (0, _react.useEffect)(function () {
    window.addEventListener('resize', sizeCb);
    return function () {
      window.removeEventListener('resize', sizeCb);
    };
  }, [map]);
  return null;
};

var _default = SizeTracking;
exports["default"] = _default;