import * as React from 'react'

import Typography from '@material-ui/core/Typography'

import { DebouncedInput } from '@touchlay/frontend-base'

import { asListItem, withDefault } from './utils'

const guaranteeID = (str, old) => {
  let f = ''
  const regex = /[a-zA-Z0-9-]/
  for (const c of str) {
    if (c.match(regex)) {
      f += c
    }
  }
  return f
}

export const EditItemId = asListItem(({ info, label, value, setData, node, treeData, extra: _extra, ...props }) => {
  const extra = withDefault(_extra, {
  })
  let rValue = value
  let prefixText = ''
  if (label === 'id' && value?.startsWith('usr$')) {
    prefixText = 'usr$'
    rValue = value.substr('usr$'.length)
  }
  const handleChange = (val) => {
    setData({
      [label]: val,
    })
  }

  const handleChangeSection = (r) => {
    const input = r.trim()
    return handleChange(prefixText + input)
  }
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {prefixText.length > 0 && (
        <Typography style={{ color: '#aaa' }}>
          {prefixText}
        </Typography>
      )}
      <DebouncedInput
        autoFocus={false}
        disabled={info?.disabled}
        maxLength={36}
        onBeforeChange={guaranteeID}
        onChange={handleChangeSection}
        value={rValue}
        {...extra} // eslint-disable-line react/jsx-props-no-spreading
      />
    </div>
  )
})

export default EditItemId
