import React from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { ConfigViewWrapper, TextEdit, DeleteButton } from '@touchlay/frontend-base'

import { useItem } from './helpers/utils'

import { getSource, updateSource, deleteSource } from '@touchlay/frontend-base/dist/actions'

const Source = () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const source = useItem((x) => x.sources, id, getSource)

  // Define event handlers
  const handleConfigChange = (value) => dispatch(updateSource(source._id, JSON.parse(value)))

  return (
    <ConfigViewWrapper subTitle={'source'} title={source && source.integration}>
      {/* edit config */}
      <TextEdit
        defaultValue={source && JSON.stringify(source.config, null, 4)}
        fullWidth
        id={'sourceconfig'}
        key={source && JSON.stringify(source.config)}
        label={'Config'}
        multiline
        onChange={handleConfigChange}
      />
      <DeleteButton action={(x) => dispatch(deleteSource(x))} id={source && source._id} />
    </ConfigViewWrapper>
  )
}

export default Source
