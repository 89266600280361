"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.usePermissions = exports.Permissions = void 0;

var _reactRedux = require("react-redux");

var Permissions = {
  WRITE_USER: 'WRITE_USER',
  WRITE: 'WRITE',
  READ: 'READ',
  INVITE: 'INVITE'
};
exports.Permissions = Permissions;

var usePermissions = function usePermissions(presentation) {
  var auth = (0, _reactRedux.useSelector)(function (x) {
    return x.auth;
  });

  var hasPermission = function hasPermission(x) {
    var _presentation$users, _presentation$users$f, _presentation$users$f2;

    return presentation === null || presentation === void 0 ? void 0 : (_presentation$users = presentation.users) === null || _presentation$users === void 0 ? void 0 : (_presentation$users$f = _presentation$users.find(function (u) {
      var _auth$user;

      return u._id === (auth === null || auth === void 0 ? void 0 : (_auth$user = auth.user) === null || _auth$user === void 0 ? void 0 : _auth$user._id);
    })) === null || _presentation$users$f === void 0 ? void 0 : (_presentation$users$f2 = _presentation$users$f.roles) === null || _presentation$users$f2 === void 0 ? void 0 : _presentation$users$f2.includes(x);
  };

  return hasPermission;
};

exports.usePermissions = usePermissions;