import * as React from 'react'
import EditItemSelect from './EditItemSelect'
import { formatLabel } from './utils'

const getStyleClasses = (style, viewName) => {
  const components = style?.data?.components
  const { styleClasses = {} } = (components && components[viewName]) || {}
  const available = Object.keys(styleClasses).map(key => ({
    label: formatLabel(key),
    value: key,
  }))
  return [
    { label: 'Default Style', value: '__TLY_DEFAULT_EMPTY_STRING__' },
    ...available,
  ]
}

const EditItemStyleClass = (props) => {
  const classes = getStyleClasses(props.presentationStyle, props.node.view)
  return (
    <EditItemSelect
      {...props}
      extra={{
        values: classes,
      }}
      info={{
        ...props.info,
        defaultValue: '__TLY_DEFAULT_EMPTY_STRING__',
      }}
    />
  )
}

export default EditItemStyleClass
