"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useSynchronousEffect = exports.mergeClasses = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _react = require("react");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

var useSynchronousEffect = function useSynchronousEffect(func, values) {
  var key = (0, _react.useRef)([]);
  var output = null; // Store "generation" key. Just returns a new object every time

  var currentKey = (0, _react.useMemo)(function () {
    return {};
  }, values); // "the first render", or "memo dropped the value"

  if (key.current !== currentKey) {
    key.current = currentKey;
    output = func();
  }

  (0, _react.useEffect)(function () {
    return function () {
      if (output) {
        output();
      }
    };
  }, [currentKey]);
};

exports.useSynchronousEffect = useSynchronousEffect;

var mergeClasses = function mergeClasses(cls1, cls2) {
  var _final = _objectSpread({}, cls1 || {});

  Object.keys(cls2 || {}).forEach(function (k) {
    if (_final[k]) {
      _final[k] = "".concat(_final[k], " ").concat(cls2[k]);
    } else {
      _final[k] = cls2[k];
    }
  });
  return _final;
};

exports.mergeClasses = mergeClasses;