"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useHackFirstRender = exports.useDimensions = void 0;

var _react = require("react");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

var defs = {
  onUpdate: function onUpdate() {}
};
/*
  part of the code taken from https://github.com/rehooks/component-size/blob/master/index.js
*/

var useDimensions = function useDimensions() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : defs,
      onUpdate = _ref.onUpdate;

  var ref = (0, _react.useRef)();

  var _useState = (0, _react.useState)({}),
      _useState2 = _slicedToArray(_useState, 2),
      dimensions = _useState2[0],
      setDimensions = _useState2[1];

  var updateDimensions = function updateDimensions() {
    var dims = {
      width: 0,
      height: 0
    };

    if (ref.current && ref.current.getBBox) {
      dims = ref.current.getBBox();
    } else if (ref.current) {
      dims = {
        width: ref.current.clientWidth,
        height: ref.current.clientHeight
      };
    } // getClientRects()
    // window.getComputedStyle(ref.current)
    // && ref.current.getBoundingClientRect && ref.current.getBoundingClientRect()


    setDimensions({
      width: dims.width,
      height: dims.height
    });
    onUpdate();
  };

  (0, _react.useLayoutEffect)(function () {
    if (!ref.current) {
      return;
    }

    updateDimensions();

    if (typeof ResizeObserver === 'function') {
      var resizeObserver = new ResizeObserver(function () {
        updateDimensions();
      });
      resizeObserver.observe(ref.current);
      return function () {
        resizeObserver.disconnect(ref.current);
        resizeObserver = null;
      };
    } else {
      window.addEventListener('resize', updateDimensions);
      return function () {
        window.removeEventListener('resize', updateDimensions);
      };
    }
  }, [ref.current]);
  return _objectSpread({
    ref: ref
  }, dimensions);
};

exports.useDimensions = useDimensions;

var useHackFirstRender = function useHackFirstRender() {
  var fr = (0, _react.useRef)(true);

  var _useState3 = (0, _react.useState)(0),
      _useState4 = _slicedToArray(_useState3, 2),
      forceUpdate = _useState4[1];

  (0, _react.useEffect)(function () {
    var tim = setTimeout(function () {
      if (fr.current) {
        fr.current = false;
        forceUpdate(function (x) {
          return x + 1;
        });
      }
    }, 500); // hack to make sure we don't linger on a first render for too long

    return function () {
      clearTimeout(tim);
    };
  });
  return fr;
};

exports.useHackFirstRender = useHackFirstRender;