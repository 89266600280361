"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.upload = exports.updateElementInfo = exports.updateElement = exports.update = exports.parseXLSX = exports.getXLSX = exports.getElement = exports.deleteElement = exports.createElement = exports.XLSX_SUCCESS = exports.XLSX_REQUEST = exports.XLSX_FAILURE = exports.UPLOAD_SUCCESS = exports.UPLOAD_REQUEST = exports.UPLOAD_FAILURE = exports.UPDATE_SUCCESS = exports.UPDATE_REQUEST = exports.UPDATE_FAILURE = exports.UPDATE_ELEMENT_SUCCESS = exports.UPDATE_ELEMENT_REQUEST = exports.UPDATE_ELEMENT_INFO_SUCCESS = exports.UPDATE_ELEMENT_INFO_REQUEST = exports.UPDATE_ELEMENT_INFO_FAILURE = exports.UPDATE_ELEMENT_FAILURE = exports.PARSE_XLSX_SUCCESS = exports.PARSE_XLSX_REQUEST = exports.PARSE_XLSX_FAILURE = exports.ELEMENT_SUCCESS = exports.ELEMENT_REQUEST = exports.ELEMENT_FAILURE = exports.DELETE_ELEMENT_SUCCESS = exports.DELETE_ELEMENT_REQUEST = exports.DELETE_ELEMENT_FAILURE = exports.CREATE_ELEMENT_SUCCESS = exports.CREATE_ELEMENT_REQUEST = exports.CREATE_ELEMENT_FAILURE = void 0;

var _utils = require("./utils");

var _urls = require("../urls");

var _axios = _interopRequireDefault(require("axios"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

var UPDATE_REQUEST = 'UPDATE_REQUEST';
exports.UPDATE_REQUEST = UPDATE_REQUEST;
var UPDATE_SUCCESS = 'UPDATE_SUCCESS';
exports.UPDATE_SUCCESS = UPDATE_SUCCESS;
var UPDATE_FAILURE = 'UPDATE_FAILURE';
exports.UPDATE_FAILURE = UPDATE_FAILURE;

var update = function update(url) {
  return (0, _utils.thunkCreator)({
    types: [UPDATE_REQUEST, UPDATE_SUCCESS, UPDATE_FAILURE],
    promise: function promise(token) {
      return fetch("".concat(_urls.CUSTOMDATA_URL, "/update"), {
        method: 'POST',
        body: JSON.stringify({
          config: {
            url: url
          }
        })
      });
    }
  });
};

exports.update = update;
var UPLOAD_REQUEST = 'UPLOAD_REQUEST';
exports.UPLOAD_REQUEST = UPLOAD_REQUEST;
var UPLOAD_SUCCESS = 'UPLOAD_SUCCESS';
exports.UPLOAD_SUCCESS = UPLOAD_SUCCESS;
var UPLOAD_FAILURE = 'UPLOAD_FAILURE';
/*
  this awkward _p1, _p2 code is there to remain backwards compatible
  with the way the parameters were used in the past
*/

exports.UPLOAD_FAILURE = UPLOAD_FAILURE;

var upload = function upload(file, _p1, _p2) {
  var params = {
    tag: null,
    xlsx: false
  };

  if (_p1 && _typeof(_p1) === 'object') {
    params = _objectSpread(_objectSpread({}, params), _p1);
  } else {
    // we're not using ?? because it breaks imports
    // maybe something is wrong with how frontend-bases compiles
    params.tag = _p1 || null;
    params.xlsx = _p2 || false;
  }

  var form = new FormData();
  form.append('file', file);
  if (params.tag) form.append('tag', params.tag);
  return (0, _utils.abortableThunkCreatorAxios)(function (state) {
    return {
      types: [UPLOAD_REQUEST, UPLOAD_SUCCESS, UPLOAD_FAILURE],
      promise: function promise(_ref) {
        var token = _ref.token,
            abortController = _ref.abortController;
        return (0, _axios["default"])({
          method: 'POST',
          url: "".concat(_urls.CUSTOMDATA_URL, "/").concat(params.xlsx ? 'xlsx' : 'upload'),
          headers: {
            Authorization: (0, _utils.tokenHeader)(token).headers.get('Authorization')
          },
          data: form,
          onUploadProgress: params && params.onUploadProgress || undefined,
          signal: abortController.signal
        });
      }
    };
  });
};

exports.upload = upload;
var ELEMENT_REQUEST = 'ELEMENT_REQUEST';
exports.ELEMENT_REQUEST = ELEMENT_REQUEST;
var ELEMENT_SUCCESS = 'ELEMENT_SUCCESS';
exports.ELEMENT_SUCCESS = ELEMENT_SUCCESS;
var ELEMENT_FAILURE = 'ELEMENT_FAILURE';
exports.ELEMENT_FAILURE = ELEMENT_FAILURE;

var getElement = function getElement(_id) {
  return (0, _utils.thunkCreator)({
    types: [ELEMENT_REQUEST, ELEMENT_SUCCESS, ELEMENT_FAILURE],
    promise: function promise(token) {
      return fetch("".concat(_urls.CUSTOMDATA_URL, "/json/").concat(_id));
    }
  });
};

exports.getElement = getElement;
var CREATE_ELEMENT_REQUEST = 'CREATE_ELEMENT_REQUEST';
exports.CREATE_ELEMENT_REQUEST = CREATE_ELEMENT_REQUEST;
var CREATE_ELEMENT_SUCCESS = 'CREATE_ELEMENT_SUCCESS';
exports.CREATE_ELEMENT_SUCCESS = CREATE_ELEMENT_SUCCESS;
var CREATE_ELEMENT_FAILURE = 'CREATE_ELEMENT_FAILURE';
exports.CREATE_ELEMENT_FAILURE = CREATE_ELEMENT_FAILURE;

var createElement = function createElement(json, tag) {
  return (0, _utils.thunkCreator)(function (state) {
    return {
      types: [CREATE_ELEMENT_REQUEST, CREATE_ELEMENT_SUCCESS, CREATE_ELEMENT_FAILURE],
      promise: function promise(token) {
        return fetch("".concat(_urls.CUSTOMDATA_URL, "/json"), Object.assign({
          method: 'POST',
          body: JSON.stringify({
            data: json,
            tag: tag
          })
        }, (0, _utils.tokenHeader)(token)));
      }
    };
  });
};

exports.createElement = createElement;
var UPDATE_ELEMENT_REQUEST = 'UPDATE_ELEMENT_REQUEST';
exports.UPDATE_ELEMENT_REQUEST = UPDATE_ELEMENT_REQUEST;
var UPDATE_ELEMENT_SUCCESS = 'UPDATE_ELEMENT_SUCCESS';
exports.UPDATE_ELEMENT_SUCCESS = UPDATE_ELEMENT_SUCCESS;
var UPDATE_ELEMENT_FAILURE = 'UPDATE_ELEMENT_FAILURE';
exports.UPDATE_ELEMENT_FAILURE = UPDATE_ELEMENT_FAILURE;

var updateElement = function updateElement(elementId, json) {
  return (0, _utils.thunkCreator)(function (state) {
    return {
      types: [UPDATE_ELEMENT_REQUEST, UPDATE_ELEMENT_SUCCESS, UPDATE_ELEMENT_FAILURE],
      promise: function promise(token) {
        return fetch("".concat(_urls.CUSTOMDATA_URL, "/json/").concat(elementId), Object.assign({
          method: 'POST',
          body: JSON.stringify({
            data: json
          })
        }, (0, _utils.tokenHeader)(token)));
      }
    };
  });
};

exports.updateElement = updateElement;
var UPDATE_ELEMENT_INFO_REQUEST = 'UPDATE_ELEMENT_INFO_REQUEST';
exports.UPDATE_ELEMENT_INFO_REQUEST = UPDATE_ELEMENT_INFO_REQUEST;
var UPDATE_ELEMENT_INFO_SUCCESS = 'UPDATE_ELEMENT_INFO_SUCCESS';
exports.UPDATE_ELEMENT_INFO_SUCCESS = UPDATE_ELEMENT_INFO_SUCCESS;
var UPDATE_ELEMENT_INFO_FAILURE = 'UPDATE_ELEMENT_INFO_FAILURE';
exports.UPDATE_ELEMENT_INFO_FAILURE = UPDATE_ELEMENT_INFO_FAILURE;

var updateElementInfo = function updateElementInfo(elementId, info) {
  return (0, _utils.thunkCreator)(function (state) {
    return {
      types: [UPDATE_ELEMENT_INFO_REQUEST, UPDATE_ELEMENT_INFO_SUCCESS, UPDATE_ELEMENT_INFO_FAILURE],
      promise: function promise(token) {
        return fetch("".concat(_urls.CUSTOMDATA_URL, "/json/").concat(elementId), Object.assign({
          method: 'POST',
          body: JSON.stringify(info)
        }, (0, _utils.tokenHeader)(token)));
      }
    };
  });
};

exports.updateElementInfo = updateElementInfo;
var DELETE_ELEMENT_REQUEST = 'DELETE_ELEMENT_REQUEST';
exports.DELETE_ELEMENT_REQUEST = DELETE_ELEMENT_REQUEST;
var DELETE_ELEMENT_SUCCESS = 'DELETE_ELEMENT_SUCCESS';
exports.DELETE_ELEMENT_SUCCESS = DELETE_ELEMENT_SUCCESS;
var DELETE_ELEMENT_FAILURE = 'DELETE_ELEMENT_FAILURE';
exports.DELETE_ELEMENT_FAILURE = DELETE_ELEMENT_FAILURE;

var deleteElement = function deleteElement(id, type) {
  return (0, _utils.thunkCreator)(function (state) {
    return {
      types: [DELETE_ELEMENT_REQUEST, DELETE_ELEMENT_SUCCESS, DELETE_ELEMENT_FAILURE],
      promise: function promise(token) {
        return fetch("".concat(_urls.CUSTOMDATA_URL, "/").concat(type, "/").concat(id), Object.assign({
          method: 'DELETE'
        }, (0, _utils.tokenHeader)(token)));
      }
    };
  });
};

exports.deleteElement = deleteElement;
var XLSX_REQUEST = 'XLSX_REQUEST';
exports.XLSX_REQUEST = XLSX_REQUEST;
var XLSX_SUCCESS = 'XLSX_SUCCESS';
exports.XLSX_SUCCESS = XLSX_SUCCESS;
var XLSX_FAILURE = 'XLSX_FAILURE';
exports.XLSX_FAILURE = XLSX_FAILURE;

var getXLSX = function getXLSX(_id) {
  return (0, _utils.thunkCreator)({
    types: [XLSX_REQUEST, XLSX_SUCCESS, XLSX_FAILURE],
    promise: function promise(token) {
      return fetch("".concat(_urls.CUSTOMDATA_URL, "/xlsx/").concat(_id));
    }
  });
};

exports.getXLSX = getXLSX;
var PARSE_XLSX_REQUEST = 'PARSE_XLSX_REQUEST';
exports.PARSE_XLSX_REQUEST = PARSE_XLSX_REQUEST;
var PARSE_XLSX_SUCCESS = 'PARSE_XLSX_SUCCESS';
exports.PARSE_XLSX_SUCCESS = PARSE_XLSX_SUCCESS;
var PARSE_XLSX_FAILURE = 'PARSE_XLSX_FAILURE';
exports.PARSE_XLSX_FAILURE = PARSE_XLSX_FAILURE;

var parseXLSX = function parseXLSX(_id, config) {
  return (0, _utils.thunkCreator)(function (state) {
    return {
      types: [PARSE_XLSX_REQUEST, PARSE_XLSX_SUCCESS, PARSE_XLSX_FAILURE],
      promise: function promise(token) {
        return fetch("".concat(_urls.CUSTOMDATA_URL, "/xlsx/").concat(_id), Object.assign({
          method: 'POST',
          body: JSON.stringify(config)
        }, (0, _utils.tokenHeader)(token)));
      }
    };
  });
};

exports.parseXLSX = parseXLSX;