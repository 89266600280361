
import React, { useState, useEffect } from 'react'

import { ErrorBoundary, ErrorUI, generateSentryID, initializeSentry } from '@touchlay/utils/dist/errors'

// initialize error reporting to sentry
const PACKAGE = require('../../package.json')
const Sentry = initializeSentry('presentations', PACKAGE.version)

const generateError = (rest) => ({
  title: 'We detected an error with presentations',
  description: (
    <>
      Something went wrong, please <a href='/' style={{ color: 'unset' }}>try again</a>
    </>
  ),
  ...rest,
})

export const ErrorContainer = ({ children }) => {
  const [ error, setError ] = useState(null)
  const [ sentryId, setSentryId ] = useState(null)
  const { _sentry } = error || {}
  useEffect(() => {
    if (sentryId) {
      return
    }
    if (error) {
      const sID = generateSentryID()
      Sentry.setTag('issue-id', sID)
      setSentryId(sID)
      if (Array.isArray(_sentry)) {
        Sentry.captureException(_sentry[0], {
          extra: _sentry[1],
        })
      } else {
        Sentry.captureException(_sentry)
      }
    }
  }, [ error, sentryId ])
  if (error) {
    return <ErrorUI {...error} sentryId={sentryId} />
  }
  const reportError = (error, errorInfo) => {
    setError(generateError({
      _sentry: errorInfo ? [ error, errorInfo ] : error,
    }))
  }
  return (
    <ErrorBoundary reportError={reportError}>
      {children}
    </ErrorBoundary>
  )
}
export default ErrorContainer
