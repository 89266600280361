"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useTrack = void 0;

var useTrack = function useTrack() {
  return function () {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return console.warn('DEPRECATED useTrack USED. ARGS:', args);
  };
};

exports.useTrack = useTrack;