import * as React from 'react'

import { DebouncedInput } from '@touchlay/frontend-base'

import { useLanguage, usePDTranslation } from '@touchlay/utils'
import { asListItem, withDefault } from './utils'

// FIXME: multiline isn't working
export const EditItemInput = asListItem(({ info, label, value, setData, node, treeData, extra: _extra, ...props }) => {
  const extra = withDefault(_extra, {
    translated: false,
    maxLength: 128,
  })
  const lang = useLanguage()
  const tPD = usePDTranslation()
  const language = extra?.translated
    ? lang
    : false
  const updateLabel = language ? `${label}.${language}` : label
  const rValue = tPD(value)
  const handleChange = (val) => {
    setData({
      [updateLabel]: val,
    })
  }
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <DebouncedInput
        disabled={info?.disabled}
        maxLength={extra.maxLength}
        onChange={handleChange}
        value={rValue}
      />
    </div>
  )
})

export default EditItemInput
