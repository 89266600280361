/*
  Auto generated file
    Re-exports devMode - used for having separate import scopes #17
*/
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _devMode = require("./dist/devModeComplete");

Object.keys(_devMode).forEach(function (key) {
  if (key === "__esModule") return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _devMode[key];
    }
  });
});
