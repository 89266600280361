import * as React from 'react'
import { asListItem, withDefault } from './utils'
import { DebouncedSlider } from '@touchlay/frontend-base'

export const EditItemSlider = asListItem(({
  info,
  label, value,
  setData,
  extra: _extra,
}) => {
  const extra = withDefault(_extra, {
    toValue: v => v,
    fromValue: v => v,
    formatValue: undefined,
    values: [],
  })
  const values = extra?.values || []
  const val = extra.toValue(value || info?.defaultValue)
  const valueLabelFormat = extra.formatValue || extra.fromValue
  return (
    <DebouncedSlider
      disabled={info?.disabled}
      marks={values}
      max={values[values.length - 1].value}
      min={values[0].value}
      onChange={val => setData({
        [label]: extra.fromValue(val),
      })}
      step={null}
      style={{ width: 'calc(100% - 8px)' }}
      value={val}
      valueLabelDisplay='auto'
      valueLabelFormat={valueLabelFormat}
    />
  )
})

export default EditItemSlider
