"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _styles = require("@material-ui/styles");

var _colors = require("@material-ui/core/colors");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var colorTemplate = function colorTemplate(name, color) {
  var _ref;

  return _ref = {}, _defineProperty(_ref, "".concat(name, "Color"), {
    color: color
  }), _defineProperty(_ref, "".concat(name, "BackgroundColor"), {
    backgroundColor: color
  }), _ref;
};

var useStatusStyles = (0, _styles.makeStyles)(function (theme) {
  return _objectSpread(_objectSpread(_objectSpread({}, colorTemplate('online', _colors.lightGreen[500])), colorTemplate('lagged', _colors.orange[500])), colorTemplate('offline', _colors.grey[500]));
});
var _default = useStatusStyles;
exports["default"] = _default;