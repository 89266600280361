"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.makeTLStyles = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _react = require("react");

var _styles = require("@material-ui/styles");

var _helpers = require("./helpers");

var _multiKeyStore = _interopRequireDefault(require("./multiKeyStore"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

/*
  most of the code here is based on material-ui's functionality
  the behavior is mostly simplified so it only handles the different styleClasses provided by the theme

  this tries to reach a middle point between the dynamic & static sheets of material-ui
  they behave like dynamic ones when it comes to rough behavior - yet they are cached and worked with as they're static, this way we can share styles over multiple components that are of the same variant

  notes:
    `variant` here is used to talk about our internal version of it a.k.a. a styleClass
*/
var getStyles = function getStyles(theme, name, variant) {
  var _components$name;

  var components = theme === null || theme === void 0 ? void 0 : theme.components;

  if (components && (_components$name = components[name]) !== null && _components$name !== void 0 && _components$name.styleClasses) {
    var styleClasses = components[name].styleClasses;

    if (styleClasses && styleClasses[variant]) {
      return styleClasses[variant];
    }
  }

  return {};
};
/* stable reference to an empty theme to allow mapping to it without issues */


var noopTheme = {};
/* functionality used to do the reference counting + initialization of the styling */

var attach = function attach(_ref) {
  var state = _ref.state,
      stylesOptions = _ref.stylesOptions,
      styleTracker = _ref.styleTracker,
      theme = _ref.theme,
      name = _ref.name,
      variant = _ref.variant;
  var sheetsRegistry = stylesOptions.sheetsRegistry;

  var sheetManager = _multiKeyStore["default"].get(stylesOptions.sheetsManager, styleTracker, theme, variant);

  var options = _objectSpread(_objectSpread({}, stylesOptions), {}, {
    theme: theme,
    flip: typeof stylesOptions.flip === 'boolean' ? stylesOptions.flip : theme.direction === 'rtl'
  });

  options.generateId = options.serverGenerateClassName || options.generateClassName;

  if (!sheetManager) {
    sheetManager = {
      refs: 0,
      variantSheet: null
    };

    _multiKeyStore["default"].set(stylesOptions.sheetsManager, styleTracker, theme, variant, sheetManager);
  }
  /* if it has been just initialized */


  if (sheetManager.refs === 0) {
    var variantSheet;

    if (stylesOptions.sheetsCache) {
      variantSheet = _multiKeyStore["default"].get(stylesOptions.sheetsCache, styleTracker, theme, variant);
    } // TODO: get these from theme


    var styles = getStyles(theme, name, variant);
    /* stylesCreator.create(theme, name) */

    if (!variantSheet) {
      variantSheet = stylesOptions.jss.createStyleSheet(styles, _objectSpread({
        link: false
      }, options));
      variantSheet.attach();

      if (stylesOptions.sheetsCache) {
        _multiKeyStore["default"].set(stylesOptions.sheetsCache, styleTracker, theme, variant, variantSheet);
      }
    }

    if (sheetsRegistry) {
      sheetsRegistry.add(variantSheet);
    }

    sheetManager.variantSheet = variantSheet;
  }

  state.classes = sheetManager.variantSheet.classes;
  sheetManager.refs += 1;
};

function detach(_ref2) {
  var state = _ref2.state,
      theme = _ref2.theme,
      stylesOptions = _ref2.stylesOptions,
      styleTracker = _ref2.styleTracker,
      variant = _ref2.variant;

  var sheetManager = _multiKeyStore["default"].get(stylesOptions.sheetsManager, styleTracker, theme, variant);

  if (!sheetManager) {
    // console.warn('sheet manager not found for', variant)
    return;
  }

  sheetManager.refs -= 1;
  var sheetsRegistry = stylesOptions.sheetsRegistry;

  if (sheetManager.refs === 0) {
    _multiKeyStore["default"]["delete"](stylesOptions.sheetsManager, styleTracker, theme, variant);

    stylesOptions.jss.removeStyleSheet(sheetManager.variantSheet);

    if (sheetsRegistry) {
      sheetsRegistry.remove(sheetManager.variantSheet);
    }
  }
}
/*
  Mostly compatible version of makeStyles that allows for styleClass variants given by props
*/


var makeTLStyles = function makeTLStyles(style, options) {
  var useNormalStyles = (0, _styles.makeStyles)(style, options);

  if (!(options !== null && options !== void 0 && options.name)) {
    /* if no name was provided - we fallback to material-ui's default behavior, as no override can be done anyway */
    return useNormalStyles;
  }
  /* reference used to keep track of this specific call of makeTouchlayStyles */


  var styleTracker = {
    name: options.name
  };
  return function (props) {
    var classesMUI = useNormalStyles(props);
    /* we must provide a stable reference as default so the  */

    var stylesOptions = (0, _react.useContext)(_styles.StylesContext);
    var theme = (0, _styles.useTheme)() || noopTheme;
    var name = options.name;
    var variant = props === null || props === void 0 ? void 0 : props.styleClass;
    var instance = (0, _react.useRef)();
    (0, _helpers.useSynchronousEffect)(function () {
      var current = {
        state: {},
        styleTracker: styleTracker,
        stylesOptions: stylesOptions,
        theme: theme,
        name: name,
        variant: variant
      };
      attach(current);
      instance.current = current;
      return function () {
        detach(current);
      };
    }, [theme, styleTracker, variant]);
    var finalClasses = (0, _react.useMemo)(function () {
      return (0, _helpers.mergeClasses)(classesMUI, instance.current.state.classes);
    }, [classesMUI, instance.current.state.classes]);
    return finalClasses;
  };
};

exports.makeTLStyles = makeTLStyles;