import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

import { CopyToClipboard } from 'react-copy-to-clipboard'

import { HelpTooltip, ListInput, NovaIcon, DashboardPlayerDownload } from '@touchlay/frontend-base'
import LoadingList from '@touchlay/frontend-base/dist/components/LoadingList'
import { useLoadManyMemo, useModal, makePreviewLink, downloadLicenseFile } from '@touchlay/frontend-base/dist/utils'
import {
  getPresentation, updateDevice, deleteDevice, createDevice,
  getPresentationModifications, publishPresentation,
} from '@touchlay/frontend-base/dist/actions'
import { usePermissions, Permissions } from '@touchlay/frontend-base/dist/permissions'

import { makeStyles } from '@material-ui/styles'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListSubheader from '@material-ui/core/ListSubheader'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import Switch from '@material-ui/core/Switch'
import Typography from '@material-ui/core/Typography'
import PublishIcon from '@material-ui/icons/Publish'
import LinkIcon from '@material-ui/icons/Link'
import DeleteIcon from '@material-ui/icons/Delete'
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows'
import ContentCopyIcon from '@material-ui/icons/ContentCopy'

const useStyles = makeStyles((theme) => ({
  inputContainer: {
    width: '55%',
    paddingTop: '6px',
    display: 'flex',
    alignItems: 'center',
  },
  publishBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  lastPublished: {
    paddingTop: theme.spacing(3),
  },
  colorOnline: {
    color: '#4CAF50',
  },
  colorOffline: {
    color: '#9E9E9E',
  },
}))

export default function PublishEditor ({ presentation, modifications, devices }) {
  const [ modalComponent, openModal ] = useModal()
  const dispatch = useDispatch()
  const styles = useStyles()
  const [ publishing, setPublishing ] = useState(false)
  const currentPresentation = presentation?._id
  const hasPermission = usePermissions(presentation)
  const canPublishPresentation =
    hasPermission(Permissions.WRITE) ||
    (hasPermission(Permissions.WRITE_USER) && modifications?.published)

  const needsMainPublish = hasPermission(Permissions.WRITE_USER) && !modifications?.published

  const presentationIds = devices.reduce((acc, d) => {
    if (d.presentation) {
      acc.add(d.presentation)
    }
    return acc
  }, new Set())
  const presentations = useLoadManyMemo(x => x.presentations, getPresentation, Array.from(presentationIds))

  const lastPublished = presentation?.publishedAt

  const links = devices
    .filter(d =>
      d?.meta?.type === 'link' && d?.presentation === currentPresentation
    )
    .map(d => {
      const BASE_URL = typeof window !== 'undefined'
        ? location.protocol + '//' + location.host
        : ''
      if (BASE_URL.length <= 0) {
        console.warn('could not get base URL from window object!')
      }
      return {
        ...d,
        link: BASE_URL + makePreviewLink(d),
        icon: <LinkIcon />,
      }
    })

  const pureDevices = devices
    .filter(d => d?.meta?.type === 'device')
    .map(d => {
      const online = new Date(d.lastPing).getTime() >= (Date.now() - 5 * 60 * 1000)
      const color = online ? styles.colorOnline : styles.colorOffline
      const text = (
        <span>
          <b>{online ? 'online' : 'offline'}</b>
          {d.lastPing ? `, last update: ${new Date(d.lastPing).toLocaleString()}` : ''}
        </span>
      )
      return {
        ...d,
        assignedTo: d.presentation &&
          presentations.find(p => p._id === d.presentation)?.name,
        icon: (
          <Tooltip title={text}>
            {d.meta?.type === 'nova'
              ? <NovaIcon className={color} />
              : <DesktopWindowsIcon className={color} />}
          </Tooltip>
        ),
      }
    })
    .sort((a, b) => {
      const aAssigned = a.presentation === currentPresentation
      const bAssigned = b.presentation === currentPresentation
      // show the ones with this presentation assigned first
      if (!aAssigned && bAssigned) {
        return 1
      }
      if (aAssigned && !bAssigned) {
        return -1
      }
      // show the ones with another presentation assigned last
      if (!a.presentation && b.presentation) {
        return -1
      }
      if (a.presentation && !b.presentation) {
        return 1
      }
      // if they are otherwise equal, sort by name
      return a.name.localeCompare(b.name)
    })

  const alreadyAssignedWarning = presentationName => `This device is already assigned to "${presentationName}".
Are you sure you want to re-assign it to the current presentation?`

  const handleAssign = d => e => {
    const presentationName = d?.presentation && presentations?.find(p => d.presentation === p._id)?.name
    /* checked must be saved now, as it might be different once user confirms with modal */
    const checked = e.target.checked
    const confirmed = (d?.presentation?.length > 0 && d?.presentation !== currentPresentation)
      ? cb => {
        openModal('confirm', {
          title: 'Assign presentation',
          desc: alreadyAssignedWarning(presentationName),
          accept: cb,
          severity: 'warning',
        })
      }
      : cb => cb()
    confirmed(() => {
      dispatch(updateDevice(d._id, {
        presentation: checked ? currentPresentation : null,
      }))
    })
  }

  const handleDelete = (d, type) => () => {
    openModal('confirm', {
      title: 'Delete device',
      desc: `This action is irreversible!
The presentation will not be accessible anymore on this ${type}!
Are you sure you want to delete this ${type}?`,
      accept: () => {
        dispatch(deleteDevice(d._id))
      },
      severity: 'warning',
    })
  }

  const handleCreate = (type) => () =>
    dispatch(
      createDevice('New ' + type.slice(0, 1).toUpperCase() + type.slice(1), {
        meta: { type },
        presentation: currentPresentation,
      })
    )

  const handleUpdate = (d, name) =>
    dispatch(
      updateDevice(d._id, { name })
    )

  const publish = () => {
    setPublishing(true)
    dispatch(publishPresentation(currentPresentation))
      .then(() => dispatch(getPresentationModifications(currentPresentation)))
      .finally(() => setPublishing(false))
  }

  if (!presentation || !devices) {
    return <LoadingList name='devices' />
  }

  return (
    <List>
      {modalComponent}
      <ListSubheader color='primary'>
        Publish{' '}
        <HelpTooltip text={'Changes you make are not visible on public links and devices until you publish them.'} />
      </ListSubheader>
      <ListItem className={styles.publishBox}>
        <Button
          color='primary'
          disabled={
            !canPublishPresentation ||
            publishing ||
            !modifications?.canPublish
          } onClick={publish}
          startIcon={<PublishIcon />} variant='contained'
        >
          Publish presentation
        </Button>
        <small className={styles.lastPublished}>
          Last Published:{' '}
          {lastPublished
            ? new Date(lastPublished).toLocaleString()
            : 'Not yet published'}
        </small>
        {
          needsMainPublish && (
            <Typography color='textSecondary'>
              <i>
                {'The presentation has not been published yet, please ask someone with edit access to the full presentation to publish it first.'}
              </i>
            </Typography>
          )
        }
        {(!needsMainPublish && !canPublishPresentation && modifications?.canPublish) && (
          <Typography color='textSecondary'>
            <i>
              {"You don't have edit permissions, please ask an authorized user "}
              {'to give you edit access to this presentation to be able to publish your previous changes.'}
            </i>
          </Typography>
        )}
      </ListItem>
      <ListInput
        addPhrase={'create'}
        addPhrasePast={'created'}
        data={links}
        description={
          'Public Links allow sharing the presentation with others via a web link. ' +
          'Anyone with access to the link can view the published presentation, ' +
          'without an account. They cannot edit anything.'
        }
        iconKey={'icon'}
        inlineEditable
        itemTooltip={() => 'click to open the link'}
        name='Public Link'
        nameKey={'name'}
        onAdd={handleCreate('link')}
        onClick={d => window.open(d.link, '_blank')}
        onNameChange={handleUpdate}
        secondaryActions={d => [
          <CopyToClipboard key='copy' text={d.link}>
            <Tooltip title='copy link'>
              <IconButton>
                <ContentCopyIcon />
              </IconButton>
            </Tooltip>
          </CopyToClipboard>,
          <Tooltip key='delete' title='delete link'>
            <IconButton onClick={handleDelete(d, 'link')}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>,
        ]}
      />
      <ListInput
        addPhrase={'create'}
        additionalKey={'assignedTo'}
        data={pureDevices}
        iconKey={'icon'}
        inlineEditable
        itemTooltip={() => 'click to download the license file'}
        name='Device'
        nameKey={'name'}
        onAdd={handleCreate('device')}
        onClick={downloadLicenseFile}
        onNameChange={handleUpdate}
        secondaryActions={d => {
          const checked = d.presentation === currentPresentation
          return [
            <Tooltip key='switch' title={`${checked ? 'unassign' : 'assign'} device`}>
              <Switch
                checked={checked}
                color='primary'
                onChange={handleAssign(d)}
              />
            </Tooltip>,
            <Tooltip key='delete' title='delete device'>
              <IconButton onClick={handleDelete(d, 'device')}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>,
          ]
        }}
      />
      <br />
      <DashboardPlayerDownload />
      <br />
      <br />
    </List>
  )
}
