import React from 'react'

import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { HelpTooltip } from '@touchlay/frontend-base'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(() => ({
  inputContainer: {
    width: '45%',
  },
  listItemFull: {
    flexDirection: 'column',
    alignItems: 'self-end',
  },
  inputContainerFull: {
    width: '100%',
  },
}))

export const ListEditItem = ({ info, options, children }) => {
  const styles = useStyles()
  return (
    <ListItem className={options?.fullWidth ? styles.listItemFull : ''}>
      <ListItemText>
        {info?.formattedLabel}
        {' '}
        {info?.helpText && <HelpTooltip text={info.helpText} />}
      </ListItemText>
      <div className={options?.fullWidth ? styles.inputContainerFull : styles.inputContainer}>
        {children}
      </div>
    </ListItem>
  )
}
export const asListItem = (Component, options = {}) => {
  function _asListItem (props) {
    return (
      <ListEditItem info={props.info} options={options}>
        <Component {...props} />
      </ListEditItem>
    )
  }
  return _asListItem
}

export const formatLabel = label => {
  // add spaces before uppercase letters
  const spacedLabel = label.replace(/([A-Z])/g, ' $1').trim()
  // make first letter uppercase
  return spacedLabel.slice(0, 1).toUpperCase() + spacedLabel.slice(1)
}

export const withDefault = (extra, defs) => ({
  ...defs,
  ...(extra || {}),
})

export const lastItemsEqual = (arr, match) => {
  if (!Array.isArray(arr)) return false
  for (let i = 0; i < match.length; i++) {
    if (match[match.length - 1 - i] !== arr[arr.length - 1 - i]) {
      return false
    }
  }
  return true
}
