"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _utils = require("@touchlay/utils");

var _genericButtonView = require("../utils/genericButtonView");

/*
type LinkViewProps = {
  global?: boolean,
  options: {
    position?: string,
    style?: Stylesheet,
    title?: string,
    route: string,
    variant?: string,
    textVariant?: string,
    icon?: string,
    startIcon?: string,
    endIcon?: string,
  }
}
*/
var LinkView = (0, _genericButtonView.createButtonView)('LinkView', function (_ref) {
  var options = _ref.options;
  var dispatch = (0, _utils.useActions)('#link#');

  var onClick = function onClick() {
    dispatch({
      type: 'action',
      name: 'history.push',
      url: options === null || options === void 0 ? void 0 : options.route,
      origin: 'user'
    });
  };

  return {
    onClick: onClick
  };
});
var _default = LinkView;
exports["default"] = _default;