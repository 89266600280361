"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _typeof2 = _interopRequireDefault(require("@babel/runtime/helpers/typeof"));

function isObject(item)
/*: boolean %checks */
{
  return !!item && (0, _typeof2["default"])(item) === 'object' && !Array.isArray(item) && item !== null;
}

function deepMerge(target, source) {
  var output = Object.assign({}, target);

  if (isObject(target) && isObject(source)) {
    Object.keys(source).forEach(function (key) {
      if (isObject(source[key])) {
        if (!(key in target) || !isObject(target[key])) {
          Object.assign(output, (0, _defineProperty2["default"])({}, key, source[key]));
        } else {
          output[key] = deepMerge(target[key], source[key]);
        }
      } else {
        Object.assign(output, (0, _defineProperty2["default"])({}, key, source[key]));
      }
    });
  }

  return output;
}

module.exports = {
  isObject: isObject,
  deepMerge: deepMerge
};