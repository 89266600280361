import React from 'react'

import { ColorInput } from '@touchlay/frontend-base'
import { asListItem } from './utils'

export const EditItemColor = asListItem(({ info, label, value, setData }) => {
  const onChange = (val) =>
    setData({
      [label]: val,
    })
  return (
    <ColorInput
      disabled={info?.disabled}
      onChange={onChange}
      value={value}
    />
  )
})

export default EditItemColor
