"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.spacingFn = exports.spacing = exports.fib9 = exports.fib8 = exports.fib7 = exports.fib6 = exports.fib5 = exports.fib4 = exports.fib3 = exports.fib2 = exports.fib12 = exports.fib11 = exports.fib10 = exports.fib1 = void 0;
var fib1 = 2;
exports.fib1 = fib1;
var fib2 = 3;
exports.fib2 = fib2;
var fib3 = 5;
exports.fib3 = fib3;
var fib4 = 8;
exports.fib4 = fib4;
var fib5 = 13;
exports.fib5 = fib5;
var fib6 = 21;
exports.fib6 = fib6;
var fib7 = 34;
exports.fib7 = fib7;
var fib8 = 55;
exports.fib8 = fib8;
var fib9 = 89;
exports.fib9 = fib9;
var fib10 = 144;
exports.fib10 = fib10;
var fib11 = 233;
exports.fib11 = fib11;
var fib12 = 377;
exports.fib12 = fib12;
var spacing = [0, 2, 3, 5, 8, 13, 21, 34, 55, 89, 144, 233, 377];
exports.spacing = spacing;

var spacingFn = function spacingFn(factor) {
  return factor >= 12 ? spacing[12] : factor <= 0 ? spacing[0] : spacing[factor];
};

exports.spacingFn = spacingFn;