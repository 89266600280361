"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.style = exports["default"] = void 0;

var _styles = require("@material-ui/styles");

var _spacings = require("../spacings");

var style = function style(theme) {
  return {
    container: {
      display: 'flex',
      alignItems: 'center',
      height: '100%',
      backgroundColor: theme.palette.background["default"],
      paddingLeft: _spacings.fib8,
      paddingRight: _spacings.fib8
    },
    containerCollapsed: {
      justifyContent: 'center'
    },
    form: {
      display: 'flex',
      alignItems: 'left',
      flexDirection: 'column',
      paddingTop: _spacings.fib5
    },
    formExpanded: {
      justifyContent: 'center',
      paddingTop: 116 // align with branding

    },
    input: {
      width: '100%'
    },
    icon: {
      maxHeight: '32px',
      marginRight: _spacings.fib5
    },
    block: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: _spacings.fib7
    },
    verticalBlock: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginBottom: _spacings.fib7
    },
    paperBlock: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      width: '100%',
      boxSizing: 'border-box',
      padding: _spacings.fib6,
      '& > h5': {
        marginBottom: _spacings.fib5
      }
    },
    interestedBlock: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      width: '100%',
      boxSizing: 'border-box',
      padding: _spacings.fib6,
      paddingTop: _spacings.fib7,
      '& > h5': {
        paddingBottom: _spacings.fib5
      }
    },
    button: {
      width: '100%',
      marginBottom: _spacings.fib5
    }
  };
};

exports.style = style;

var _default = (0, _styles.makeStyles)(style);

exports["default"] = _default;