import * as React from 'react'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { asListItem, withDefault } from './utils'

const EditItemSelect = asListItem(({ info, label, value, extra: _extra, setData }) => {
  const extra = withDefault(_extra, {
    values: [],
  })
  const onChange = val => {
    const x = val?.target?.value
    if (!x) return
    setData({
      [label]: x,
    })
  }
  return (
    <Select
      disabled={info?.disabled}
      fullWidth
      onChange={onChange}
      value={value || info?.defaultValue}
    >
      {extra.values.map(({ label, value }) =>
        <MenuItem key={'option-' + label} value={value}>{label}</MenuItem>
      )}
    </Select>
  )
})

export default EditItemSelect
