import React from 'react'

import AddNode from './AddNode'
import GenericEditView from './GenericEditView'
import { findNode, updateNode, deleteNode } from './treeUtils'

import LoadingList from '@touchlay/frontend-base/dist/components/LoadingList'

import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

function LoadingData () {
  return <LoadingList name='data' />
}

function Error ({ err, openSubpage }) {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', textAlign: 'center', padding: '2em 1em' }}>
      <Typography color='primary' style={{ paddingBottom: '0.5em' }} variant='h4'>{err}</Typography>
      <Button onClick={() => openSubpage(false)} variant='outlined'>
        Go back to main page
      </Button>
    </div>
  )
}

const getIndexKey = (treeData, path) => {
  let keys = ''
  let tree = treeData
  for (const p of path) {
    const ix = Array.isArray(tree) ? tree.findIndex((t) => p === t.id) : -1
    if (ix >= 0) keys = `${keys}-${ix}`
    else {
      console.warn('id not found in tree - invalid state', p, tree)
    }
    tree = tree[ix].children
  }
  return keys
}

/*
  The logic here is that there's some shared logic which must be done only on some cases
*/
export default function EditView ({ id, mode, treeData, topLevel, setData, subpage, openSubpage, openSubpageBatch, presentation, presentationStyle, layout }) {
  if (!treeData || treeData.length === 0) return <LoadingData />
  if (!layout) return <LoadingData />

  const getResources = () => {
    const { err, node, path } = findNode(id, treeData)
    if (err) return { error: <Error err={err} openSubpage={openSubpage} /> }
    if (!node) return { error: <LoadingData /> }
    const { node: parent } = findNode(path[path.length - 2], treeData)
    const keysPath = getIndexKey(treeData, path)
    const removeNode = () => {
      const treeNew = deleteNode(treeData, path)
      setData(treeNew)
    }
    return { node, parent, keysPath, removeNode, path }
  }
  if (subpage === 'add') {
    let res = null
    if (id !== undefined) {
      res = getResources()
    }
    if (res?.error) {
      return res.error
    }
    return (
      <div>
        <AddNode
          id={id}
          key={id}
          node={res?.node}
          openSubpage={openSubpage}
          setData={setData}
          topLevel={topLevel}
          treeData={treeData}
        />
      </div>
    )
  } else { // if (subpage === 'element' )
    const { error, node, parent, keysPath, removeNode, path } = getResources()
    if (!node) {
      return error
    }
    return (
      <div>
        <GenericEditView
          data={node}
          key={keysPath}
          layout={layout}
          mode={mode}
          openSubpage={openSubpage}
          openSubpageBatch={openSubpageBatch}
          parent={parent}
          presentation={presentation}
          presentationStyle={presentationStyle}
          removeNode={removeNode}
          topLevel={topLevel}
          treeData={treeData}
          updateData={(newNode, cb) => setData(updateNode(treeData, path, newNode), cb)}
        />
      </div>
    )
  }
}
