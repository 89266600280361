"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateUserInfo = exports.passwordResetSuccess = exports.passwordResetRequest = exports.passwordResetError = exports.logoutSuccess = exports.logoutRequest = exports.logoutError = exports.inviteUser = exports.getUserInfo = exports.getUserById = exports.getUserByEmail = exports.expiredToken = exports.authSuccess = exports.authRequest = exports.authError = exports.USER_INFO_SUCCESS = exports.USER_INFO_REQUEST = exports.USER_INFO_FAILURE = exports.USER_BY_ID_SUCCESS = exports.USER_BY_ID_REQUEST = exports.USER_BY_ID_FAILURE = exports.USER_BY_EMAIL_SUCCESS = exports.USER_BY_EMAIL_REQUEST = exports.USER_BY_EMAIL_FAILURE = exports.UPDATE_USER_INFO_SUCCESS = exports.UPDATE_USER_INFO_REQUEST = exports.UPDATE_USER_INFO_FAILURE = exports.UNAUTHORIZED = exports.PASSWORD_RESET_SUCCESS = exports.PASSWORD_RESET_REQUEST = exports.PASSWORD_RESET_ERROR = exports.LOGOUT_SUCCESS = exports.LOGOUT_REQUEST = exports.LOGOUT_ERROR = exports.INVITE_USER_SUCCESS = exports.INVITE_USER_REQUEST = exports.INVITE_USER_FAILURE = exports.EXPIRED_TOKEN = exports.AUTH_SUCCESS = exports.AUTH_REQUEST = exports.AUTH_ERROR = void 0;

var _utils = require("./utils");

var AUTH_REQUEST = 'AUTH_REQUEST';
exports.AUTH_REQUEST = AUTH_REQUEST;

var authRequest = function authRequest() {
  return {
    type: AUTH_REQUEST
  };
};

exports.authRequest = authRequest;
var AUTH_SUCCESS = 'AUTH_SUCCESS';
exports.AUTH_SUCCESS = AUTH_SUCCESS;

var authSuccess = function authSuccess(token) {
  return {
    type: AUTH_SUCCESS,
    token: token
  };
};

exports.authSuccess = authSuccess;
var AUTH_ERROR = 'AUTH_ERROR';
exports.AUTH_ERROR = AUTH_ERROR;

var authError = function authError(error) {
  return {
    type: AUTH_ERROR,
    error: error
  };
};

exports.authError = authError;
var LOGOUT_REQUEST = 'LOGOUT_REQUEST';
exports.LOGOUT_REQUEST = LOGOUT_REQUEST;

var logoutRequest = function logoutRequest() {
  return {
    type: LOGOUT_REQUEST
  };
};

exports.logoutRequest = logoutRequest;
var LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
exports.LOGOUT_SUCCESS = LOGOUT_SUCCESS;

var logoutSuccess = function logoutSuccess() {
  return {
    type: LOGOUT_SUCCESS
  };
};

exports.logoutSuccess = logoutSuccess;
var LOGOUT_ERROR = 'LOGOUT_ERROR';
exports.LOGOUT_ERROR = LOGOUT_ERROR;

var logoutError = function logoutError() {
  return {
    type: LOGOUT_ERROR
  };
};

exports.logoutError = logoutError;
var PASSWORD_RESET_REQUEST = 'PASSWORD_RESET_REQUEST';
exports.PASSWORD_RESET_REQUEST = PASSWORD_RESET_REQUEST;

var passwordResetRequest = function passwordResetRequest() {
  return {
    type: PASSWORD_RESET_REQUEST
  };
};

exports.passwordResetRequest = passwordResetRequest;
var PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS';
exports.PASSWORD_RESET_SUCCESS = PASSWORD_RESET_SUCCESS;

var passwordResetSuccess = function passwordResetSuccess() {
  return {
    type: PASSWORD_RESET_SUCCESS
  };
};

exports.passwordResetSuccess = passwordResetSuccess;
var PASSWORD_RESET_ERROR = 'PASSWORD_RESET_ERROR';
exports.PASSWORD_RESET_ERROR = PASSWORD_RESET_ERROR;

var passwordResetError = function passwordResetError() {
  return {
    type: PASSWORD_RESET_ERROR
  };
};

exports.passwordResetError = passwordResetError;
var USER_INFO_REQUEST = 'USER_INFO_REQUEST';
exports.USER_INFO_REQUEST = USER_INFO_REQUEST;
var USER_INFO_SUCCESS = 'USER_INFO_SUCCESS';
exports.USER_INFO_SUCCESS = USER_INFO_SUCCESS;
var USER_INFO_FAILURE = 'USER_INFO_FAILURE';
exports.USER_INFO_FAILURE = USER_INFO_FAILURE;

var getUserInfo = function getUserInfo(invite) {
  return (0, _utils.thunkCreator)(function (state) {
    return {
      types: [USER_INFO_REQUEST, USER_INFO_SUCCESS, USER_INFO_FAILURE],
      promise: function promise(token) {
        return fetch("".concat(process.env.REACT_APP_API_URL, "/user").concat(invite ? '?invite=' + invite : ''), (0, _utils.tokenHeader)(token));
      }
    };
  });
};

exports.getUserInfo = getUserInfo;
var UPDATE_USER_INFO_REQUEST = 'USER_INFO_REQUEST';
exports.UPDATE_USER_INFO_REQUEST = UPDATE_USER_INFO_REQUEST;
var UPDATE_USER_INFO_SUCCESS = 'USER_INFO_SUCCESS';
exports.UPDATE_USER_INFO_SUCCESS = UPDATE_USER_INFO_SUCCESS;
var UPDATE_USER_INFO_FAILURE = 'USER_INFO_FAILURE';
exports.UPDATE_USER_INFO_FAILURE = UPDATE_USER_INFO_FAILURE;

var updateUserInfo = function updateUserInfo(config) {
  return (0, _utils.thunkCreator)(function (state) {
    return {
      types: [UPDATE_USER_INFO_REQUEST, UPDATE_USER_INFO_SUCCESS, UPDATE_USER_INFO_FAILURE],
      promise: function promise(token) {
        return fetch("".concat(process.env.REACT_APP_API_URL, "/user"), Object.assign({
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(config)
        }, (0, _utils.tokenHeader)(token)));
      }
    };
  });
};

exports.updateUserInfo = updateUserInfo;
var USER_BY_ID_REQUEST = 'USER_BY_ID_REQUEST';
exports.USER_BY_ID_REQUEST = USER_BY_ID_REQUEST;
var USER_BY_ID_SUCCESS = 'USER_BY_ID_SUCCESS';
exports.USER_BY_ID_SUCCESS = USER_BY_ID_SUCCESS;
var USER_BY_ID_FAILURE = 'USER_BY_ID_FAILURE';
exports.USER_BY_ID_FAILURE = USER_BY_ID_FAILURE;

var getUserById = function getUserById(id) {
  return (0, _utils.thunkCreator)(function (state) {
    return {
      types: [USER_BY_ID_REQUEST, USER_BY_ID_SUCCESS, USER_BY_ID_FAILURE],
      promise: function promise(token) {
        return fetch("".concat(process.env.REACT_APP_API_URL, "/user/id/").concat(id), Object.assign({
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        }, (0, _utils.tokenHeader)(token)));
      }
    };
  });
};

exports.getUserById = getUserById;
var USER_BY_EMAIL_REQUEST = 'USER_BY_EMAIL_REQUEST';
exports.USER_BY_EMAIL_REQUEST = USER_BY_EMAIL_REQUEST;
var USER_BY_EMAIL_SUCCESS = 'USER_BY_EMAIL_SUCCESS';
exports.USER_BY_EMAIL_SUCCESS = USER_BY_EMAIL_SUCCESS;
var USER_BY_EMAIL_FAILURE = 'USER_BY_EMAIL_FAILURE';
exports.USER_BY_EMAIL_FAILURE = USER_BY_EMAIL_FAILURE;

var getUserByEmail = function getUserByEmail(email) {
  return (0, _utils.thunkCreator)(function (state) {
    return {
      types: [USER_BY_EMAIL_REQUEST, USER_BY_EMAIL_SUCCESS, USER_BY_EMAIL_FAILURE],
      promise: function promise(token) {
        return fetch("".concat(process.env.REACT_APP_API_URL, "/user/email/").concat(encodeURIComponent(email)), Object.assign({
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        }, (0, _utils.tokenHeader)(token)));
      }
    };
  });
};

exports.getUserByEmail = getUserByEmail;
var INVITE_USER_REQUEST = 'INVITE_USER_REQUEST';
exports.INVITE_USER_REQUEST = INVITE_USER_REQUEST;
var INVITE_USER_SUCCESS = 'INVITE_USER_SUCCESS';
exports.INVITE_USER_SUCCESS = INVITE_USER_SUCCESS;
var INVITE_USER_FAILURE = 'INVITE_USER_FAILURE';
exports.INVITE_USER_FAILURE = INVITE_USER_FAILURE;

var inviteUser = function inviteUser(email) {
  return (0, _utils.thunkCreator)(function (state) {
    return {
      types: [INVITE_USER_REQUEST, INVITE_USER_SUCCESS, INVITE_USER_FAILURE],
      promise: function promise(token) {
        return fetch("".concat(process.env.REACT_APP_API_URL, "/invite"), Object.assign({
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            email: email
          })
        }, (0, _utils.tokenHeader)(token)));
      }
    };
  });
};

exports.inviteUser = inviteUser;
var UNAUTHORIZED = 'UNAUTHORIZED';
exports.UNAUTHORIZED = UNAUTHORIZED;
var EXPIRED_TOKEN = 'EXPIRED_TOKEN';
exports.EXPIRED_TOKEN = EXPIRED_TOKEN;

var expiredToken = function expiredToken() {
  return {
    type: EXPIRED_TOKEN
  };
};

exports.expiredToken = expiredToken;