import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// Check if a timestamp is no longer than x secounds in the past
export const checkLastPing = (ping, seconds) => {
  const date = new Date()
  date.setSeconds(date.getSeconds() - seconds)
  return (date.getTime() <= new Date(ping).getTime())
}

export const useItem = (selector, id, getItem) => {
  const dispatch = useDispatch()
  const items = useSelector(selector)
  const item = items.filter(it => it._id === id).pop()
  useEffect(() => {
    dispatch(getItem(id))
  }, [ dispatch, getItem, id ])
  return item
}

// Create a new type, link to current Presentation and go to TypeConfigView for the new type
export const createAndLink = ({ type, createType, update, element, push }) =>
  createType(prompt('Enter name:'))
    .then(({ result }) => {
      update(element._id, { [type]: result._id })
      push(`/${type}/` + result._id)
    })
