"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useCached = void 0;

var _react = require("react");

var useCached = function useCached(cacheProperty) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var cb = arguments.length > 2 ? arguments[2] : undefined;

  var _cacheRef = (0, _react.useRef)(new Map());

  (0, _react.useEffect)(function () {
    _cacheRef.current = new Map();
  }, [cacheProperty]);
  var cachedCb = (0, _react.useCallback)(function (cache) {
    for (var _len = arguments.length, params = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      params[_key - 1] = arguments[_key];
    }

    var unify = options.cacheID ? options.cacheID(params) : JSON.stringify(params);
    /* slow approach */

    if (options.alwaysCached || cache) {
      var el = _cacheRef.current.get(unify);

      if (el) {
        return el;
      }
    } // eslint-disable-next-line node/no-callback-literal


    var value = cb.apply(void 0, params);

    if (options.alwaysCached || cache) {
      _cacheRef.current.set(unify, value);
    }

    return value;
  });
  return cachedCb;
};

exports.useCached = useCached;