import React from 'react'
import Button from '@material-ui/core/Button'
import PlayArrow from '@material-ui/icons/PlayArrow'

import { SettingsEditor } from '@touchlay/frontend-base'

export default function PresentationsSettingsEditor (props) {
  const options = {
    updateLogo: (layout, logo) => {
      return layout.map(l => {
        if (l.view === 'HeaderView') {
          return { ...l, projectLogo: logo.url }
        }
        return l
      })
    },
    // eslint-disable-next-line react/display-name
    additional: (presentation) => {
      return (
        <div style={{ width: '100%', padding: 14, paddingBottom: 0 }}>
          <Button
            color='primary'
            endIcon={<PlayArrow />}
            fullWidth
            href={`/view/${presentation._id}`}
            target='_blank'
            variant='contained'
          >
            Launch Preview Presentation
          </Button>
        </div>
      )
    },
  }

  return (
    // here we are extending SettingsEditor, so we need props spreading
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SettingsEditor {...props} options={options} />
  )
}
