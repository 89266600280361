import React from 'react'

import { usePDTranslation, useLanguage } from '@touchlay/utils'
import { asListItem, withDefault } from './utils'

import { RichTextInput } from '@touchlay/frontend-base'

export const EditItemRichText = asListItem(({ info, label, value, setData, extra: _extra, placeholder = '' }) => {
  const extra = withDefault(_extra, {
    translated: false,
  })
  // create one function that works for both
  const lang = useLanguage()
  const tPD = usePDTranslation()
  const language = extra?.translated ? lang : false
  const updateLabel = language ? `${label}.${language}` : label

  const handleChange = (input) => setData({ [updateLabel]: input })

  return (
    <RichTextInput
      controls={[ 'title', 'bold', 'italic', 'link', 'clear' ]}
      defaultValue={tPD(value)}
      disabled={info?.disabled}
      label={placeholder}
      language={language}
      onChange={handleChange}
    />
  )
}, {
  fullWidth: true,
})

export default EditItemRichText
