import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  createElement,
  getDevices, createDevice, deleteDevice,
  getPresentations, createPresentation, deletePresentation,
} from '@touchlay/frontend-base/dist/actions'

import { useIsLoading, useModal } from '@touchlay/frontend-base/dist/utils'

import Grid from '@material-ui/core/Grid'
import Chip from '@material-ui/core/Chip'
import DashboardChangelog from '@touchlay/frontend-base/components/DashboardChangelog'
import DashboardPlayerDownload from '@touchlay/frontend-base/components/DashboardPlayerDownload'
import DashboardSection from '@touchlay/frontend-base/components/DashboardSection'
import { editButton, deleteButton, dashboardButton } from '@touchlay/frontend-base/components/DashboardButtons'

import PageContent from './helpers/PageContent'
import PresentToAllIcon from '@material-ui/icons/PresentToAll'
import DevicesIcon from '@material-ui/icons/Devices'
import PlayArrow from '@material-ui/icons/PlayArrow'

import { DeviceStatus, PresentationInfo } from '@touchlay/frontend-base'

import defaultPD from '../../defaults/pd.json'
import generateDefaults from '../../defaults/index'
import changeLog from '../../changelog.json'
import { Permissions } from '@touchlay/frontend-base/dist/permissions'

const Dashboard = () => {
  const [ modalComponent, openModal ] = useModal()
  const dispatch = useDispatch()
  const history = useHistory()
  useEffect(() => {
    dispatch(getPresentations())
    dispatch(getDevices())
    const devicesInterval = setInterval(
      () => dispatch(getDevices())
      , 10000)
    return () => {
      clearInterval(devicesInterval)
    }
  }, [dispatch])

  const auth = useSelector((x) => x.auth)
  const devices = useSelector((x) => x.devices)
  const presentations = useSelector((x) => x.presentations?.filter(p => p.type === 'presentation'))
  const loadingDevices = useIsLoading('devices')
  const loadingPresentations = useIsLoading('presentations')

  // Define event handlers
  const handleCreatePresentation = () =>
    dispatch(createElement(defaultPD, 'presentation'))
      .then(({ type, result }) => {
        if (type === 'CREATE_ELEMENT_SUCCESS') {
          const { _id } = result
          return dispatch(createPresentation('New Presentation', generateDefaults(_id)))
            .then(({ type, result }) => {
              if (type === 'CREATE_PRESENTATION_SUCCESS') {
                history.push(`/presentation/${result._id}`)
              } else {
                alert(type)
              }
            })
        } else {
          alert(type)
        }
      })

  const handleDeletePresentation = (id) => dispatch(deletePresentation(id))
  const handleCreateDevice = () =>
    dispatch(createDevice('New Device', { meta: { type: 'device' } }))
      .then(({ type, result }) => {
        if (type === 'CREATE_DEVICE_SUCCESS') {
          history.push(`/device/${result._id}`)
        } else {
          alert(type)
        }
      })

  const handleDeleteDevice = (id) => dispatch(deleteDevice(id))

  // check if user has given permission for presentation
  const hasUserPermission = (presentation, permission) => {
    return Boolean(presentation?.users && presentation.users.find(user =>
      (user._id === auth?.user?._id) && user.roles.includes(permission))
    )
  }
  const canOpenEditor = (presentation) =>
    hasUserPermission(presentation, Permissions.WRITE) || hasUserPermission(presentation, Permissions.WRITE_USER)

  return (
    <PageContent>
      {modalComponent}
      <DashboardSection
        actions={[
          dashboardButton(() => ({
            onClick: (id) => window.open(`/view/${id}`, '_blank'),
            icon: (<PlayArrow />),
          })),
          editButton('presentation', 'Data', (presentation) => canOpenEditor(presentation)),
          deleteButton(handleDeletePresentation, {
            confirm: (title, desc, cb) => {
              openModal('confirm', {
                title: title,
                desc: desc,
                accept: cb,
                severity: 'warning',
              })
            },
            kind: 'presentation',
            show: (presentation) => hasUserPermission(presentation, 'OWNER'),
          }),
        ]}
        bottomInfo={PresentationInfo}
        icon={<PresentToAllIcon />}
        items={presentations}
        loading={loadingPresentations}
        onCreate={handleCreatePresentation}
        title={'Presentations'}
        titleSelector={'name'}
      />
      <DashboardSection
        actions={[
          editButton('device'),
          deleteButton(handleDeleteDevice, {
            confirm: (title, desc, cb) => {
              openModal('confirm', {
                title: title,
                desc: desc,
                accept: cb,
                severity: 'warning',
              })
            },
            kind: 'device',
          }),
        ]}
        bottomInfo={DeviceStatus}
        icon={<DevicesIcon />}
        items={
          devices
            .filter(device => device.meta && device.meta.type === 'device')
            .sort((a, b) =>
              (new Date(b.lastPing).getTime() || 0) - (new Date(a.lastPing).getTime() || 0))
        }
        loading={loadingDevices}
        onCreate={handleCreateDevice}
        subTitleSelector={item => {
          const assignedPresentation = item.presentation &&
            presentations?.find(p => item.presentation === p._id)
          const tag = assignedPresentation?.meta?.tag
            ? <Chip label={assignedPresentation.meta.tag} size='small' style={{ marginLeft: '0.2em' }} />
            : ''
          return assignedPresentation
            ? (
                <span>Assigned To: <b>{assignedPresentation.name}</b> {tag}</span>
              )
            : 'Not assigned to any presentation.'
        }}
        title={'Devices'}
        titleSelector={'name'}
      />
      <Grid container spacing={6}>
        <Grid item sm={6} xs={12}>
          {<DashboardPlayerDownload />}
        </Grid>
        <Grid item sm={6} xs={12}>
          {changeLog && <DashboardChangelog data={changeLog} />}
        </Grid>
      </Grid>
    </PageContent>
  )
}

export default Dashboard
