"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateStyle = exports.getStyles = exports.getStyle = exports.deleteStyle = exports.createStyle = exports.UPDATE_STYLE_SUCCESS = exports.UPDATE_STYLE_REQUEST = exports.UPDATE_STYLE_FAILURE = exports.STYLE_SUCCESS = exports.STYLE_REQUEST = exports.STYLE_FAILURE = exports.STYLES_SUCCESS = exports.STYLES_REQUEST = exports.STYLES_FAILURE = exports.DELETE_STYLE_SUCCESS = exports.DELETE_STYLE_REQUEST = exports.DELETE_STYLE_FAILURE = exports.CREATE_STYLE_SUCCESS = exports.CREATE_STYLE_REQUEST = exports.CREATE_STYLE_FAILURE = void 0;

var _utils = require("./utils");

var STYLES_REQUEST = 'STYLES_REQUEST';
exports.STYLES_REQUEST = STYLES_REQUEST;
var STYLES_SUCCESS = 'STYLES_SUCCESS';
exports.STYLES_SUCCESS = STYLES_SUCCESS;
var STYLES_FAILURE = 'STYLES_FAILURE';
exports.STYLES_FAILURE = STYLES_FAILURE;

var getStyles = function getStyles() {
  return (0, _utils.thunkCreator)(function (state) {
    return {
      types: [STYLES_REQUEST, STYLES_SUCCESS, STYLES_FAILURE],
      promise: function promise(token) {
        return fetch("".concat(process.env.REACT_APP_API_URL, "/styles"), (0, _utils.tokenHeader)(token));
      }
    };
  });
};

exports.getStyles = getStyles;
var STYLE_REQUEST = 'STYLE_REQUEST';
exports.STYLE_REQUEST = STYLE_REQUEST;
var STYLE_SUCCESS = 'STYLE_SUCCESS';
exports.STYLE_SUCCESS = STYLE_SUCCESS;
var STYLE_FAILURE = 'STYLE_FAILURE';
exports.STYLE_FAILURE = STYLE_FAILURE;

var getStyle = function getStyle(styleId) {
  return (0, _utils.thunkCreator)(function (state) {
    return {
      types: [STYLE_REQUEST, STYLE_SUCCESS, STYLE_FAILURE],
      promise: function promise(token) {
        return fetch("".concat(process.env.REACT_APP_API_URL, "/styles/") + styleId, (0, _utils.tokenHeader)(token));
      }
    };
  });
};

exports.getStyle = getStyle;
var CREATE_STYLE_REQUEST = 'CREATE_STYLE_REQUEST';
exports.CREATE_STYLE_REQUEST = CREATE_STYLE_REQUEST;
var CREATE_STYLE_SUCCESS = 'CREATE_STYLE_SUCCESS';
exports.CREATE_STYLE_SUCCESS = CREATE_STYLE_SUCCESS;
var CREATE_STYLE_FAILURE = 'CREATE_STYLE_FAILURE';
exports.CREATE_STYLE_FAILURE = CREATE_STYLE_FAILURE;

var createStyle = function createStyle(name, data) {
  return (0, _utils.thunkCreator)(function (state) {
    return {
      types: [CREATE_STYLE_REQUEST, CREATE_STYLE_SUCCESS, CREATE_STYLE_FAILURE],
      promise: function promise(token) {
        return fetch("".concat(process.env.REACT_APP_API_URL, "/styles"), Object.assign({
          method: 'POST',
          body: JSON.stringify({
            name: name,
            data: data
          })
        }, (0, _utils.tokenHeader)(token)));
      }
    };
  });
};

exports.createStyle = createStyle;
var DELETE_STYLE_REQUEST = 'DELETE_STYLE_REQUEST';
exports.DELETE_STYLE_REQUEST = DELETE_STYLE_REQUEST;
var DELETE_STYLE_SUCCESS = 'DELETE_STYLE_SUCCESS';
exports.DELETE_STYLE_SUCCESS = DELETE_STYLE_SUCCESS;
var DELETE_STYLE_FAILURE = 'DELETE_STYLE_FAILURE';
exports.DELETE_STYLE_FAILURE = DELETE_STYLE_FAILURE;

var deleteStyle = function deleteStyle(styleId) {
  return (0, _utils.thunkCreator)(function (state) {
    return {
      types: [DELETE_STYLE_REQUEST, DELETE_STYLE_SUCCESS, DELETE_STYLE_FAILURE],
      promise: function promise(token) {
        return fetch("".concat(process.env.REACT_APP_API_URL, "/styles/") + styleId, Object.assign({
          method: 'DELETE'
        }, (0, _utils.tokenHeader)(token)));
      }
    };
  });
};

exports.deleteStyle = deleteStyle;
var UPDATE_STYLE_REQUEST = 'UPDATE_STYLE_REQUEST';
exports.UPDATE_STYLE_REQUEST = UPDATE_STYLE_REQUEST;
var UPDATE_STYLE_SUCCESS = 'UPDATE_STYLE_SUCCESS';
exports.UPDATE_STYLE_SUCCESS = UPDATE_STYLE_SUCCESS;
var UPDATE_STYLE_FAILURE = 'UPDATE_STYLE_FAILURE';
exports.UPDATE_STYLE_FAILURE = UPDATE_STYLE_FAILURE;

var updateStyle = function updateStyle(styleId, change) {
  return (0, _utils.thunkCreator)(function (state) {
    return {
      types: [UPDATE_STYLE_REQUEST, UPDATE_STYLE_SUCCESS, UPDATE_STYLE_FAILURE],
      promise: function promise(token) {
        return fetch("".concat(process.env.REACT_APP_API_URL, "/styles/") + styleId, Object.assign({
          method: 'POST',
          body: JSON.stringify(change)
        }, (0, _utils.tokenHeader)(token)));
      }
    };
  });
};

exports.updateStyle = updateStyle;