"use strict";

function logException(ex, context) {
  // $FlowFixMe
  if (window.Sentry && typeof window.Sentry.captureException === 'function') {
    if (context) {
      window.Sentry.captureException(ex, {
        extra: context
      });
    } else {
      window.Sentry.captureException(ex);
    }
  }

  if (console && typeof console.error === 'function') {
    if (context && context.message) {
      console.error(String(context.message) + ':', ex);
    } else {
      console.error(ex);
    }
  }
}

module.exports = {
  logException: logException
};