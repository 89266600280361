
import { getSizedUrl } from '@touchlay/utils/src/customData'

import EditItemStatic from './EditItemStatic'
import EditItemSwitch from './EditItemSwitch'
import EditItemColor from './EditItemColor'
import EditItemRichText from './EditItemRichText'
import EditItemType from './EditItemType'
import EditItemSlider from './EditItemSlider'
import { EditItemAutocompleteId } from './EditItemAutocomplete'
import EditItemSelect from './EditItemSelect'
import EditItemStyleClass from './EditItemStyleClass'
import EditItemAnswers from './EditItemAnswers'
import EditItemPoint, { getImageParent } from './EditItemPoint'
import EditItemTree from './EditItemTree'
import EditItemURL from './EditItemURL'
import EditItemUpload, { UploadPreviewIframe, UploadPreviewImage, UploadPreviewMedia, UploadPreviewVideo } from './EditItemUpload'
import EditItemInput from './EditItemInput'
import EditItemId from './EditItemID'
import EditItemTitle from './EditItemTitle'
import { EditItemUploadList, EditItemContentUpload } from './EditItemUploadList'
import { lastItemsEqual } from './utils'

/*
  Properties are defined as:
  {
    check?: ({ parentNode, node }) => boolean,
    info?: FieldInfo | ({ parentNode, node }) => FieldInfo,
    extra?: any,
    field: null | InputComponent | (props) => InputComponent,
  }

  FieldInfo is defined as:
  {
    name?: string,
    helpText?: string,
    disabled?: ({ parentNode, node }) => boolean | boolean,
    defaultValue?: ({ parentNode, node}) => any | any,
  }

  extra is used to pass settings to the field should be used as minimally as
  possible - and always on a declarative away
  disabled is used for components which *should* be shown, yet clearly disabled
  e.g. a locked switch

  InputComponent is a React Component which gets passed and does the heavy work

  - check is called to see if the node matches
  - if it matches, fieldinfo are used to assign the labels/component
    - the fieldinfo is passed along to the Component

  on helpers a high order component is defined that wraps the most generic case
  and guarantees a style
  special cases (e.g. previews) might require extra work other than the generic
  default
  if `field` is null, the whole property is ignored
  (note: check must succeedfor this to be checked)

  the split between info/field lets us handle most of the defining from `properties`
  without getting into custom components for *each* thing
*/

// helpers for Variant property
const formatHeaderValue = val => {
  if (val === 1) return 'h3'
  else if (val === 3) return 'h1'
  return 'h2'
}

const headerToValue = variant => {
  if (variant === 'h3') return 1
  else if (variant === 'h1') return 3
  return 2
}

// see where this is actually needed
const uploadExtractValue = (result, { node }) => {
  // postfix with -raw so that the client will download the full image
  if (node.view === 'ImageView' || node.view === 'MarkerView') {
    return getSizedUrl(result?.url, 'raw')
  }
  return result?.url
}

// TODO: split properties into nicer objects

const pdfProperties = {
  src: {
    check: ({ node }) => (node.type === 'pdf' || node.subType === 'pdf'),
    field: EditItemUpload,
    extra: {
      type: 'pdf',
      preview: UploadPreviewIframe,
      extractValue: uploadExtractValue,
      translated: true,
    },
  },
}

const videoProperties = {
  autoplay: {
    field: EditItemSwitch,
  },
  muted: {
    field: EditItemSwitch,
  },
  loop: {
    field: EditItemSwitch,
  },
  src: {
    check: ({ node }) => (node.type === 'video' || node.subType === 'video'),
    field: EditItemUpload,
    extra: {
      type: 'video',
      preview: UploadPreviewVideo,
      extractValue: uploadExtractValue,
      translated: true,
    },
  },
}
const urlProperties = {
  src: {
    check: ({ node }) => (node.type === 'web' || node.subType === 'web'),
    info: {
      name: 'URL',
    },
    field: EditItemURL,
    extra: {
      translated: true,
    },
  },
}

const imageViewProperties = {
  point: {
    check: ({ parentNode }) => !!getImageParent(parentNode),
    field: EditItemPoint,
  },
}

const gridViewProperties = {
  size: {
    check: ({ node }) => node?.view === 'GridView',
    info: {
      name: 'Items per Row',
      helpText: 'Number of items per row in the grid',
      defaultValue: 3,
    },
    field: EditItemSlider,
    extra: {
      toValue: (v) => 12 / v,
      fromValue: (v) => 12 / v,
      formatValue: val => val,
      values: [
        { value: 1, label: '1' },
        { value: 2, label: '2' },
        { value: 3, label: '3' },
        { value: 4, label: '4' },
        { value: 6, label: '6' },
        { value: 12, label: '12' },
      ],
    },
  },

  point: {
    field: null, // we disable the point field outside of imageviews
  },
}

const genericProperties = {
  /*
    content: [] is always added to nodes to make PD consistent
    (children: [] in the tree structure)
    ... which means nodes that should not have access to it, get a tree view by default
  */
  children: {
    info: {
      name: 'Contents',
    },
    check: ({ node }) => (
      (node?.type === 'view' || node?.type === 'menu.item' || node?.children?.length > 0)
    ),
    field: EditItemTree,
  },
  /* TODO: remove this? - and leave popupMode: null
     we need this so we can still disable popup mode on existing views\
     this feature is still supported in the code, but working together with
     openAsPopup (both functionally and UX-wise) is a bit of a mess
  */
  popupMode: {
    check: ({ node }) => !!node?.popupMode,
    field: EditItemSwitch,
    info: {
      name: 'Items as Popup',
      helpText: 'All items will open in a popup instead of a new page.',
    },
  },
  openAsPopup: {
    field: EditItemSwitch,
    check: ({ parentNode }) => parentNode?.view === 'GridView' || parentNode?.view === 'ScatterView',
    info: {
      name: 'Open as Popup',
      helpText: 'This menu item will open as a popup instead of a new page.',
    },
  },
  styleClass: {
    field: EditItemStyleClass,
  },
}

const imageProperties = {
  media: {
    field: EditItemUpload,
    extra: {
      type: 'media',
      preview: UploadPreviewMedia,
      extractValue: uploadExtractValue,
    },
  },
  image: {
    field: EditItemUpload,
    extra: {
      preview: UploadPreviewImage,
      extractValue: uploadExtractValue,
    },
  },
  frontImageUrl: {
    field: EditItemUpload,
    extra: {
      preview: UploadPreviewImage,
      extractValue: uploadExtractValue,
    },
  },
  backgroundUrl: {
    field: EditItemUpload,
    extra: {
      preview: UploadPreviewImage,
      extractValue: uploadExtractValue,
    },
  },
  src: {
    /*
      maybe check: for image types
    */
    field: EditItemUpload,
    extra: {
      preview: UploadPreviewImage,
      extractValue: uploadExtractValue,
      translated: true,
      thumbnailLabel: 'thumbnailSrc',
    },
  },

  thumbnailSrc: {
    // handled by src
    field: null,
  },
}

const delayMinTable = [
  [ 1000, 1, '1s' ],
  [ 2000, 2, '2s' ],
  [ 5000, 4, '5s' ],
  [ 10000, 6, '10s' ],
]
const delayMaxTable = [
  [ 2000, 1, '2s' ],
  [ 5000, 2, '5s' ],
  [ 10000, 4, '10s' ],
  [ 20000, 6, '20s' ],
]

const getV = (t, k1, def) => (t.find((p) => p[0] === k1) ?? [])[1] ?? def
const getK = (t, k2, def) => (t.find((p) => p[1] === k2) ?? [])[0] ?? def

const scatterViewProperties = {
  children: {
    check: ({ node }) =>
      node.view === 'ScatterView',
    field: null,
  },
  width: {
    check: ({ node }) =>
      node.view === 'ScatterView',
    info: {
      name: 'Size',
      defaultValue: '300px',
    },
    field: EditItemSlider,
    extra: {
      toValue: (v) => Number(v.match(/^[0-9]*/)[0]),
      fromValue: (v) => `${v}px`,
      formatValue: val => val,
      values: [
        { value: 200, label: '200px' },
        { value: 300, label: '300px' },
        { value: 400, label: '400px' },
      ],
    },
  },
  min: {
    check: ({ node, path }) =>
      node.view === 'ScatterView' && lastItemsEqual(path, [ 'pinch', 'scaleBounds' ]),
    info: {
      name: 'Scale Min',
      defaultValue: 0.5,
    },
    field: EditItemSlider,
    extra: {
      toValue: (v) => v,
      fromValue: (v) => v,
      formatValue: val => val,
      values: [
        { value: 0.25, label: '0.25x' },
        { value: 0.5, label: '0.5x' },
        { value: 0.75, label: '0.75x' },
        { value: 1, label: '1x' },
      ],
    },
  },
  max: {
    check: ({ node, path }) =>
      node.view === 'ScatterView' && lastItemsEqual(path, [ 'pinch', 'scaleBounds' ]),
    info: {
      name: 'Scale Max',
      defaultValue: 0.5,
    },
    field: EditItemSlider,
    extra: {
      toValue: (v) => v,
      fromValue: (v) => v,
      formatValue: val => val,
      values: [
        { value: 1, label: '1x' },
        { value: 1.5, label: '1.5x' },
        { value: 2, label: '2x' },
        { value: 3, label: '3x' },
      ],
    },
  },
  smartAutoplay: {
    field: EditItemSwitch,
  },
  smartAutoplayOptions: {
    check: ({ node }) => !node?.options?.smartAutoplay,
    field: null,
  },
  playerCount: {
    info: {
      defaultValue: 1,
    },
    field: EditItemSlider,
    extra: {
      values: [
        { value: 1, label: '1' },
        { value: 2, label: '2' },
        { value: 3, label: '3' },
        { value: 4, label: '4' },
      ],
    },
  },
  delayMin: {
    info: {
      defaultValue: 2000,
    },
    field: EditItemSlider,
    extra: {
      toValue: (v) => getV(delayMinTable, v, 2),
      fromValue: (v) => getK(delayMinTable, v, 2000),
      values: delayMinTable.map(([ k, v, l ]) => ({
        label: l,
        value: v,
      })),
    },
  },
  delayMax: {
    info: {
      defaultValue: 5000,
    },
    field: EditItemSlider,
    extra: {
      toValue: (v) => getV(delayMaxTable, v, 2),
      fromValue: (v) => getK(delayMaxTable, v, 5000),
      values: delayMaxTable.map(([ k, v, l ]) => ({
        label: l,
        value: v,
      })),
    },
  },
  /*
    TODO?: implement a min/max unified slider that changes both properties (maybe)
           this would require implementing some extra checks on GenericEditView
           (scaleBounds is an object and not a translatable field, so it needs some care)
  scaleBounds: {
    check: ({ node }) =>
      node.view === 'ScatterView',
    field: EditItemStatic,
  },
  */
}

const scatterViewFieldNames = {
  defaultSpringProperties: {
    name: 'Scatter Item Properties',
  },
  gestureProperties: {
    name: 'Gesture Properties',
  },
  'pinch.scaleBounds': {
    name: null,
  },
}

const memoryViewProperties = {
  cards: {
    field: EditItemUploadList,
  },
  children: {
    check: ({ node }) =>
      node.view === 'MemoryView',
    field: null,
  },
}

const mediaProperties = {
  children: {
    check: ({ node }) =>
      node.type === 'group' && node.kind === 'media',
    field: EditItemContentUpload,
    extra: {
      type: 'media',
    },
  },
}

const galleryProperties = {
  children: {
    check: ({ node }) =>
      node.view === 'SlideShowView' || (node.type === 'group' && node.kind === 'image'),
    field: EditItemContentUpload,
  },
  kind: {
    field: null,
  },
  thumbnails: {
    field: EditItemSwitch,
  },
}

const ignoredProperties = {
  // if we're not handling children in a specific case, we should ignore it
  children: {
    field: null,
  },
  // this is to avoid showing two email headers as we handle it in EmailSection
  email: {
    field: null,
  },
  // we're already showing it on the type
  view: {
    field: null,
  },
  // internal properties
  _source: {
    field: null,
  },
  _sourceLink: {
    field: null,
  },
  _template: {
    field: null,
  },
  _modified: {
    field: null,
  },

  // these should be purpose handled by the respective handler
  min: {
    field: null,
  },
  max: {
    field: null,
  },

  /* if we're not on the right view, this property is meaningless */
  openAsPopup: {
    field: null,
  },
  popupMode: {
    field: null,
  },
}

const basicProperties = {
  id: {
    info: {
      helpText: 'Unique identifier, used in the URL path',
      disabled: ({ mode, node }) => (
        mode?.type === 'user' && node?._sourceLink
      ),
    },
    field: EditItemId,
  },
  title: {
    info: {
      helpText: 'Name of the element in the given language',
    },
    field: EditItemTitle,
  },
  subtitle: {
    field: EditItemInput,
    extra: {
      translated: true,
    },
  },
  type: {
    field: EditItemType,
  },

  subType: {
    // TODO: implement subtypes properly (select from none, web, image, video)
    field: EditItemStatic,
  },
  subViews: {
    info: {
      name: 'Layouts',
    },
    field: EditItemTree,
    extra: {
      noButtons: true,
    },
  },
  hideText: {
    info: {
      helpText: 'Hide title and description (only show image)',
    },
    field: EditItemSwitch,
  },
  hidden: {
    info: {
      helpText: 'Hide element in the presentation',
    },
    field: EditItemSwitch,
  },
  noMove: {
    info: {
      name: 'Disable Moving',
      helpText: 'Do not allow this node to be moved',
    },
    field: EditItemSwitch,
  },
  noDelete: {
    info: {
      name: 'Disable Deletion',
      helpText: 'Do not allow this node to be deleted',
    },
    field: EditItemSwitch,
  },
  locked: {
    info: {
      name: 'Lock Element',
      helpText: 'Do not allow changes to be made',
    },
    field: EditItemSwitch,
  },

}
const extraProperties = {
  answers: {
    field: EditItemAnswers,
  },
  lineHeight: {
    info: {
      helpText: 'Height taken by the component',
      defaultValue: 0,
    },
    field: EditItemSlider,
    extra: {
      toValue: val => val,
      fromValue: val => val,
      values: [
        ...([ 0, 1, 2, 3, 4, 5 ].map(v => ({
          label: '' + v, value: v,
        }))),
        { label: 'max', value: 6 },
      ],
    },
  },
  description: {
    field: EditItemInput,
    extra: {
      multiline: true,
      maxRows: 4,
      translated: true,
      maxLength: 1024,
    },
  },

  text: { // richtext and markdown
    field: EditItemRichText,
    extra: {
      translated: true,
    },
  },

  // [old comment]
  // TODO: make those into number fields
  contentWidth: {
    field: EditItemStatic,
  },
  contentHeight: {
    field: EditItemStatic,
  },

  alignItems: {
    field: EditItemSelect,
    extra: {
      values: [
        { label: 'Top', value: 'flex-start' },
        { label: 'Center', value: 'center' },
        { label: 'Bottom', value: 'flex-end' },
      ],
    },
  },

  meta: {
    name: 'Meta Information',
    field: EditItemInput,
  },

  variant: {
    info: {
      helpText: 'Variant of the text style',
      defaultValue: 'h3',
    },
    field: EditItemSlider,
    extra: {
      toValue: headerToValue,
      fromValue: formatHeaderValue,
      values: [
        { label: 'small', value: 1 },
        { label: 'medium', value: 2 },
        { label: 'large', value: 3 },
      ],
    },
  },

  linkToId: {
    info: {
      name: 'Link to Page',
      helpText: 'Add a link to another page in the presentation by specifying its Id',
    },
    field: EditItemAutocompleteId,
  },
  size: { // mostly for menu items
    info: {
      name: 'Full Width',
      helpText: 'Make this item span across the full width',
      defaultValue: ({ node }) => node?.type === 'menu.header' ? 12 : false,
      disabled: ({ node, parentNode }) => node?.type === 'menu.header' || parentNode?.view !== 'GridView',
    },
    field: EditItemSwitch,
    extra: {
      toValue: (v) => v === 12,
      fromValue: (v) => v ? 12 : false,
    },
  },

  backgroundImage: {
    // check: ({ node }) =>
    //  node.backgroundMedia === undefined,
    info: {
      helpText: 'Use the image as background',
    },
    field: EditItemSwitch,
  },
  backgroundMedia: {
    // check: ({ node }) =>
    //  node.backgroundImage === undefined || node.backgroundImage === false || node.backgroundImage === node.backgroundMedia,
    info: {
      helpText: 'Use media as background',
    },
    field: EditItemSwitch,
  },
  color: {
    field: EditItemColor,
  },
  backgroundColor: {
    field: EditItemColor,
  },
}
export const properties = [
  mediaProperties,
  galleryProperties,
  pdfProperties,
  videoProperties,
  urlProperties,
  scatterViewProperties,
  imageViewProperties,
  gridViewProperties, // must go after imageViewProperties
  imageProperties, // must go after pdf/video/url, provides default
  memoryViewProperties,
  genericProperties,
  basicProperties, // this is in every component
  extraProperties,
  ignoredProperties,
]

export const defaultProperty = {
  field: EditItemInput,
}

export const fieldNames = [
  scatterViewFieldNames,
]
