"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useExtensionLight = exports.useExtension = exports.unregister = exports.register = void 0;

var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = require("react");

/*

  This file allows things to hook up into the extension initialization, to connect config/action/etc

*/
if (!global.__WAYFINDER_CONNECT_IX__) {
  global.__WAYFINDER_CONNECT_IX__ = 0;
}

if (!global.__WAYFINDER_CONNECT__INT__) {
  global.__WAYFINDER_CONNECT__INT__ = {};
}

if (!global.__WAYFINDER_CONNECT__) {
  global.__WAYFINDER_CONNECT__ = function () {
    for (var property in global.__WAYFINDER_CONNECT__INT__) {
      var value = global.__WAYFINDER_CONNECT__INT__[property];

      if (typeof value === 'function') {
        value();
      }
    }
  };
}

var register = function register(cb) {
  var id = global.__WAYFINDER_CONNECT_IX__;
  global.__WAYFINDER_CONNECT_IX__++;
  global.__WAYFINDER_CONNECT__INT__[id] = cb;
  return id;
};

exports.register = register;

var unregister = function unregister(id) {
  delete global.__WAYFINDER_CONNECT__INT__[id];
};

exports.unregister = unregister;

var useExtension = function useExtension(cb) {
  var cbParams = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];

  var _useState = (0, _react.useState)(0),
      _useState2 = (0, _slicedToArray2["default"])(_useState, 2),
      update = _useState2[0],
      setUpdate = _useState2[1];

  (0, _react.useEffect)(function () {
    var idx = register(function () {
      setUpdate(function (x) {
        return x + 1;
      });
    });
    return function () {
      unregister(idx);
    };
  }, []);
  (0, _react.useEffect)(cb, [update].concat((0, _toConsumableArray2["default"])(cbParams)));
};

exports.useExtension = useExtension;

var useExtensionLight = function useExtensionLight(cb) {
  (0, _react.useEffect)(function () {
    var ret = null;
    var idx = register(function () {
      if (typeof ret === 'function') {
        ret();
      }

      ret = cb();
    });
    return function () {
      if (typeof ret === 'function') {
        ret();
      }

      unregister(idx);
    };
  }, []);
  (0, _react.useEffect)(cb, []);
};

exports.useExtensionLight = useExtensionLight;