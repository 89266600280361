"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _reactLeaflet = require("react-leaflet");

var IfLeafletNotAvailable = function IfLeafletNotAvailable(props) {
  var leaflet = (0, _reactLeaflet.useLeaflet)();

  if (leaflet && leaflet.map) {
    return null;
  } else {
    return props.children || null;
  }
};

var _default = IfLeafletNotAvailable;
exports["default"] = _default;