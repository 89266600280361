"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = positionStyle;

function positionStyle(position) {
  var radius = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  var style = {
    top: position === 'topleft' || position === 'topright' ? 0 : null,
    bottom: position === 'bottomleft' || position === 'bottomright' ? 0 : null,
    left: position === 'topleft' || position === 'bottomleft' ? 0 : null,
    right: position === 'topright' || position === 'bottomright' ? 0 : null
  };
  return radius ? Object.assign(style, {
    borderBottomRightRadius: position === 'topleft' ? 5 : 0,
    borderBottomLeftRadius: position === 'topright' ? 5 : 0,
    borderTopRightRadius: position === 'bottomleft' ? 5 : 0,
    borderTopLeftRadius: position === 'bottomright' ? 5 : 0
  }) : style;
}