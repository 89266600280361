import React from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { ConfigViewWrapper, TextEdit, DeleteButton } from '@touchlay/frontend-base'

import { useItem } from './helpers/utils'

import { getStyle, updateStyle, deleteStyle } from '@touchlay/frontend-base/dist/actions'

const Style = () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const style = useItem((x) => x.styles, id, getStyle)

  // Define event handlers
  const handleNameChange = (value) => dispatch(updateStyle(style._id, { name: value }))
  const handleDataChange = (value) => dispatch(updateStyle(style._id, { data: JSON.parse(value) }))

  return (
    <ConfigViewWrapper subTitle={'Style'} title={style && style.integration}>
      {/* edit name */}
      <TextEdit
        defaultValue={style && style.name}
        id={'stylename'}
        key={style && style.name}
        label={'Name'}
        onChange={handleNameChange}
      /><br />

      {/* edit data */}
      <TextEdit
        defaultValue={style && JSON.stringify(style.data, null, 4)}
        fullWidth
        id={'styledata'}
        key={style && JSON.stringify(style.data)}
        label={'Data'}
        multiline
        onChange={handleDataChange}
      />
      <DeleteButton action={(x) => dispatch(deleteStyle(x))} id={style && style._id} />
    </ConfigViewWrapper>
  )
}

export default Style
