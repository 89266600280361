import { DebouncedInput } from '@touchlay/frontend-base'
import { useLanguage, usePDTranslation } from '@touchlay/utils'
import * as React from 'react'
import { ListEditItem, withDefault } from './utils'

// TODO: properly implement image previews
const imagePreviewStyle = { maxWidth: '100%', padding: '0 16px', border: 0 }

/*
  // TODO: implement type overrides for fields
  if (label === 'src' && (node.type === 'web' || node.subType === 'web')) {
    // for web components this should be a simple input field, not an upload
    inputField = EditItemURL
    inputProps = {}
    info = { ...info }
    info.previewImage = false
    info.previewIframe = true
    info.name = 'URL'
  }
*/

const EditItemURL = ({ info, label, extra: _extra, value, setData }) => {
  const extra = withDefault(_extra, {
    translated: false,
  })
  const lang = useLanguage()
  const tPD = usePDTranslation()
  const language = extra?.translated ? lang : false

  const updateLabel = language ? `${label}.${language}` : label

  const handleChange = (input) => {
    const formattedInput =
      (input.length <= 0 || input.startsWith('http://') || input.startsWith('https://'))
        ? input
        : 'https://' + input

    setData({
      [updateLabel]: formattedInput,
    })
  }

  return (
    <>
      <ListEditItem info={info}>
        <DebouncedInput
          disabled={info?.disabled}
          onChange={handleChange}
          value={tPD(value)}
        />
      </ListEditItem>
      <iframe src={tPD(value)} style={imagePreviewStyle} title={label} />
    </>
  )
}

export default EditItemURL
