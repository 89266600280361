"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = errorReducer;

var _actions = require("../actions");

function errorReducer() {
  var _action$error;

  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case _actions.AUTH_SUCCESS:
    case _actions.DISMISS_ERROR:
      return false;
    // user didn't trigger these actions, so it's irrelevant to them to see they failed

    case _actions.UPLOAD_DEVICE_STATS_FAILURE:
    case _actions.UPLOAD_PRESENTATION_STATS_FAILURE:
      return false;

    case _actions.SOURCE_FAILURE:
      // FIXME: this is a quick workaround to prevent the not found errors caused by requesting sources of other users
      if (((_action$error = action.error) === null || _action$error === void 0 ? void 0 : _action$error.error) === 'source not found') return false;
      return action.error;

    case _actions.USER_BY_EMAIL_FAILURE:
      {
        var _action$error2, _action$error2$error;

        var matchError = /User with email '(.*)' not found./;
        if ((_action$error2 = action.error) !== null && _action$error2 !== void 0 && (_action$error2$error = _action$error2.error) !== null && _action$error2$error !== void 0 && _action$error2$error.match(matchError)) return false;
        return action.error;
      }

    default:
      if (action.type.endsWith('_ERROR') || action.type.endsWith('_FAILURE')) {
        return action.error;
      }

      return state;
  }
}