import * as React from 'react'

import List from '@material-ui/core/List'
import ListSubheader from '@material-ui/core/ListSubheader'
import Button from '@material-ui/core/Button'
import NodePicker from './NodePicker'
import {
  addNodeAtRoot, addNode,
  generateID,
  getPossibleChildren,
} from './treeUtils'

import { useHistory } from 'react-router-dom'

const AddNode = ({ treeData, id: parentKey, node, setData, topLevel, presentation }) => {
  const history = useHistory()
  const goBack = () => {
    history.goBack()
  }

  // hack to reuse the logic, if there's no node, we're on the top most thing

  const parent = parentKey === undefined ? topLevel.node : node

  const createNode = (newNode) => () => {
    const type = newNode.type === 'md' ? 'text' : newNode.type
    newNode.id = generateID(newNode.title || newNode.view || type, treeData)

    const treeNew = (parentKey && parentKey.length > 0)
      ? addNode(treeData, parentKey, newNode)
      : addNodeAtRoot(treeData, newNode)
    setData(treeNew.treeData)
    goBack()
  }
  let possible = parent ? getPossibleChildren(parent, { presentation }) : []

  if (parent) {
    const isValidItem = parent?.type === 'menu.item' || parent?.type === 'view' || parent?.type === 'direct'
    const alreadyHasView = parent && parent.type === 'menu.item' && parent.children && parent.children.length > 0
    const fullscreenAlreadyHasContents = parent && parent.type === 'view' && parent.view === 'FullscreenView' && parent.children && parent.children.length > 0
    if (!isValidItem || alreadyHasView || fullscreenAlreadyHasContents) {
      possible = []
    }
  }

  if (possible.length === 0) {
    return (
      <List>
        <ListSubheader color='primary'>Create New...</ListSubheader>
        <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: '0.5em' }}>
          <Button color='inherit' onClick={goBack} variant='outlined'>Cancel & Go Back</Button>
        </div>
        <ListSubheader color='primary'>{"Can't create element here"}</ListSubheader>
     </List>
    )
  }
  return (
    <NodePicker
      cancel={goBack}
      handleCreate={createNode}
      nodes={possible}
    />
  )
}

export default AddNode
