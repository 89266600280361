"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _styles = require("@material-ui/styles");

var _spacings = require("../spacings");

var _default = (0, _styles.makeStyles)(function (theme) {
  return {
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#F5F5F5',
      height: '100%',
      color: '#111111',
      '& h1, h2, h3, h4, h5, h6': {
        color: '#111111'
      }
    },
    typography: {
      fontFamily: '"Rubik", sans-serif',
      fontWeight: 100
    },
    innerContainer: {
      padding: _spacings.fib7
    },
    block: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '0.5em'
    },
    list: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      listStyleType: 'none',
      width: '100%',
      paddingInlineStart: 0,
      marginBlockStart: '1em',
      marginBlockEnd: 0
    },
    logo: {
      height: '48px',
      marginTop: '9px'
    },
    icon: {
      width: '2em',
      height: '2em',
      marginRight: _spacings.fib5
    }
  };
});

exports["default"] = _default;