"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.DEFAULT_SCALE = exports.DEFAULT_BUBBLE_TIMEOUT = void 0;
// TODO: either have a central constants file or get rid of this
var DEFAULT_BUBBLE_TIMEOUT = 10000;
exports.DEFAULT_BUBBLE_TIMEOUT = DEFAULT_BUBBLE_TIMEOUT;
var DEFAULT_SCALE = {
  "default": -0.5,
  min: -1,
  max: 1
};
exports.DEFAULT_SCALE = DEFAULT_SCALE;
var _default = {
  DEFAULT_SCALE: DEFAULT_SCALE,
  DEFAULT_BUBBLE_TIMEOUT: DEFAULT_BUBBLE_TIMEOUT
};
exports["default"] = _default;