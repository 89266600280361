import React, { useMemo } from 'react'
import { Editor, HelpEditor, MediaLibraryIcon, useMediaLibrary } from '@touchlay/frontend-base'

import { UndoContext } from './data/UndoContext'

import SettingsEditor from './settings/'
import DataEditor from './data/'
import StatsEditor from './stats/'
import PublishEditor from './publish/'
import StyleEditor from './style/'

import SettingsIcon from '@material-ui/icons/Settings'
import StorageIcon from '@material-ui/icons/Storage'
import StyleIcon from '@material-ui/icons/Style'
import TrendingUpIcon from '@material-ui/icons/TrendingUp'
import PublicIcon from '@material-ui/icons/Public'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'

const faq = [
  {
    title: 'How long does it take for my devices to update?',
    content: 'If your devices are online, they will check for updates in a 2 minute interval.',
  },
  {
    title: 'Can I have a custom design/style?',
    content: 'Certain aspects of the design can be adjusted in the Style sidebar. If you need a more customized design, feel free to contact us.',
  },
  {
    title: 'Is it possible to add a special element/layout?',
    content: 'If you have a special use-case, just contact us. We are always open to adding new functionality, elements and layouts to our platform!',
  },
  {
    title: 'Can I change the default/fallback language?',
    content: 'The default language will always be set to the language of the user. The fallback language can be changed from the Settings sidebar.',
  },
  {
    title: 'Something does not work, what can I do?',
    content: 'Send us an e-mail with detailed information about what you tried to do and what happened, and we will get back to you as soon as possible!',
  },
]

const NoneEditor = () => null

export default function PresentationEditor () {
  const openML = useMediaLibrary()
  const openMediaLibrary = () => {
    openML({
      mode: 'view',
    })
  }
  const pages = useMemo(() => [
    { name: 'Settings', editor: SettingsEditor, icon: SettingsIcon },
    { name: 'Style', beta: true, editor: StyleEditor, icon: StyleIcon },
    { name: 'Data', editor: DataEditor, icon: StorageIcon, expandable: true },
    { name: 'Publish', editor: PublishEditor, icon: PublicIcon },
    { name: 'Statistics', beta: true, editor: StatsEditor, icon: TrendingUpIcon, expandable: true },
    { filler: true },
    { name: 'Media Library', beta: true, editor: NoneEditor, icon: MediaLibraryIcon, handler: openMediaLibrary },
    { name: 'Help', editor: HelpEditor, icon: HelpOutlineIcon, faq },
  ], [])

  return (
    <UndoContext>
      <Editor basePath='/presentation' pages={pages} />
    </UndoContext>
  )
}
