"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

function parser(d, str, debug) {
  if (d.length === 0) {
    // we finished
    if (debug) console.log('[parser] finished');
    return str;
  }

  var _d$shift = d.shift(),
      _d$shift2 = (0, _slicedToArray2["default"])(_d$shift, 7),
      op = _d$shift2[0],
      x1 = _d$shift2[1],
      y1 = _d$shift2[2],
      x2 = _d$shift2[3],
      y2 = _d$shift2[4],
      x = _d$shift2[5],
      y = _d$shift2[6];

  switch (op) {
    // operations with two arguments
    case 'M':
    case 'm':
    case 'L':
    case 'l':
      return parser(d, str + op + ' ' + x1 + ',' + y1 + ' ', debug);
    // operations with six arguments

    case 'C':
    case 'c':
      return parser(d, str + op + ' ' + x1 + ' ' + y1 + ', ' + x2 + ' ' + y2 + ', ' + x + ' ' + y + ' ');
    // end operation (no arguments)

    case 'Z':
      return parser(d, str + op, debug);
    // unknown operation

    default:
      throw new Error('unknown operation ' + op);
  }
}

function svgPathToString(scannedPath, debug) {
  var str = parser(scannedPath.slice(), '', debug);
  if (debug) console.log('[main] result', str);
  return str;
}

module.exports = svgPathToString;