import * as React from 'react'

import { DebouncedInput } from '@touchlay/frontend-base'

import { useLanguage, usePDTranslation } from '@touchlay/utils'
import { asListItem, withDefault } from './utils'

import { generateID } from '../treeUtils'

export const EditItemTitle = asListItem(({ info, label, value, setData, node, treeData, extra: _extra, ...props }) => {
  const extra = withDefault(_extra, {
    maxLength: 128,
  })
  const lang = useLanguage()
  const tPD = usePDTranslation()
  const updateLabel = `${label}.${lang}`
  const rValue = tPD(value)
  const handleChange = (rawInput) => {
    const input = rawInput.trim()
    let obj = {
      [updateLabel]: input,
    }
    if (lang === 'en' && input && input.length > 0) { // TODO: use default language here
      const oldID = generateID(tPD(node.title ?? node.id, true)).replace(/-[0-9]+$/, '', [], {
        userNode: node?._source?.kind === 'user',
      })
      if ((tPD(value ?? node.id).length <= 0) || oldID === node.id.replace(/-[0-9]+$/, '')) {
        // if the old title would have resulted in the same ID, update the ID
        // -> this is used to check that the ID was not changed manually yet
        obj = {
          ...obj,
          id: input,
        }
      }
    }
    setData(obj)
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <DebouncedInput
        autoFocus
        disabled={info?.disabled}
        maxLength={extra.maxLength}
        onChange={handleChange}
        value={rValue}
      />
    </div>
  )
})

export default EditItemTitle
