"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var React = _interopRequireWildcard(require("react"));

var _reactRouter = require("react-router");

var _utils = require("@touchlay/utils");

var _helpers = require("./helpers");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

var trimPrefix = function trimPrefix(str, prefix) {
  var v = str.startsWith(prefix) ? str.slice(prefix.length) : str;
  return v || '/';
};

var find = function find(_content, id, currentPath) {
  var ret = null;

  var go = function go(cnt, id, route) {
    React.Children.forEach(cnt, function (ch) {
      if (!ret) {
        var _ch$props, _ch$props2, _ch$props4, _ch$props5, _ch$props8, _ch$props9;

        var newRoute = route;

        if (ch !== null && ch !== void 0 && (_ch$props = ch.props) !== null && _ch$props !== void 0 && _ch$props.id && (ch === null || ch === void 0 ? void 0 : (_ch$props2 = ch.props) === null || _ch$props2 === void 0 ? void 0 : _ch$props2.pdType) === 'menu.item') {
          var _ch$props3;

          newRoute = "".concat(route, "/").concat(ch === null || ch === void 0 ? void 0 : (_ch$props3 = ch.props) === null || _ch$props3 === void 0 ? void 0 : _ch$props3.id);
        }

        if ((ch === null || ch === void 0 ? void 0 : (_ch$props4 = ch.props) === null || _ch$props4 === void 0 ? void 0 : _ch$props4.type) === 'view' && (ch === null || ch === void 0 ? void 0 : (_ch$props5 = ch.props) === null || _ch$props5 === void 0 ? void 0 : _ch$props5.name) === 'ToggleView') {
          var _ch$props6, _subViews$, _subViews$$layout;

          var subViews = ch === null || ch === void 0 ? void 0 : (_ch$props6 = ch.props) === null || _ch$props6 === void 0 ? void 0 : _ch$props6.subViews;
          var viewIdx = Array.isArray(subViews) && subViews.findIndex(function (x) {
            var _x$layout;

            return (x === null || x === void 0 ? void 0 : (_x$layout = x.layout) === null || _x$layout === void 0 ? void 0 : _x$layout.id) === id;
          });

          if (viewIdx >= 0) {
            ret = "".concat(route, "/").concat(subViews[viewIdx].layout.id);
            return;
          }
          /*
            heuristic/hack:
            - we need to "find" the current view, for that we:
            - produce the current route
            - get the new route we're generating
            - IF the route is similar (iow: the new route matches the start of the current path) we assume it is shared, so we pass it along
              otherwise we'll default to the first view
            because of the code *before* this - we don't need to worry of the case where the route specified *is* the toggleview
            TODO/FIXME:
              check if it's somehow possible to get into an invalid state (invalid view)
              which would trigger the failsafe of ToggleView and thus break the routing
              doesn't seem possible, and the extra check could be hiding somewhat important bugs
          */


          var currentPathS = currentPath.split('/').filter(function (x) {
            return x !== '';
          });
          var routeSoFar = newRoute.split('/').filter(function (x) {
            return x !== '';
          });
          var currentView = currentPathS[routeSoFar.length];

          for (var ix = 0; ix < routeSoFar.length; ix++) {
            if (routeSoFar[ix] !== currentPathS[ix]) {
              currentView = null;
            }
          }

          if (currentView) {
            newRoute = "".concat(route, "/").concat(currentView);
          } else if (subViews && subViews[0] && (_subViews$ = subViews[0]) !== null && _subViews$ !== void 0 && (_subViews$$layout = _subViews$.layout) !== null && _subViews$$layout !== void 0 && _subViews$$layout.id) {
            var _ch$props7;

            newRoute = "".concat(route, "/").concat(ch === null || ch === void 0 ? void 0 : (_ch$props7 = ch.props) === null || _ch$props7 === void 0 ? void 0 : _ch$props7.subViews[0].layout.id);
          }
        }

        if ((ch === null || ch === void 0 ? void 0 : (_ch$props8 = ch.props) === null || _ch$props8 === void 0 ? void 0 : _ch$props8.id) === id) {
          ret = newRoute;
        } else if (ch !== null && ch !== void 0 && (_ch$props9 = ch.props) !== null && _ch$props9 !== void 0 && _ch$props9.content) {
          var _ch$props10;

          go(ch === null || ch === void 0 ? void 0 : (_ch$props10 = ch.props) === null || _ch$props10 === void 0 ? void 0 : _ch$props10.content, id, newRoute);
        }
      }
    });
  };

  go(_content, id, '/');
  return ret;
}; // TODO: this function might be better implemented with something like useConfigRef


var RouterCtx = function RouterCtx(_ref) {
  var content = _ref.content,
      children = _ref.children,
      idleRoute = _ref.idleRoute;
  var contentRef = (0, React.useRef)(content);
  contentRef.current = content;
  var prefixPath = (0, _utils.useConfig)('router.prefixPath', '');
  (0, _utils.useConfig)('router.canGoBack', false);
  var history = (0, _reactRouter.useHistory)();
  var defaultPath = history.location.pathname;

  if (prefixPath) {
    defaultPath = trimPrefix(defaultPath, prefixPath);
  }

  (0, _utils.useConfig)('routing.path', defaultPath);
  (0, _utils.useConfig)('routing.hash', history.location.hash);
  var dispatch = (0, _utils.useActions)('#routing-system#');
  (0, React.useEffect)(function () {
    return history.listen(function (location, action) {
      var path = location.pathname;

      if (prefixPath) {
        path = trimPrefix(path, prefixPath);
      }

      dispatch({
        type: 'config',
        key: 'routing.path',
        value: path
      });
      dispatch({
        type: 'config',
        key: 'routing.hash',
        value: location.hash
      });
      dispatch({
        type: 'config',
        key: 'router.canGoBack',
        value: true
      });
    });
  }, [prefixPath]);
  (0, _utils.useActions)('#routing-system#', function (act) {
    if (act.type === 'action') {
      if (act.name === 'history.push') {
        var url = (prefixPath || '') + (act.url || '/');
        history.push({
          pathname: (0, _helpers.simplifyURL)(url),
          hash: act.hash || ''
        });
      }

      if (act.name === 'history.goto.id') {
        // esteban's debugger:
        // console.warn({ history })
        var path = history.location.pathname;

        if (prefixPath) {
          path = trimPrefix(path, prefixPath);
        }

        var _url = find(contentRef.current, act.id, (0, _helpers.simplifyURL)(path));

        if (_url) {
          history.push({
            pathname: (0, _helpers.simplifyURL)((prefixPath || '') + _url)
          });
        }
      }

      if (act.name === 'history.back') {
        history.goBack();
      }

      if (act.name === 'ui.idle') {
        var _url2 = (prefixPath || '') + (idleRoute || '/');

        history.push((0, _helpers.simplifyURL)(_url2));
      }
    }
  }, [prefixPath, idleRoute]);
  return children;
};

var _default = RouterCtx;
exports["default"] = _default;