"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useItem = exports.createAndLink = exports.convertResultToError = exports.checkLastPing = void 0;
exports.useQuery = useQuery;

var _react = require("react");

var _reactRedux = require("react-redux");

var _reactRouterDom = require("react-router-dom");

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

// Check if a timestamp is no longer than x secounds in the past
var checkLastPing = function checkLastPing(ping, seconds) {
  var date = new Date();
  date.setSeconds(date.getSeconds() - seconds);
  return date.getTime() <= new Date(ping).getTime();
};

exports.checkLastPing = checkLastPing;

var useItem = function useItem(selector, id, getItem) {
  var dispatch = (0, _reactRedux.useDispatch)();
  var items = (0, _reactRedux.useSelector)(selector);
  var item = items.find(function (it) {
    return it._id === id;
  });
  (0, _react.useEffect)(function () {
    dispatch(getItem(id));
  }, [dispatch, getItem, id]);
  return item;
}; // Create a new type, link to current Presentation and go to TypeConfigView for the new type


exports.useItem = useItem;

var createAndLink = function createAndLink(_ref) {
  var type = _ref.type,
      createType = _ref.createType,
      update = _ref.update,
      element = _ref.element,
      push = _ref.push;
  return createType(prompt('Enter name:')).then(function (_ref2) {
    var result = _ref2.result;
    update(element._id, _defineProperty({}, type, result._id));
    push("/".concat(type, "/") + result._id);
  });
};

exports.createAndLink = createAndLink;

function useQuery() {
  var location = (0, _reactRouterDom.useLocation)();

  if (location !== null && location !== void 0 && location.search) {
    return new URLSearchParams(location.search);
  } else {
    return {
      get: function get() {}
    };
  }
}

var convertResultToError = function convertResultToError(dispatchResult) {
  if (dispatchResult.type.endsWith('_FAILURE')) {
    var _dispatchResult$error;

    if (typeof ((_dispatchResult$error = dispatchResult.error) === null || _dispatchResult$error === void 0 ? void 0 : _dispatchResult$error.error) === 'string') {
      throw new Error(dispatchResult.error.error);
    }

    throw new Error(dispatchResult.error);
  }

  return dispatchResult;
};

exports.convertResultToError = convertResultToError;