import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getPresentationStats } from '@touchlay/frontend-base/dist/actions'
import LoadingList from '@touchlay/frontend-base/dist/components/LoadingList'

import List from '@material-ui/core/List'
import ListSubheader from '@material-ui/core/ListSubheader'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'

import Chart from './Chart'
import { processChunk, processClicks, sortValues } from './processing'

export default function StatsEditor ({ presentation }) {
  const dispatch = useDispatch()
  const presentationId = presentation?._id
  useEffect(() => {
    if (presentationId) {
      dispatch(getPresentationStats(presentationId))
    }
  }, [ dispatch, presentationId ])

  const stats = useSelector(x => x?.presentations?.find(p => p._id === presentationId)?.stats)

  if (!stats) {
    return <LoadingList name='statistics' />
  }

  const sessionCount = stats.length
  const sessionClicks = stats.map(processChunk).map(processClicks)
  const { clicks, views } = sessionClicks.reduce((acc, session) => {
    Object.keys(session).forEach(key => {
      acc.clicks[key] = (acc.clicks[key] || 0) + session[key]
      acc.views[key] = (acc.views[key] || 0) + 1
    })
    return acc
  }, { clicks: {}, views: {} })
  const [ sortedLabels, sortedClicks, sortedViews ] = sortValues(Object.keys(clicks), Object.values(clicks), Object.values(views))
  // const searchClicks = actions.filter(({ from }) => from === '#searchview#')

  return (
    <List>
      <ListSubheader color='primary' style={{ marginTop: 0 }}>Summary</ListSubheader>
      <ListItem>
        <ListItemText>
          <b>Total Sessions:</b> {sessionCount}
        </ListItemText>
      </ListItem>
      <ListSubheader color='primary'>Top 10</ListSubheader>
      <Chart
        config={{
          type: 'horizontalBar',
          data: {
            labels: sortedLabels.slice(0, 10),
            datasets: [
              {
                label: 'Clicks',
                backgroundColor: 'rgb(141, 211, 199)',
                data: sortedClicks,
              },
              {
                label: 'Views',
                backgroundColor: 'rgb(252, 205, 229)',
                data: sortedViews,
              },
            ],
          },
          options: {
            scales: {
              xAxes: [{
                ticks: {
                  beginAtZero: true,
                  precision: 0,
                },
              }],
            },
          },
        }}
      />
    </List>
  )
}
