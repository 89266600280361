"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

function parser(d, pointer, points, debug) {
  if (d.length === 0) {
    // we finished
    if (debug) console.log('[parser] finished');
    return points;
  }

  var _d$shift = d.shift(),
      _d$shift2 = (0, _slicedToArray2["default"])(_d$shift, 3),
      op = _d$shift2[0],
      x = _d$shift2[1],
      y = _d$shift2[2];

  if (debug) {
    console.log('[parser] op %s | pointer %s | point %s', op, pointer, [x, y]);
  }

  switch (op) {
    case 'M':
      // absolute moveto operation
      return parser(d, [x, y], points, debug);
    // set pointer

    case 'm':
      {
        // relative moveto operation
        var _pointer = (0, _slicedToArray2["default"])(pointer, 2),
            pX = _pointer[0],
            pY = _pointer[1];

        return parser(d, [pX + x, pY + y], points, debug); // set pointer
      }

    case 'L':
      {
        // absolute lineto operation
        points.push(pointer); // line from pointer

        return parser(d, [x, y], points, debug); // set new pointer
      }

    case 'l':
      {
        // relative lineto operation
        points.push(pointer); // line from pointer

        var _pointer2 = (0, _slicedToArray2["default"])(pointer, 2),
            _pX = _pointer2[0],
            _pY = _pointer2[1];

        return parser(d, [_pX + x, _pY + y], points, debug); // set new pointer
      }

    case 'Z':
    case 'z':
      {
        // end operation
        points.push(pointer); // draw final line

        return parser(d, pointer, points, debug);
      }

    case 'C':
    case 'c':
      throw new Error('curve operation not supported in polygons');

    default:
      throw new Error('unknown operation ' + op);
  }
}

function svgPathToPoints(scannedPath, debug) {
  var points = parser(scannedPath.slice(), [0, 0], [], debug);
  if (debug) console.log('[main] result', points);
  return points;
}

module.exports = svgPathToPoints;