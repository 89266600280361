"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "Focus", {
  enumerable: true,
  get: function get() {
    return _Focus["default"];
  }
});
Object.defineProperty(exports, "FocusRef", {
  enumerable: true,
  get: function get() {
    return _Focus.FocusRef;
  }
});
Object.defineProperty(exports, "IfLeafletAvailable", {
  enumerable: true,
  get: function get() {
    return _IfLeafletAvailable["default"];
  }
});
Object.defineProperty(exports, "IfLeafletNotAvailable", {
  enumerable: true,
  get: function get() {
    return _IfLeafletNotAvailable["default"];
  }
});
Object.defineProperty(exports, "ImageOverlay", {
  enumerable: true,
  get: function get() {
    return _ImageOverlay["default"];
  }
});
Object.defineProperty(exports, "Polygon", {
  enumerable: true,
  get: function get() {
    return _Polygon["default"];
  }
});
Object.defineProperty(exports, "Polyline", {
  enumerable: true,
  get: function get() {
    return _Polyline["default"];
  }
});
exports["default"] = void 0;
Object.defineProperty(exports, "useFocus", {
  enumerable: true,
  get: function get() {
    return _Focus.useFocus;
  }
});
Object.defineProperty(exports, "useLeafletAvailable", {
  enumerable: true,
  get: function get() {
    return _IfLeafletAvailable.useLeafletAvailable;
  }
});
Object.defineProperty(exports, "useZoom", {
  enumerable: true,
  get: function get() {
    return _Focus.useZoom;
  }
});

require("./leafletHacks");

var _Focus = _interopRequireWildcard(require("./Focus"));

var _Polygon = _interopRequireDefault(require("./Polygon"));

var _Polyline = _interopRequireDefault(require("./Polyline"));

var _ImageOverlay = _interopRequireDefault(require("./ImageOverlay"));

var _IfLeafletAvailable = _interopRequireWildcard(require("./IfLeafletAvailable"));

var _IfLeafletNotAvailable = _interopRequireDefault(require("./IfLeafletNotAvailable"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

var _default = {
  Focus: _Focus["default"],
  FocusRef: _Focus.FocusRef,
  useFocus: _Focus.useFocus,
  Polygon: _Polygon["default"],
  Polyline: _Polyline["default"],
  ImageOverlay: _ImageOverlay["default"],
  IfLeafletAvailable: _IfLeafletAvailable["default"],
  IfLeafletNotAvailable: _IfLeafletNotAvailable["default"],
  useLeafletAvailable: _IfLeafletAvailable.useLeafletAvailable
};
exports["default"] = _default;