import ChartJS from 'chart.js'
import React, { useState, useRef, useEffect } from 'react'

export default function Chart ({ config }) {
  const [ chart, setChart ] = useState(null)
  const ctx = useRef(null)

  useEffect(() => {
    if (ctx?.current) {
      if (chart) {
        chart.destroy()
      }
      setChart(new ChartJS(ctx.current, config))
    }
    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [ config, ctx ]) // eslint-disable-line

  return (
    // this ref is not used to inject html directly, so it should be fine
    // eslint-disable-next-line react-security/no-refs
    <canvas ref={ctx} />
  )
}
