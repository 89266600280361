"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isType = exports.Types = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

/* We have to ignore the linter rule because it's a mutual recursive data structure */
var typ = function typ(type, cb) {
  return function (param) {
    var ret = cb ? cb(param) : null;

    if (!ret) {
      return type;
    } else {
      return _objectSpread(_objectSpread({}, ret), {}, {
        type: type
      });
    }
  };
};

var Ignore = function Ignore(x) {
  return x;
};

var Types = {
  String: typ('string'),
  Text: typ('text'),
  Number: typ('number'),
  File: typ('file', function (accept) {
    return accept && {
      accept: accept
    };
  }),
  Optional: Ignore
};
exports.Types = Types;

var isType = function isType(type, x) {
  if (typeof x === 'string') {
    return x === type;
  } else {
    if (x && x.type === type) {
      return x;
    }
  } // if none of the previous tests was succesful, the type tag didn't match


  return false;
};

exports.isType = isType;