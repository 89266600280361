"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = useConfig;

var _react = require("react");

var _ConfigContext = _interopRequireDefault(require("./ConfigContext"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function useConfig() {
  return (0, _react.useContext)(_ConfigContext["default"]);
}