import { useMemo, useCallback } from 'react'
import { useUndoContext } from './UndoContext'

const HISTORY_SIZE = 10

const lastNItems = (arr, n) =>
  arr.slice(Math.max(arr.length - n, 0))

export const useUndo = ({ setData }) => {
  const historyStateRef = useUndoContext()

  const replaceCurrent = useCallback((state) => {
    historyStateRef.current.current = state
  }, [])
  const push = useCallback((state) => {
    if (JSON.stringify(historyStateRef.current.current) === JSON.stringify(state)) return
    historyStateRef.current.redo = []
    historyStateRef.current.undo = [
      ...lastNItems(historyStateRef.current.undo, HISTORY_SIZE),
      historyStateRef.current.current,
    ]
    historyStateRef.current.current = state
  }, [])
  const undo = useCallback(() => {
    const lastVal = historyStateRef.current.undo.pop()
    if (lastVal) {
      historyStateRef.current.redo = [
        ...historyStateRef.current.redo,
        historyStateRef.current.current,
      ]
      historyStateRef.current.current = lastVal
      setData(lastVal)
    }
  }, [setData])
  const redo = useCallback(() => {
    const lastVal = historyStateRef.current.redo.pop()
    if (lastVal) {
      historyStateRef.current.undo = [
        ...historyStateRef.current.undo,
        historyStateRef.current.current,
      ]
      historyStateRef.current.current = lastVal
      setData(lastVal)
    }
  }, [setData])
  const isUndoAvailable = useCallback(() => historyStateRef.current.undo.length > 0, [])
  const isRedoAvailable = useCallback(() => historyStateRef.current.redo.length > 0, [])
  const history = useMemo(() => ({
    replaceCurrent,
    push,
    undo,
    redo,
    isUndoAvailable,
    isRedoAvailable,
  }), [ replaceCurrent, push, undo, redo, isUndoAvailable, isRedoAvailable ])

  return history
}
