"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isLightweight = exports["default"] = void 0;

var isLightweight = function isLightweight(action) {
  return action && (action.type === 'config' || action.behavior === 'lightweight');
};

exports.isLightweight = isLightweight;
var _default = {
  isLightweight: isLightweight
};
exports["default"] = _default;