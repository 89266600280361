"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.uploadDeviceStats = exports.updateDevice = exports.getDevices = exports.getDeviceStyle = exports.getDeviceStats = exports.getDevicePresentationData = exports.getDevicePresentation = exports.getDeviceLayout = exports.getDeviceInfo = exports.getDevice = exports.deleteDevice = exports.createDevice = exports.UPLOAD_DEVICE_STATS_SUCCESS = exports.UPLOAD_DEVICE_STATS_REQUEST = exports.UPLOAD_DEVICE_STATS_FAILURE = exports.UPDATE_DEVICE_SUCCESS = exports.UPDATE_DEVICE_REQUEST = exports.UPDATE_DEVICE_FAILURE = exports.GET_DEVICE_STYLE_SUCCESS = exports.GET_DEVICE_STYLE_REQUEST = exports.GET_DEVICE_STYLE_FAILURE = exports.GET_DEVICE_PRESENTATION_SUCCESS = exports.GET_DEVICE_PRESENTATION_REQUEST = exports.GET_DEVICE_PRESENTATION_FAILURE = exports.GET_DEVICE_PRESENTATION_DATA_SUCCESS = exports.GET_DEVICE_PRESENTATION_DATA_REQUEST = exports.GET_DEVICE_PRESENTATION_DATA_FAILURE = exports.GET_DEVICE_LAYOUT_SUCCESS = exports.GET_DEVICE_LAYOUT_REQUEST = exports.GET_DEVICE_LAYOUT_FAILURE = exports.GET_DEVICE_INFO_SUCCESS = exports.GET_DEVICE_INFO_REQUEST = exports.GET_DEVICE_INFO_FAILURE = exports.DEVICE_SUCCESS = exports.DEVICE_STATS_SUCCESS = exports.DEVICE_STATS_REQUEST = exports.DEVICE_STATS_FAILURE = exports.DEVICE_REQUEST = exports.DEVICE_FAILURE = exports.DEVICES_SUCCESS = exports.DEVICES_REQUEST = exports.DEVICES_FAILURE = exports.DELETE_DEVICE_SUCCESS = exports.DELETE_DEVICE_REQUEST = exports.DELETE_DEVICE_FAILURE = exports.CREATE_DEVICE_SUCCESS = exports.CREATE_DEVICE_REQUEST = exports.CREATE_DEVICE_FAILURE = void 0;

var _utils = require("./utils");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var DEVICES_REQUEST = 'DEVICES_REQUEST';
exports.DEVICES_REQUEST = DEVICES_REQUEST;
var DEVICES_SUCCESS = 'DEVICES_SUCCESS';
exports.DEVICES_SUCCESS = DEVICES_SUCCESS;
var DEVICES_FAILURE = 'DEVICES_FAILURE';
exports.DEVICES_FAILURE = DEVICES_FAILURE;

var getDevices = function getDevices() {
  return (0, _utils.thunkCreator)(function (state) {
    return {
      types: [DEVICES_REQUEST, DEVICES_SUCCESS, DEVICES_FAILURE],
      promise: function promise(token) {
        return fetch("".concat(process.env.REACT_APP_API_URL, "/devices"), (0, _utils.tokenHeader)(token));
      }
    };
  });
};

exports.getDevices = getDevices;
var DEVICE_REQUEST = 'DEVICE_REQUEST';
exports.DEVICE_REQUEST = DEVICE_REQUEST;
var DEVICE_SUCCESS = 'DEVICE_SUCCESS';
exports.DEVICE_SUCCESS = DEVICE_SUCCESS;
var DEVICE_FAILURE = 'DEVICE_FAILURE';
exports.DEVICE_FAILURE = DEVICE_FAILURE;

var getDevice = function getDevice(deviceId) {
  return (0, _utils.thunkCreator)(function (state) {
    return {
      types: [DEVICE_REQUEST, DEVICE_SUCCESS, DEVICE_FAILURE],
      promise: function promise(token) {
        return fetch("".concat(process.env.REACT_APP_API_URL, "/devices/") + deviceId, (0, _utils.tokenHeader)(token));
      }
    };
  });
};

exports.getDevice = getDevice;
var CREATE_DEVICE_REQUEST = 'CREATE_DEVICE_REQUEST';
exports.CREATE_DEVICE_REQUEST = CREATE_DEVICE_REQUEST;
var CREATE_DEVICE_SUCCESS = 'CREATE_DEVICE_SUCCESS';
exports.CREATE_DEVICE_SUCCESS = CREATE_DEVICE_SUCCESS;
var CREATE_DEVICE_FAILURE = 'CREATE_DEVICE_FAILURE';
exports.CREATE_DEVICE_FAILURE = CREATE_DEVICE_FAILURE;

var createDevice = function createDevice(name, additional) {
  return (0, _utils.thunkCreator)(function (state) {
    return {
      types: [CREATE_DEVICE_REQUEST, CREATE_DEVICE_SUCCESS, CREATE_DEVICE_FAILURE],
      promise: function promise(token) {
        return fetch("".concat(process.env.REACT_APP_API_URL, "/devices"), Object.assign({
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(_objectSpread(_objectSpread({}, additional), {}, {
            name: name
          }))
        }, (0, _utils.tokenHeader)(token)));
      }
    };
  });
};

exports.createDevice = createDevice;
var DELETE_DEVICE_REQUEST = 'DELETE_DEVICE_REQUEST';
exports.DELETE_DEVICE_REQUEST = DELETE_DEVICE_REQUEST;
var DELETE_DEVICE_SUCCESS = 'DELETE_DEVICE_SUCCESS';
exports.DELETE_DEVICE_SUCCESS = DELETE_DEVICE_SUCCESS;
var DELETE_DEVICE_FAILURE = 'DELETE_DEVICE_FAILURE';
exports.DELETE_DEVICE_FAILURE = DELETE_DEVICE_FAILURE;

var deleteDevice = function deleteDevice(deviceId) {
  return (0, _utils.thunkCreator)(function (state) {
    return {
      types: [DELETE_DEVICE_REQUEST, DELETE_DEVICE_SUCCESS, DELETE_DEVICE_FAILURE],
      promise: function promise(token) {
        return fetch("".concat(process.env.REACT_APP_API_URL, "/devices/") + deviceId, Object.assign({
          method: 'DELETE'
        }, (0, _utils.tokenHeader)(token)));
      }
    };
  });
};

exports.deleteDevice = deleteDevice;
var UPDATE_DEVICE_REQUEST = 'UPDATE_DEVICE_REQUEST';
exports.UPDATE_DEVICE_REQUEST = UPDATE_DEVICE_REQUEST;
var UPDATE_DEVICE_SUCCESS = 'UPDATE_DEVICE_SUCCESS';
exports.UPDATE_DEVICE_SUCCESS = UPDATE_DEVICE_SUCCESS;
var UPDATE_DEVICE_FAILURE = 'UPDATE_DEVICE_FAILURE';
exports.UPDATE_DEVICE_FAILURE = UPDATE_DEVICE_FAILURE;

var updateDevice = function updateDevice(deviceId, change) {
  return (0, _utils.thunkCreator)(function (state) {
    return {
      types: [UPDATE_DEVICE_REQUEST, UPDATE_DEVICE_SUCCESS, UPDATE_DEVICE_FAILURE],
      promise: function promise(token) {
        return fetch("".concat(process.env.REACT_APP_API_URL, "/devices/") + deviceId, Object.assign({
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(change)
        }, (0, _utils.tokenHeader)(token)));
      }
    };
  });
};

exports.updateDevice = updateDevice;
var GET_DEVICE_INFO_REQUEST = 'GET_DEVICE_INFO_REQUEST';
exports.GET_DEVICE_INFO_REQUEST = GET_DEVICE_INFO_REQUEST;
var GET_DEVICE_INFO_SUCCESS = 'GET_DEVICE_INFO_SUCCESS';
exports.GET_DEVICE_INFO_SUCCESS = GET_DEVICE_INFO_SUCCESS;
var GET_DEVICE_INFO_FAILURE = 'GET_DEVICE_INFO_FAILURE';
exports.GET_DEVICE_INFO_FAILURE = GET_DEVICE_INFO_FAILURE;

var getDeviceInfo = function getDeviceInfo(deviceId, license) {
  return (0, _utils.thunkCreator)(function (state) {
    return {
      types: [GET_DEVICE_INFO_REQUEST, GET_DEVICE_INFO_SUCCESS, GET_DEVICE_INFO_FAILURE],
      promise: function promise(token) {
        return fetch("".concat(process.env.REACT_APP_API_URL, "/ping"), Object.assign({
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            id: deviceId,
            license: license
          })
        }));
      },
      deviceId: deviceId,
      propName: 'deviceInfo'
    };
  });
};

exports.getDeviceInfo = getDeviceInfo;
var GET_DEVICE_PRESENTATION_REQUEST = 'GET_DEVICE_PRESENTATION_REQUEST';
exports.GET_DEVICE_PRESENTATION_REQUEST = GET_DEVICE_PRESENTATION_REQUEST;
var GET_DEVICE_PRESENTATION_SUCCESS = 'GET_DEVICE_PRESENTATION_SUCCESS';
exports.GET_DEVICE_PRESENTATION_SUCCESS = GET_DEVICE_PRESENTATION_SUCCESS;
var GET_DEVICE_PRESENTATION_FAILURE = 'GET_DEVICE_PRESENTATION_FAILURE';
exports.GET_DEVICE_PRESENTATION_FAILURE = GET_DEVICE_PRESENTATION_FAILURE;

var getDevicePresentation = function getDevicePresentation(deviceId, license) {
  return (0, _utils.thunkCreator)(function (state) {
    return {
      types: [GET_DEVICE_PRESENTATION_REQUEST, GET_DEVICE_PRESENTATION_SUCCESS, GET_DEVICE_PRESENTATION_FAILURE],
      promise: function promise(token) {
        return fetch("".concat(process.env.REACT_APP_API_URL, "/presentation.json"), Object.assign({
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            id: deviceId,
            license: license
          })
        }));
      },
      deviceId: deviceId,
      propName: 'presentationInfo'
    };
  });
};

exports.getDevicePresentation = getDevicePresentation;
var GET_DEVICE_PRESENTATION_DATA_REQUEST = 'GET_DEVICE_PRESENTATION_DATA_REQUEST';
exports.GET_DEVICE_PRESENTATION_DATA_REQUEST = GET_DEVICE_PRESENTATION_DATA_REQUEST;
var GET_DEVICE_PRESENTATION_DATA_SUCCESS = 'GET_DEVICE_PRESENTATION_DATA_SUCCESS';
exports.GET_DEVICE_PRESENTATION_DATA_SUCCESS = GET_DEVICE_PRESENTATION_DATA_SUCCESS;
var GET_DEVICE_PRESENTATION_DATA_FAILURE = 'GET_DEVICE_PRESENTATION_DATA_FAILURE';
exports.GET_DEVICE_PRESENTATION_DATA_FAILURE = GET_DEVICE_PRESENTATION_DATA_FAILURE;

var getDevicePresentationData = function getDevicePresentationData(deviceId, license) {
  return (0, _utils.thunkCreator)(function (state) {
    return {
      types: [GET_DEVICE_PRESENTATION_DATA_REQUEST, GET_DEVICE_PRESENTATION_DATA_SUCCESS, GET_DEVICE_PRESENTATION_DATA_FAILURE],
      promise: function promise(token) {
        return fetch("".concat(process.env.REACT_APP_API_URL, "/pd.json"), Object.assign({
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            id: deviceId,
            license: license
          })
        }));
      },
      deviceId: deviceId,
      propName: 'presentationData'
    };
  });
};

exports.getDevicePresentationData = getDevicePresentationData;
var GET_DEVICE_LAYOUT_REQUEST = 'GET_DEVICE_LAYOUT_REQUEST';
exports.GET_DEVICE_LAYOUT_REQUEST = GET_DEVICE_LAYOUT_REQUEST;
var GET_DEVICE_LAYOUT_SUCCESS = 'GET_DEVICE_LAYOUT_SUCCESS';
exports.GET_DEVICE_LAYOUT_SUCCESS = GET_DEVICE_LAYOUT_SUCCESS;
var GET_DEVICE_LAYOUT_FAILURE = 'GET_DEVICE_LAYOUT_FAILURE';
exports.GET_DEVICE_LAYOUT_FAILURE = GET_DEVICE_LAYOUT_FAILURE;

var getDeviceLayout = function getDeviceLayout(deviceId, license) {
  return (0, _utils.thunkCreator)(function (state) {
    return {
      types: [GET_DEVICE_LAYOUT_REQUEST, GET_DEVICE_LAYOUT_SUCCESS, GET_DEVICE_LAYOUT_FAILURE],
      promise: function promise(token) {
        return fetch("".concat(process.env.REACT_APP_API_URL, "/layout.json"), Object.assign({
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            id: deviceId,
            license: license
          })
        }));
      },
      deviceId: deviceId,
      propName: 'presentationLayout'
    };
  });
};

exports.getDeviceLayout = getDeviceLayout;
var GET_DEVICE_STYLE_REQUEST = 'GET_DEVICE_STYLE_REQUEST';
exports.GET_DEVICE_STYLE_REQUEST = GET_DEVICE_STYLE_REQUEST;
var GET_DEVICE_STYLE_SUCCESS = 'GET_DEVICE_STYLE_SUCCESS';
exports.GET_DEVICE_STYLE_SUCCESS = GET_DEVICE_STYLE_SUCCESS;
var GET_DEVICE_STYLE_FAILURE = 'GET_DEVICE_STYLE_FAILURE';
exports.GET_DEVICE_STYLE_FAILURE = GET_DEVICE_STYLE_FAILURE;

var getDeviceStyle = function getDeviceStyle(deviceId, license) {
  return (0, _utils.thunkCreator)(function (state) {
    return {
      types: [GET_DEVICE_STYLE_REQUEST, GET_DEVICE_STYLE_SUCCESS, GET_DEVICE_STYLE_FAILURE],
      promise: function promise(token) {
        return fetch("".concat(process.env.REACT_APP_API_URL, "/style.json"), Object.assign({
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            id: deviceId,
            license: license
          })
        }));
      },
      deviceId: deviceId,
      propName: 'presentationTheme'
    };
  });
};

exports.getDeviceStyle = getDeviceStyle;
var DEVICE_STATS_REQUEST = 'DEVICE_STATS_REQUEST';
exports.DEVICE_STATS_REQUEST = DEVICE_STATS_REQUEST;
var DEVICE_STATS_SUCCESS = 'DEVICE_STATS_SUCCESS';
exports.DEVICE_STATS_SUCCESS = DEVICE_STATS_SUCCESS;
var DEVICE_STATS_FAILURE = 'DEVICE_STATS_FAILURE';
exports.DEVICE_STATS_FAILURE = DEVICE_STATS_FAILURE;

var getDeviceStats = function getDeviceStats(deviceId) {
  return (0, _utils.thunkCreator)(function (state) {
    return {
      types: [DEVICE_STATS_REQUEST, DEVICE_STATS_SUCCESS, DEVICE_STATS_FAILURE],
      _id: deviceId,
      promise: function promise(token) {
        return fetch("".concat(process.env.REACT_APP_API_URL, "/devices/").concat(deviceId, "/stats.json"), (0, _utils.tokenHeader)(token));
      }
    };
  });
};

exports.getDeviceStats = getDeviceStats;
var UPLOAD_DEVICE_STATS_REQUEST = 'UPLOAD_DEVICE_STATS_REQUEST';
exports.UPLOAD_DEVICE_STATS_REQUEST = UPLOAD_DEVICE_STATS_REQUEST;
var UPLOAD_DEVICE_STATS_SUCCESS = 'UPLOAD_DEVICE_STATS_SUCCESS';
exports.UPLOAD_DEVICE_STATS_SUCCESS = UPLOAD_DEVICE_STATS_SUCCESS;
var UPLOAD_DEVICE_STATS_FAILURE = 'UPLOAD_DEVICE_STATS_FAILURE';
exports.UPLOAD_DEVICE_STATS_FAILURE = UPLOAD_DEVICE_STATS_FAILURE;

var uploadDeviceStats = function uploadDeviceStats(deviceId, license, chunk) {
  return (0, _utils.thunkCreator)(function (state) {
    return {
      types: [UPLOAD_DEVICE_STATS_REQUEST, UPLOAD_DEVICE_STATS_SUCCESS, UPLOAD_DEVICE_STATS_FAILURE],
      promise: function promise(token) {
        return fetch("".concat(process.env.REACT_APP_API_URL, "/stats.json"), Object.assign({
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(_objectSpread(_objectSpread({}, chunk), {}, {
            id: deviceId,
            license: license
          }))
        }));
      },
      deviceId: deviceId
    };
  });
};

exports.uploadDeviceStats = uploadDeviceStats;