"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = integrationsReducer;

var _actions = require("../actions");

function integrationsReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case _actions.INTEGRATIONS_SUCCESS:
      return action.result;

    default:
      return state;
  }
}