"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/*
  code heavily based on
    https://github.com/mui-org/material-ui/blob/509d0c23b2fb33864466fe33ef6aac32485c6320/packages/material-ui-styles/src/makeStyles/multiKeyStore.js

  - this code must be kept in sync so we can integrate with the styles context from material-ui without major breakage
  - it's technically incompatible if key0 happens to match with a key used in material-ui
    (this situation is technically impossible, as long as we keep using things such as objects as keys
     but we must be careful to avoid strings/things that can be cross-referenced)
*/
// Used https://github.com/thinkloop/multi-key-cache as inspiration
var multiKeyStore = {
  set: function set(cache0, key0, key1, key2, value) {
    var cache1 = cache0.get(key0);

    if (!cache1) {
      cache1 = new Map();
      cache0.set(key0, cache1);
    }

    var cache2 = cache1.get(key1);

    if (!cache2) {
      cache2 = new Map();
      cache1.set(key1, cache2);
    }

    cache2.set(key2, value);
  },
  get: function get(cache0, key0, key1, key2) {
    var _cache0$get, _cache0$get$get;

    return (_cache0$get = cache0.get(key0)) === null || _cache0$get === void 0 ? void 0 : (_cache0$get$get = _cache0$get.get(key1)) === null || _cache0$get$get === void 0 ? void 0 : _cache0$get$get.get(key2);
  },
  "delete": function _delete(cache0, key0, key1, key2) {
    var cache1 = cache0.get(key0);
    var cache2 = cache1.get(key1);
    cache2["delete"](key2);
  }
};
var _default = multiKeyStore;
exports["default"] = _default;