"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.runExpression = void 0;

var _jsep = _interopRequireDefault(require("jsep"));

/**
 * The general idea behind this file is to provide a minimal & safe interpreter
 * for certain JS functionality.
 *
 * The idea is to implement somewhat basic type-checking and add some safety
 * checks.
 *
 * We can use this function to evaluate a JavaScript expression. For example,
 * this is used to evaluate `filter` expressions in the layout objects, see:
 * https://git.touchlay.com/meta/docs/-/wikis/data-structures/layout
 *
 * This feature is also used in quiz condition checks
 *
 * @name runExpression
 */
var compute = function compute(op, e1, e2) {
  var mp = {
    '+': function _(a, b) {
      return a() + b();
    },
    '-': function _(a, b) {
      return a() - b();
    },
    '*': function _(a, b) {
      return a() * b();
    },
    '/': function _(a, b) {
      return a() / b();
    },
    '==': function _(a, b) {
      return a() === b();
    },
    '!=': function _(a, b) {
      return a() !== b();
    },
    '===': function _(a, b) {
      return a() === b();
    },
    '!==': function _(a, b) {
      return a() !== b();
    },
    '>': function _(a, b) {
      return a() > b();
    },
    '>=': function _(a, b) {
      return a() >= b();
    },
    '<': function _(a, b) {
      return a() < b();
    },
    '<=': function _(a, b) {
      return a() <= b();
    },
    '&&': function _(a, b) {
      return a() && b();
    },
    '||': function _(a, b) {
      return a() || b();
    }
  };

  if (!mp[op]) {
    throw new Error("Operator ".concat(op, " not supported"));
  }

  return mp[op](e1, e2);
};

var computeUnary = function computeUnary(op, e1) {
  var mp = {
    '!': function _(a) {
      return !a;
    }
  };

  if (!mp[op]) {
    throw new Error("Operator ".concat(op, " not supported"));
  }

  return mp[op](e1);
};

var recu = function recu(env) {
  var lookup = function lookup(t) {
    if (t.type === 'value') {
      return t.value;
    } else {
      return env[t.value];
    }
  };

  var loop = function loop(t) {
    if (t === false) {
      throw new Error('Missing expression');
    }

    var e1, e2;

    switch (t.type) {
      case 'Literal':
        return {
          type: 'value',
          value: t.value
        };

      case 'Identifier':
        return {
          type: 'ident',
          value: t.name
        };

      case 'BinaryExpression':
      case 'LogicalExpression':
        e1 = function e1() {
          return lookup(loop(t.left));
        };

        e2 = function e2() {
          return lookup(loop(t.right));
        };

        return {
          type: 'value',
          value: compute(t.operator, e1, e2)
        };

      case 'MemberExpression':
        {
          e1 = lookup(loop(t.object));

          var _e2 = loop(t.property);

          e2 = t.computed ? lookup(_e2) : _e2.value;

          if (!e1) {
            throw new Error('Invalid member access (trying to access undefined object)');
          }

          if (!e2) {
            throw new Error('Invalid member access (trying to access undefined property)');
          }

          var value = e1 instanceof Map ? e1.get(e2) : e1[e2];
          return {
            type: 'value',
            value: value
          };
        }

      case 'ConditionalExpression':
        return lookup(loop(t.test)) ? loop(t.consequest) : loop(t.alternate);

      case 'UnaryExpression':
        e1 = lookup(loop(t.argument));
        return {
          type: 'value',
          value: computeUnary(t.operator, e1)
        };

      default:
        throw new Error("Unsupported expression ".concat(t.type, " found"));
    }
  };

  return function (t) {
    return lookup(loop(t));
  };
};

var runExpression = function runExpression(env, s) {
  if (s === undefined) return true;
  return recu(env)((0, _jsep["default"])(s));
};

exports.runExpression = runExpression;