"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.dismissError = exports.DISMISS_ERROR = void 0;
var DISMISS_ERROR = 'DISMISS_ERROR';
exports.DISMISS_ERROR = DISMISS_ERROR;

var dismissError = function dismissError() {
  return {
    type: DISMISS_ERROR
  };
};

exports.dismissError = dismissError;