"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _exportNames = {
  DevModeSync: true,
  useDevModeDefaults: true
};
exports.useDevModeDefaults = exports["default"] = exports.DevModeSync = void 0;

var _react = require("react");

var _actions = require("./actions");

var _Config = require("./Config");

var _extension = require("./extension");

var _devMode = require("./devMode");

Object.keys(_devMode).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _devMode[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _devMode[key];
    }
  });
});

var DevModeSync = function DevModeSync() {
  var dispatch = (0, _actions.useActions)('#dev-mode-sync#');
  var devmodeConfig = (0, _Config.useConfig)('devmode');
  (0, _react.useEffect)(function () {
    if (devmodeConfig) {
      (0, _devMode.changeModeNormalized)(devmodeConfig);
      var wayfinderDevtools = window.__WAYFINDER_DEVTOOLS_EXTENSION__;

      if (wayfinderDevtools && wayfinderDevtools.send) {
        wayfinderDevtools.send('dev', {
          type: 'devmode',
          settings: (0, _devMode.internal$getCurrentMode)()
        });
      }
    }
  });
  (0, _extension.useExtensionLight)(function () {
    var wayfinderDevtools = window.__WAYFINDER_DEVTOOLS_EXTENSION__;

    if (wayfinderDevtools && wayfinderDevtools.connect$Internal) {
      var send = null;
      wayfinderDevtools.connect$Internal('dev', {
        connect: function connect(obj) {
          send = obj.send;
        },
        onReceived: function onReceived(act) {
          if (act.type === 'set-devmode') {
            dispatch({
              type: 'config',
              key: 'devmode',
              value: act.settings
            });
          }

          if (send && act.type === 'get-devmode') {
            send({
              type: 'devmode',
              settings: (0, _devMode.internal$getCurrentMode)()
            });
          }
        }
      });
      return function () {
        wayfinderDevtools.disconnect$Internal('dev');
      };
    }
  });
  return null;
};

exports.DevModeSync = DevModeSync;

var useDevModeDefaults = function useDevModeDefaults(defaults) {
  // somewhat hacky code to make sure it runs *before* anything, even useLayourEffect(s)
  var executedRef = (0, _react.useRef)(false);

  if (defaults && executedRef.current === false) {
    executedRef.current = true;
    (0, _devMode.changeModeNormalized)(defaults);
  }
};

exports.useDevModeDefaults = useDevModeDefaults;
var _default = {
  debug: _devMode.debug,
  info: _devMode.info,
  warn: _devMode.warn,
  error: _devMode.error,
  critical: _devMode.critical
};
exports["default"] = _default;