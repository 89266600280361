import React from 'react'

import Typography from '@material-ui/core/Typography'
import PeopleIcon from '@material-ui/icons/People'
import PersonIcon from '@material-ui/icons/Person'
import Tooltip from '@material-ui/core/Tooltip'
import { asListItem } from './utils'

import { resolveNodeName } from '../treeUtils'

export const EditItemType = asListItem(({ node }) => {
  return (
    <Typography>
      <span style={{ display: 'flex' }}>
        <span style={{ paddingRight: '0.5em' }}>{resolveNodeName(node)}</span>
        {node?._sourceLink && (
          <Tooltip key='personal-item' title='Changes made to this and its children will be only made in your version of the presentation'>
            <PeopleIcon />
          </Tooltip>
        )}
        {node?._source?.kind === 'user' && (
          <Tooltip key='personal-pd' title='This node is only visible to you'>
            <PersonIcon />
          </Tooltip>
        )}
      </span>
    </Typography>
  )
})

export default EditItemType
