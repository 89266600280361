"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.tokenHeader = exports.thunkCreator = exports.abortableThunkCreatorAxios = exports.abortableThunkCreator = void 0;

var _index = require("./index");

var _utils = require("@touchlay/utils");

var _firebase = _interopRequireDefault(require("../components/firebase"));

var _AbortablePromise = _interopRequireDefault(require("../AbortablePromise"));

var _excluded = ["types", "promise"],
    _excluded2 = ["types", "promise"];

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

function FetchError(message, status, statusCode) {
  this.name = 'FetchError';
  this.message = message || '';
  this.status = status;
  this.statusCode = statusCode;
}

FetchError.prototype = Error.prototype; // provide the current user token to actions

var tokenProvider = function tokenProvider() {
  return new Promise(function (resolve, reject) {
    var user = _firebase["default"].auth.currentUser;

    if (!user) {
      var delHandler = _firebase["default"].auth.onAuthStateChanged(function (changedUser) {
        if (!changedUser) {
          resolve();
          delHandler();
        } else {
          changedUser.getIdToken().then(function (token) {
            resolve(token);
            delHandler();
          });
        }
      });
    } else user.getIdToken().then(function (token) {
      return resolve(token);
    });
  });
}; // create a promise handler that:
//  * emits the first action type (REQUESTED) when the promise is started
//  * emits the second action type (RESOLVED) when everything works well
//  * emits the third action type (REJECTED) when an error happened


var abortableThunkCreator = function abortableThunkCreator(_action) {
  return function (dispatch, getState) {
    var action = typeof _action === 'function' ? _action(getState()) : _action;

    var types = action.types,
        promise = action.promise,
        rest = _objectWithoutProperties(action, _excluded);

    var _types = _slicedToArray(types, 3),
        REQUESTED = _types[0],
        RESOLVED = _types[1],
        REJECTED = _types[2];

    dispatch(_objectSpread(_objectSpread({}, rest), {}, {
      type: REQUESTED
    }));

    var tokenPromise = function tokenPromise(abortController) {
      return tokenProvider().then(function (token) {
        return {
          token: token,
          abortController: abortController
        };
      });
    };

    return new _AbortablePromise["default"](tokenPromise).then(promise).then(function (response) {
      var jsonResponse = response.json();

      if (response.status >= 400 && response.status < 600) {
        // check if request is okay
        return jsonResponse.then(function (err) {
          return Promise.reject(new FetchError(err, response.statusText, response.status));
        });
      } else {
        return jsonResponse;
      }
    }).then(function (response) {
      return dispatch(_objectSpread(_objectSpread({}, rest), {}, {
        type: RESOLVED,
        result: response
      }));
    }) // otherwise, dispatch result)
    ["catch"](function (error) {
      if (error.name === 'AbortError') {
        return dispatch(_objectSpread(_objectSpread({}, rest), {}, {
          type: REJECTED,
          error: 'Request aborted'
        }));
      }

      if (error.statusCode === 401) {
        dispatch({
          type: _index.UNAUTHORIZED
        }); // if server returns "invalid token", logout
        // FIXME dispatch(replace('/auth')) // redirect to login page

        return {
          type: _index.UNAUTHORIZED
        };
      } else {
        dispatch(_objectSpread(_objectSpread({}, rest), {}, {
          type: REJECTED,
          error: error.message
        }));
      }

      if (error.statusCode >= 500) {
        // server error, report it
        (0, _utils.logException)(error);
      }

      return _objectSpread(_objectSpread({}, rest), {}, {
        type: REJECTED,
        error: error.message
      });
    });
  };
}; // copy-pasted code used as base


exports.abortableThunkCreator = abortableThunkCreator;

var abortableThunkCreatorAxios = function abortableThunkCreatorAxios(_action) {
  return function (dispatch, getState) {
    var action = typeof _action === 'function' ? _action(getState()) : _action;

    var types = action.types,
        promise = action.promise,
        rest = _objectWithoutProperties(action, _excluded2);

    var _types2 = _slicedToArray(types, 3),
        REQUESTED = _types2[0],
        RESOLVED = _types2[1],
        REJECTED = _types2[2];

    dispatch(_objectSpread(_objectSpread({}, rest), {}, {
      type: REQUESTED
    }));

    var tokenPromise = function tokenPromise(abortController) {
      return tokenProvider().then(function (token) {
        return {
          token: token,
          abortController: abortController
        };
      });
    };

    return new _AbortablePromise["default"](tokenPromise).then(promise).then(function (response) {
      if (response.status >= 400 && response.status < 600) {
        // check if request is okay
        // this shouldn't happen?
        return Promise.reject(new FetchError(response.data, response.statusText, response.status));
      } else {
        return response.data;
      }
    }).then(function (response) {
      return dispatch(_objectSpread(_objectSpread({}, rest), {}, {
        type: RESOLVED,
        result: response
      }));
    }) // otherwise, dispatch result)
    ["catch"](function (error) {
      if (error.name === 'AbortError' || error.message === 'cancelled') {
        return dispatch(_objectSpread(_objectSpread({}, rest), {}, {
          type: REJECTED,
          error: 'Request aborted'
        }));
      }

      if (error.statusCode === 401) {
        // TODO: handle sign out errors, and actually wait for it to be completed
        // this needs more work
        _firebase["default"].auth.signOut();

        dispatch({
          type: _index.UNAUTHORIZED
        }); // if server returns "invalid token", logout
        // FIXME dispatch(replace('/auth')) // redirect to login page

        return {
          type: _index.UNAUTHORIZED
        };
      } else {
        dispatch(_objectSpread(_objectSpread({}, rest), {}, {
          type: REJECTED,
          error: error.message
        }));
      }

      if (error.statusCode >= 500) {
        // server error, report it
        (0, _utils.logException)(error);
      }

      return _objectSpread(_objectSpread({}, rest), {}, {
        type: REJECTED,
        error: error.message
      });
    });
  };
}; // simplified handler (for back-compat & thunks which can't be cancelled)


exports.abortableThunkCreatorAxios = abortableThunkCreatorAxios;

var thunkCreator = function thunkCreator(_action) {
  var replace = function replace(obj) {
    return _objectSpread(_objectSpread({}, obj), {}, {
      promise: typeof obj.promise === 'function' ? function (_ref) {
        var token = _ref.token;
        return obj.promise(token);
      } : obj.promise // they don't care about the token anyway

    });
  };

  var _actionProc = typeof _action === 'function' ? function () {
    return replace(_action.apply(void 0, arguments));
  } : function () {
    return replace(_action);
  };

  return abortableThunkCreator(_actionProc);
}; // Check if the auth token from firebase exists


exports.thunkCreator = thunkCreator;

var tokenHeader = function tokenHeader(token) {
  if (!token) {
    console.error('token not found');
    return {};
  } else {
    return {
      headers: new Headers({
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json'
      })
    };
  }
};

exports.tokenHeader = tokenHeader;