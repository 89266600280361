import React from 'react'

import Typography from '@material-ui/core/Typography'
import { usePDTranslation } from '@touchlay/utils'
import { asListItem } from './utils'

export const EditItemStatic = asListItem(({ value }) => {
  const tPD = usePDTranslation()
  return (
    <Typography>{tPD(value)}</Typography>
  )
})

export default EditItemStatic
