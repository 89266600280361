import React from 'react'

import { makeStyles } from '@material-ui/styles'
import List from '@material-ui/core/List'
import ListSubheader from '@material-ui/core/ListSubheader'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardActions from '@material-ui/core/CardActions'
import CardMedia from '@material-ui/core/CardMedia'
import Button from '@material-ui/core/Button'

import BetaNotice from '@touchlay/frontend-base/dist/components/editor/BetaNotice'

const useStyles = makeStyles((theme) => ({
  cards: {
    padding: theme.spacing(1),
  },
  cardContainer: {
    padding: theme.spacing(1),
  },
  card: {
    width: '100%',
  },
  actions: {
    display: 'flex',
    justifyContent: 'center',
  },
  image: {
    width: 120,
    height: 80,
    margin: theme.spacing(5) + ' auto 0px',
  },
  betaNotice: {
    paddingLeft: theme.spacing(2),
  },
}))

export default function NodePicker ({ nodes, handleCreate, cancel }) {
  const styles = useStyles()

  const renderedNodes = nodes
    .filter(node => !!node)
    .flat()
    .filter(node => !!node.template)

  return (
    <List>
      <ListSubheader color='primary'>Create New...</ListSubheader>
      <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: '0.5em' }}>
        <Button color='inherit' onClick={cancel} variant='outlined'>Cancel & Go Back</Button>
      </div>
      <Grid className={styles.cards} container>
        {renderedNodes.map(({ image, icon, title, template, beta }, i) => {
          return (
            <Grid
              className={styles.cardContainer}
              item
              key={'node-picker-' + i}
              sm={6}
            >
              <Card className={styles.card} variant='outlined'>
                <CardActionArea onClick={handleCreate(template)}>
                  {(image || icon) &&
                    <CardMedia className={styles.image} image={image || icon} title={title} />}
                  <CardActions className={styles.actions}>
                    <Button color='primary' component='div' size='small'>
                      {title}
                      {beta && (
                        <span className={styles.betaNotice}>
                          <BetaNotice small={false} />
                        </span>
                      )}
                    </Button>
                  </CardActions>
                </CardActionArea>
              </Card>
            </Grid>
          )
        })}
      </Grid>
    </List>
  )
}
