import { CUSTOMDATA_URL } from '@touchlay/frontend-base'

import layoutData from './layout.json'
import styleData from './style.json'

// custom data json id passed to the function so we can use it in the source
export default function generateDefaults (cdId) {
  const sources = [
    {
      name: 'Custom Data',
      integration: 'json',
      config: {
        url: `${CUSTOMDATA_URL}/single/${cdId}.json`,
      },
      meta: {
        kind: 'main',
      },
    },
  ]

  const style = { data: styleData }
  const layout = { data: layoutData }

  return { type: 'presentation', sources, style, layout }
}
