import React, { useState } from 'react'

import { useTheme } from '@material-ui/styles'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import LockIcon from '@material-ui/icons/Lock'
import EditIcon from '@material-ui/icons/Edit'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'

import PeopleIcon from '@material-ui/icons/People'
import PersonIcon from '@material-ui/icons/Person'

import ButtonGroup from '@material-ui/core/ButtonGroup'
import Button from '@material-ui/core/Button'

import UndoIcon from '@material-ui/icons/Undo'
import RedoIcon from '@material-ui/icons/Redo'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import CopyIcon from '@material-ui/icons/AddToPhotos'
import PasteIcon from '@material-ui/icons/AssignmentReturned'
import CancelIcon from '@material-ui/icons/Close'

import { usePDTranslation } from '@touchlay/utils'
import { useModal } from '@touchlay/frontend-base/dist/utils'

import { SortableTreeWithoutDndContext as SortableTree } from 'react-sortable-tree'
import './treeStyle.css'

import {
  getNodeKey, NodeType, addNode, deleteNode, expandAll, resolveNodeName,
  generateID, addNodeAtRoot, canPlaceNode,
} from './treeUtils'
import { makeStyles } from '@material-ui/core'

const useNodeTitleStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  locked: {
    cursor: null,
  },
  ignored: {
    filter: 'grayscale(0.75)',
  },
  titleSpan: {
    paddingLeft: '0.5em',
    fontSize: '1rem',
    maxWidth: '175px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}))
function NodeTitle (node) {
  const { id, meta, title, view, openSubpage, treeIndex, path, _source, _sourceLink } = node
  const tPD = usePDTranslation()
  const classes = useNodeTitleStyles()
  const nodeName = resolveNodeName(node)
  const { locked } = meta || {}
  const userLevelSource = _source?.kind === 'user'
  /*
    TODO: see how to handle this
    when we share a presentation with a restricted user, they will lack top-level access
    and if their privileges are on a secondary view, hiding these w/o access would move other views to "main"
    this check isn't enough, a deeply nested user source/source link would do that and it'd not be caught
  */
  const ignoredView = (_sourceLink || userLevelSource)
    ? false
    : view && path.length === 1 && treeIndex > 0
  const hidden = !!node?.hidden
  return (
    <span className={`${classes.root} ${locked ? classes.locked : ''} ${(ignoredView || hidden) ? classes.ignored : ''}`} onClick={() => openSubpage('element', id)}>
      <NodeType {...node} />
      {_sourceLink && (
        <Tooltip key='personal-item' title='Changes made to this and its children will be only made in your version of the presentation'>
          <PeopleIcon />
        </Tooltip>
      )}
      {userLevelSource && (
        <Tooltip key='personal-pd' title='This node is only visible to you'>
          <PersonIcon />
        </Tooltip>
      )}
      {ignoredView && (
        <Tooltip key='ignored-view' title='Only the first view is shown'>
          <VisibilityOffIcon />
        </Tooltip>
      )}
      {(hidden && !ignoredView) && (
        <Tooltip key='hidden-pd' title="This element is hidden and won't be rendered">
          <VisibilityOffIcon />
        </Tooltip>
      )}
      <span className={classes.titleSpan}>
        {tPD(title) || tPD(nodeName || id)}
      </span>
    </span>
  )
}

const nodeButtons = ({ node, viewCount, path, openSubpage, createNode, removeNode, copyNode, cancelCopy, pasteNode, copyingNode, noDuplicate, disabled }) => {
  const buttonStyle = {
    padding: '8px',
  }
  const copy = (
    <Tooltip key='copy-button' onClick={copyNode} title='Copy this node to create a duplicate'>
      <IconButton style={buttonStyle}>
        <CopyIcon />
      </IconButton>
    </Tooltip>
  )

  const cancel = (
    <Tooltip key='cancel-button' onClick={cancelCopy} title='Cancel copying this node'>
      <IconButton style={buttonStyle}>
        <CancelIcon />
      </IconButton>
    </Tooltip>
  )

  const paste = (
    <Tooltip key='paste-button' onClick={pasteNode} title='Paste the copied node here'>
      <IconButton style={buttonStyle}>
        <PasteIcon />
      </IconButton>
    </Tooltip>
  )

  const locked = (
    <Tooltip key='locked-info' title='This node is locked and cannot be edited'>
      <IconButton style={buttonStyle}>
        <LockIcon />
      </IconButton>
    </Tooltip>
  )

  const add = (
    <IconButton key='add-button' onClick={createNode} style={buttonStyle}>
      <AddIcon />
    </IconButton>
  )

  const edit = (
    <IconButton key='edit-button' onClick={() => openSubpage('element', node.id)} style={buttonStyle}>
      <EditIcon />
    </IconButton>
  )

  const remove = (
    <IconButton key='remove-button' onClick={removeNode} style={buttonStyle}>
      <DeleteIcon />
    </IconButton>
  )

  if (node.meta?.locked || disabled) { // you cannot do anything with locked items
    return [locked]
  }

  const usedButtons = []

  // if we are already copying, only allow cancelling (or pasting)
  if (copyingNode && copyingNode.id === node.id) {
    return [cancel]
  }

  if (!noDuplicate && !copyingNode) { // allow copying if we are not already doing it
    usedButtons.push(copy)
  }

  if (
    ((node.type === 'view' || node.type === 'article') && !(node.view === 'FullscreenView' && node.children && node.children.length > 0)) ||
    (node.type === 'menu.item' && (!node.children || node.children.length === 0))
  ) {
    // if we are in copying mode, only allow pasting (or cancelling)
    if (copyingNode) {
      if (node.type === 'view' && copyingNode.type === 'view') {
        return []
      }
      if (node.type === 'menu.item' && copyingNode.type === 'menu.item') {
        return []
      }
      return [paste]
    }
    usedButtons.push([ add, edit ])
  } else if (!copyingNode) { // only allow editing if we are not in copying mode
    usedButtons.push(edit)
  }

  if (!node.meta?.noDelete && !copyingNode && !(path.length === 1 && viewCount <= 1)) {
    usedButtons.push(remove)
  }

  return usedButtons
}

// remove duplicate IDs when pasting + make sure nodes
const deduplicate = (node, treeData) => {
  const tempIds = []
  const dedup = (node, parent = null) => {
    node.id = generateID(node.id, treeData, tempIds)
    if (node._sourceLink) {
      node._sourceLink = `${generateID()}`
    }
    tempIds.push(node.id)
    if (Array.isArray(node.children)) {
      node.children = node.children.map(n => dedup(n, node))
    }
    if (Array.isArray(node.subViews)) {
      node.subViews = node.subViews.map(n => dedup(n, node))
    }
    return node
  }
  return dedup(node)
}

export default function TreeView ({ disabled, changeMode, mode, treeData, history, topLevel, setData, openSubpage, scrollLeft, options, presentation, height, noDuplicate = false, fullTreeData, parentNode }) {
  const [ modalComponent, openModal ] = useModal()
  const [ copyingNode, setCopyingNode ] = useState(false)
  const theme = useTheme()

  const { noButtons = false } = options || {}

  const setDataReq = (newData) => {
    function goT (xs, parent) {
      if (Array.isArray(xs)) {
        for (let ix = 0; ix < xs.length; ix++) {
          const v = go(xs[ix], parent)
          if (v) return v
        }
      } else if (xs) {
        return go(xs, parent)
      } else {
        return null
      }
    }
    function go (it, _parent) {
      // console.warn(it, it?._source)
      const fromLink = it?._source.link
      const toLink = _parent?._sourceLink || _parent?._source?.link
      // console.warn({ fromLink, toLink })
      if (toLink && fromLink) {
        if (toLink !== fromLink) {
          // different sources, impossible case currently
          return {
            type: 'between-sources',
            from: fromLink,
            to: toLink,
          } // else => moving inside the same user source
        }
      } else if (fromLink && !toLink) {
        // we are moving out of an user source
        return {
          type: 'to-main-source',
          from: fromLink,
        }
      } else if (!fromLink && toLink) {
        return {
          type: 'to-user-source',
          to: toLink,
        }
      } // else => moving inside main source
      if (it.children) {
        return goT(it.children, it)
      }
      return null
    }
    const status = goT(newData, parentNode)
    if (status) {
      const maps = {
        'to-main-source': {
          title: 'Per-User Versions',
          desc: 'Moving this outside of the per-user section will make it available for all users. Are you sure you want to do this?',
        },
        'to-user-source': {
          title: 'Per-User Versions',
          desc: 'Moving this inside the per-user section will remove it for all other users. Are you sure you want to do this?',
        },
      }
      openModal('confirm', {
        severity: 'warning',
        ...(maps[status.type] || {
          title: 'Unknown movement',
          desc: 'moving this may cause issues, do you want to continue?',
        }),
        accept: () => {
          setData(newData)
        },
      })
    } else {
      setData(newData)
    }
  }
  const copyNode = (node) => () => {
    setCopyingNode(node)
  }

  const cancelCopy = () => {
    setCopyingNode(false)
  }

  const pasteNode = (destination) => () => {
    // in sub-trees, we use the full tree data to check for duplicate IDs
    const fullTree = fullTreeData || treeData
    // FIXME: this might result in data loss, use better deep cloning method if it is a problem
    const nodeCopy = JSON.parse(JSON.stringify(copyingNode))
    const deduplicatedNode = deduplicate(nodeCopy, fullTree)
    const treeNew = destination
      ? addNode(treeData, destination, deduplicatedNode)
      : addNodeAtRoot(treeData, deduplicatedNode)
    setData(treeNew.treeData)
    setCopyingNode(false)
  }

  const canDrop = ({ node, nextParent }) => {
    const newParent = nextParent ?? topLevel.node
    if (disabled) return false
    if (node?.meta?.locked) return false
    if (mode?.noTopLevelDrop && !nextParent) return false
    /* if we're dropping a link to an user source, and the target is on an user source - nesting might break things */
    const checkSourceLink = (x) => {
      if (x?._sourceLink) return x._sourceLink
      if (x?._source?.kind === 'user') return x._source.link
      if (x?.content) {
        if (Array.isArray(x.content)) {
          return x.content.reduce((acc, y) => acc || checkSourceLink(y), false)
        } else {
          return checkSourceLink(x.content)
        }
      }
      return false
    }
    const linkNode = checkSourceLink(node)
    const linkParent = checkSourceLink(nextParent)
    if (linkNode && linkParent && linkNode !== linkParent) {
      return false
    }
    if (!canPlaceNode(newParent, node)) {
      return false
    }
    if (node.type === 'view') {
      // views should only be under menu items, or in direct mode
      // if direct, any number of children is allowed, otherwise it is limited to 0-1
      if (newParent.type === 'direct') return true
      if (newParent.type === 'menu.item') {
        if (newParent.children && newParent.children.length > 1) {
          // has to be > 1 instead of > 0 because when this gets executed the
          // view is already part of the parents children
          return false // this menu item already has a view
        } else {
          return true
        }
      } else {
        return false
      }
    } else { // all other items should be top level (if allowed) or under a view, if allowed by add rules
      if (newParent.type === 'view') {
        if (newParent?.view === 'FullscreenView' && newParent?.children?.length > 1) {
          return false // this fullscreen view already has an item
        }
        return true
      } else {
        return false
      }
    }
  }

  const removeNode = (path) => () => {
    openModal('confirm', {
      severity: 'warning',
      title: 'Delete Node & All Children',
      desc: 'Are you sure you want to delete this node and all its children? This action CANNOT be undone!',
      accept: () => {
        const treeNew = deleteNode(treeData, path)
        setData(treeNew)
      },
    })
  }

  const expandNodes = () => {
    const treeNew = expandAll(treeData, true)
    setData(treeNew)
  }

  const collapseNodes = () => {
    const treeNew = expandAll(treeData, false)
    setData(treeNew)
  }
  const switchModes = () => {
    changeMode(mode => ({
      ...mode,
      look: mode?.look === 'userHidden' ? 'userLock' : 'userHidden',
    }))
  }

  const onVisibilityToggle = ({ expanded, path }) => {
    const depth = expanded ? path.length - 1 : path.length - 2
    scrollLeft(depth * 40)
  }

  const addNew = (pId) => {
    openSubpage('add', pId)
  }
  // convert height to string to work with calc()
  const _height = (typeof height === 'number') ? height + 'px' : height

  const alreadyHasView = parentNode && parentNode.type === 'menu.item' && parentNode.children && parentNode.children.length > 0
  const fullscreenAlreadyHasContents = parentNode && parentNode.type === 'view' && parentNode.view === 'FullscreenView' && parentNode.children && parentNode.children.length > 0
  // we're on the top level tree in user mode or full read-only so we can't add new components here
  const topUser = !parentNode && (mode?.type === 'user' || mode?.type === 'read')
  const addButtonDisabled = copyingNode || alreadyHasView || fullscreenAlreadyHasContents || topUser
  const addButtonDisabledInfo =
    copyingNode
      ? 'adding is disabled, because you are currently copying an item'
      : alreadyHasView
        ? 'adding is disabled, because this item already has a view'
        : fullscreenAlreadyHasContents
          ? 'adding is disabled, because this fullscreen view already has an item, and can only have one'
          : topUser
            ? 'adding is disabled, because you don\'t have full access'
            : 'adding is disabled'
  const addButton = (
    <Button
      color={addButtonDisabled ? 'inherit' : 'primary'}
      component={addButtonDisabled ? 'div' : undefined}
      disableElevation
      disabled={addButtonDisabled}
      key='add'
      onClick={addButtonDisabled ? undefined : () => addNew(parentNode?.id)}
      style={addButtonDisabled ? { pointerEvents: 'auto' } : {}}
      variant='contained'
    >
      <AddIcon />
    </Button>
  )

  const topLevelAllowed = !topUser
  const canPasteNode = copyingNode && (
    (topLevel.node.type === 'view' && topLevel.node.view === 'GridView' && copyingNode.type !== 'view') ||
    (topLevel.node.type === 'direct' && copyingNode.type === 'view')
  )
  const viewCount = treeData.length
  return (
    <div style={{ height: _height || 'calc(100vh - 128px)', overflow: 'auto' }}>
      {modalComponent}
      {(!noButtons && !disabled) && (
        <div style={{ padding: theme.spacing(5), position: 'relative', display: 'flex', justifyContent: 'space-between', zIndex: 1, boxShadow: theme.shadows[4] }}>
          <ButtonGroup color='primary' size='small'>
            {
              (topLevelAllowed && canPasteNode)
                ? (
                  <Button
                    disableElevation
                    key='paste'
                    onClick={pasteNode()}
                    startIcon={<PasteIcon />}
                    variant='contained'
                  >Paste
                  </Button>
                  )
                : addButtonDisabled
                  ? (
                    <Tooltip key='add-disabled' title={addButtonDisabledInfo}>
                      {addButton}
                    </Tooltip>
                    )
                  : (
                    <Tooltip key='add' title={'Add'}>
                      {addButton}
                    </Tooltip>
                    )
            }
            <Tooltip key='expand' title='Expand All'>
              <Button key='expand' onClick={expandNodes}>
                <ExpandMoreIcon />
              </Button>
            </Tooltip>
            <Tooltip key='collapse' title='Collapse All'>
              <Button key='collapse' onClick={collapseNodes}>
                <ExpandLessIcon />
              </Button>
            </Tooltip>
            {mode?.type === 'user' && (
              <Tooltip key='mode-switch' title={mode.look === 'userHidden' ? 'Show Locked' : 'Hide Locked'}>
                <Button key={'mode-switch'} onClick={switchModes}>
                  {mode.look === 'userHidden' ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </Button>
              </Tooltip>
            )}
          </ButtonGroup>
          {history &&
            <ButtonGroup color='primary' size='small'>
              <Button disabled={!history.isUndoAvailable()} key='undo-button' onClick={history.undo}>
                <UndoIcon />
              </Button>
              <Button disabled={!history.isRedoAvailable()} key='redo-button' onClick={history.redo}>
                <RedoIcon />
              </Button>
            </ButtonGroup>}
        </div>
      )}
      <div style={{ height: _height ? `calc(${_height} - 61px)` : 'calc(100% - 61px)' }}>
        <SortableTree
          canDrag={({ node }) => !node.meta?.noMove}
          canDrop={canDrop}
          generateNodeProps={({ node, path, treeIndex }) => ({
            // likely no user input will be spread here, so it should be fine
            // TODO: still consider using node={node} instead as it's more maintainable
            // eslint-disable-next-line react/jsx-props-no-spreading
            title: (
              <NodeTitle
                {...node} openSubpage={openSubpage} path={path}
                treeIndex={treeIndex} />
            ),
            buttons: nodeButtons(
              {
                node,
                viewCount,
                path,
                openSubpage,
                createNode: () => addNew(node?.id),
                removeNode: removeNode(path),
                copyNode: copyNode(node),
                cancelCopy,
                pasteNode: pasteNode(path[path.length - 1]),
                copyingNode,
                noDuplicate,
              }
            ),
            subtitle: ' ',
          })}
          getNodeKey={getNodeKey}
          onChange={setDataReq}
          onVisibilityToggle={onVisibilityToggle}
          rowHeight={60}
          treeData={treeData}
        />
      </div>
    </div>
  )
}
