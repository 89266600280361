"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useSelection = exports.toArray = exports.normalizeID = exports.matchGroup = exports.getClickedFromAction = exports["default"] = void 0;

var _react = _interopRequireWildcard(require("react"));

var _utils = require("@touchlay/utils");

var _helpers = require("../generic/helpers");

var _internal = require("./leaflet/internal");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e2) { throw _e2; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e3) { didErr = true; err = _e3; }, f: function f() { try { if (!normalCompletion && it["return"] != null) it["return"](); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

var normalizeID = function normalizeID(_parent) {
  var parentComponents = _parent ? _parent.split('_') : undefined;
  return parentComponents ? parentComponents[parentComponents.length - 1] : undefined;
};

exports.normalizeID = normalizeID;

var matchGroup = function matchGroup(ownGroup, parent, _groupToMatch) {
  var groupsToMatch = typeof _groupToMatch !== 'undefined' ? Array.isArray(_groupToMatch) ? _groupToMatch : [_groupToMatch] : [];
  groupsToMatch = groupsToMatch.map(normalizeID);
  var groupMatch = groupsToMatch.findIndex(function (groupToMatch) {
    return Array.isArray(ownGroup) ? ownGroup.findIndex(function (v) {
      return groupToMatch === v;
    }) !== -1 : ownGroup === groupToMatch;
  }) !== -1;
  var parentMatch = groupsToMatch.findIndex(function (groupToMatch) {
    return Array.isArray(parent) ? parent.findIndex(function (v) {
      return groupToMatch === normalizeID(v);
    }) !== -1 : normalizeID(parent) === groupToMatch;
  }) !== -1;
  return parentMatch || groupMatch;
};

exports.matchGroup = matchGroup;

var getClickedFromAction = function getClickedFromAction(_ref, action, content) {
  var ownID = _ref.ownID,
      ownGroup = _ref.ownGroup,
      ownParent = _ref.ownParent,
      linked = _ref.linked;

  if (action.name === 'ui.favorites') {
    return {
      type: 'ui.select',
      clicked: false,
      indirect: true
    };
  }

  if (action.name === 'ui.select') {
    var actionTo = action.to;
    var normalActionTo = normalizeID(actionTo);
    var res = {
      type: 'ui.select',
      clicked: normalActionTo === normalizeID(ownID),
      indirect: action.indirect === true
    };

    if (action.indirect) {
      if (res.clicked) {
        return res;
      } else {
        return null;
      }
    }

    if (!res.clicked && linked) {
      if (Array.isArray(linked)) {
        var _iterator = _createForOfIteratorHelper(linked),
            _step;

        try {
          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            var id = _step.value;
            var clicked = normalActionTo === normalizeID(id);

            if (clicked) {
              res = {
                type: 'ui.select',
                clicked: clicked,
                indirect: true
              };
              break;
            }
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }
      } else {
        res = {
          type: 'ui.select',
          clicked: normalActionTo === normalizeID(linked),
          indirect: true
        };
      }
    }

    if (!res.clicked && content) {
      _react["default"].Children.forEach(content, function (ch) {
        if (ch && ch.props && ch.props.id && ch.props.highlightWhenContained) {
          if (action.to === ch.props.id) {
            res = {
              type: 'ui.select',
              clicked: true,
              indirect: true,
              children: true
            };
          }
        }
      });
    }

    return res;
  }

  if (action.name === 'ui.select.group' && ownGroup != null) {
    var _clicked = matchGroup(ownGroup, ownParent, action.to);

    var clickedDirect = normalizeID(ownID) === normalizeID(action.to);
    return {
      type: 'ui.select.group',
      clicked: clickedDirect || _clicked,
      indirect: !clickedDirect
    };
  }

  if (action.name === 'ui.idle') {
    return {
      type: 'ui.select',
      clicked: false
    };
  }

  return null;
};

exports.getClickedFromAction = getClickedFromAction;

var toArray = function toArray(val) {
  if (typeof val === 'undefined') return [];
  if (Array.isArray(val)) return val;
  return [val];
};

exports.toArray = toArray;

var useSelection = function useSelection(props) {
  var zoom = (0, _internal.useZoom)();
  var focus = (0, _internal.useFocus)();

  var _useState = (0, _react.useState)(false),
      _useState2 = _slicedToArray(_useState, 2),
      clicked = _useState2[0],
      setClicked = _useState2[1];

  var clickedRef = (0, _helpers.useSyncRef)(clicked);
  (0, _utils.useActions)(props.id, function (action) {
    var act = getClickedFromAction({
      ownID: props.id,
      ownGroup: toArray(props.group),
      ownParent: props.meta && props.meta.parent,
      linked: toArray(props.linked)
    }, action, props.content);
    if (!act) return;

    if (act.type === 'ui.select') {
      if (clickedRef.current !== act.clicked) {
        setClicked(act.clicked);
      }

      if ((act.indirect !== true || act.children) && act.clicked && focus) {
        focus(props.points || props.point);
      }
    }

    if (act.type === 'ui.select.group') {
      if (clickedRef.current !== act.clicked) {
        setClicked(act.clicked);
      }

      if (act.indirect === false) {
        zoom();
        focus(props.points || props.point);
      }
    }
  }, [zoom, focus]);
  return clicked;
};

exports.useSelection = useSelection;
var _default = {
  normalizeID: normalizeID,
  matchGroup: matchGroup,
  getClickedFromAction: getClickedFromAction,
  toArray: toArray,
  useSelection: useSelection
};
exports["default"] = _default;