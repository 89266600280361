import * as React from 'react'

import Switch from '@material-ui/core/Switch'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import { DebouncedInput, ListInput } from '@touchlay/frontend-base'

// this is specific to quiz answers
function EditItemAnswers ({ info, label, value, setData }) {
  const updateData = (val) => {
    setData({
      [label]: val,
    })
  }
  const handleAdd = () =>
    updateData([ ...value, { text: 'New Answer' } ])

  const handleDelete = (d) =>
    updateData(value.filter(v => v.text !== d.text))

  const handleSetCorrect = (ev, d) =>
    updateData(value.map(v => {
      return (v.text === d.text)
        ? { ...v, correct: ev.target.checked }
        : v
    }))

  const handleTextChange = (inputValue, d) => {
    updateData(value.map(v => {
      return (v.text === d.text)
        ? { ...v, text: inputValue }
        : v
    }))
  }

  return (
    <ListInput
      data={value}
      disabled={info?.disabled}
      emptyText={'You have not added any answers to this question yet.'}
      name={label}
      nameKey={'text'}
      onAdd={() => handleAdd()}
      plural='Answers'
      primaryActions={(d) => (
        <div style={{ marginRight: 90 }}>
          <DebouncedInput
            disabled={info?.disabled}
            onChange={(value) => handleTextChange(value, d)}
            value={d.text}
          />
        </div>
      )}
      secondaryActions={(d) => ([
        <Tooltip key='select-correct' title={'select if answer is correct'}>
          <Switch
            checked={!!d.correct}
            disabled={info?.disabled}
            onChange={(ev) => handleSetCorrect(ev, d)}
          />
        </Tooltip>,
        <Tooltip key='delete-answer' title={'delete answer'}>
          <IconButton
            disabled={info?.disabled}
            onClick={() => handleDelete(d)}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>,
      ])}
    />
  )
}

export default EditItemAnswers
