import * as React from 'react'
import Autocomplete from '@material-ui/core/Autocomplete'
import TextField from '@material-ui/core/TextField'

import { listValidItems } from '../treeUtils'
import { asListItem } from './utils'

export const EditItemAutocomplete = asListItem(({ info, label, value, values, setData }) => {
  return (
    <Autocomplete
      disabled={info?.disabled}
      fullWidth
      onChange={(e, element) => {
        if (!element) {
          setData({
            [label]: undefined,
          })
        } else {
          setData({
            [label]: element.value,
          })
        }
      }}
      options={values}
      renderInput={(params) => (
        <TextField {...params} />
      )}
      value={values.find(v => v.value === value) || null}
    />
  )
})

export function EditItemAutocompleteId (props) {
  const values = listValidItems(props.treeData).map(v => ({ label: v, value: v }))
  return <EditItemAutocomplete {...props} values={values} />
}

export default EditItemAutocomplete
