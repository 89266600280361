import React from 'react'

import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import { Navigation, Footer, ErrorSnackbar } from '@touchlay/frontend-base'
import CssBaseline from '@material-ui/core/CssBaseline'
import ErrorContainer from './ErrorContainer'

// App component that wrapps around all the other components
// the params prop is needed for the Navigation
const App = ({ children, params, editor = false, navigation = true, errorSnackbar = true }) => (
  <DndProvider backend={HTML5Backend}>
    <CssBaseline />
    <div className='appContainer'>
      {navigation && <Navigation basePath='/presentation' params={params} />}
      <div className={editor ? 'editorContainer' : 'contentContainer'}>
        <ErrorContainer>
          {children}
        </ErrorContainer>
      </div>
      {!editor && <Footer />}
      {errorSnackbar && <ErrorSnackbar />}
    </div>
  </DndProvider>
)

export default App
