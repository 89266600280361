"use strict";

function scanNumbers(str, count) {
  if (count <= 0) return [str]; // last element in array -> final string

  var num = parseFloat(str);
  var numLength = num.toString().length;
  var nextCount = count - 1; // if last argument: no separator (0), otherwise 1 character

  var separatorLength = nextCount <= 0 ? 0 : 1;
  var nextStr = str.slice(numLength + separatorLength);
  return [num].concat(scanNumbers(nextStr, count - 1));
}

function scanner(d, state, debug) {
  if (d.length === 0) {
    // we finished
    if (debug) console.log('[scanner] finished');
    return state;
  }

  var c = d.slice(0, 1);
  var rest = d.slice(1);

  if (debug) {
    console.log('[scanner] state', JSON.stringify(state));
    console.log('[scanner] read', c);
  }

  if (c === ' ') {
    // ignore space
    return scanner(rest, state, debug);
  }

  var op;
  var nextStr;

  if (c.toLowerCase() === 'm' || c.toLowerCase() === 'l') {
    // operations with 2 arguments
    var numbers = scanNumbers(rest, 2);
    var e = numbers.pop();
    nextStr = typeof e === 'string' && e || '';
    op = [c].concat(numbers);
  } else if (c.toLowerCase() === 'c') {
    // operations with 6 arguments
    var _numbers = scanNumbers(rest, 6);

    var _e = _numbers.pop();

    nextStr = typeof _e === 'string' && _e || '';
    op = [c].concat(_numbers);
  } else if (c.toUpperCase() === 'Z') {
    // opeartion without argument (end operation)
    nextStr = rest;
    op = ['Z'];
  } else {
    throw new Error('unknown operation ' + c);
  }

  if (debug) {
    console.log('[scanner] scanned operation', op);
    console.log('[scanner] next string', nextStr);
    console.log('[scanner] ---');
  }

  return scanner(nextStr, state.concat([op]), debug);
}

function parseSvgPath(d, debug) {
  var cleanedStr = d.replace(/\s/g, ''); // remove all whitespace characters

  if (debug) console.log('[main] cleaned path', d);
  var scannedPath = scanner(cleanedStr, [], debug);
  if (debug) console.log('[main] scanned path', scannedPath);
  return scannedPath;
}

module.exports = parseSvgPath;