import React from 'react'

import slugify from 'slugify'

import {
  changeNodeAtPath, removeNodeAtPath, addNodeUnderParent, find,
  toggleExpandedForAll, insertNode,
} from 'react-sortable-tree'

import { runExpression } from '@touchlay/utils'
import { filterByView } from '@touchlay/renderer/dist/defaultMaps'

import Tooltip from '@material-ui/core/Tooltip'

import MenuHeader from './logos/MenuHeader.svg'
import MenuHeaderIcon from './logos/MenuHeaderIcon.svg'
import LineBreak from './logos/LineBreak.svg'
import LineBreakIcon from './logos/LineBreakIcon.svg'
import MenuItem from './logos/MenuItem.svg'
import MenuItemIcon from './logos/MenuItemIcon.svg'
import MenuLink from './logos/MenuLink.svg'
import MenuLinkIcon from './logos/MenuLinkIcon.svg'
import GridView from './logos/GridView.svg'
import GridViewIcon from './logos/GridViewIcon.svg'
import ScatterView from './logos/ScatterView.svg'
import ScatterViewIcon from './logos/ScatterViewIcon.svg'
import ImageView from './logos/ImageView.svg'
import ImageViewIcon from './logos/ImageViewIcon.svg'
import MarkerView from './logos/MarkerView.svg'
import MarkerViewIcon from './logos/MarkerViewIcon.svg'
import MemoryView from './logos/MemoryView.svg'
import ToggleViewIcon from './logos/ToggleViewIcon.svg'
import ArticleIcon from './logos/ArticleIcon.svg'
import SlideShowView from './logos/SlideShowView.svg'
import SlideShowViewIcon from './logos/SlideShowViewIcon.svg'
import FullscreenView from './logos/FullscreenView.svg'
import FullscreenViewIcon from './logos/FullscreenViewIcon.svg'
import ImageIllustration from './logos/Image.svg'
import ImageIcon from './logos/ImageIcon.svg'
import MultiImageIcon from './logos/MultiImageIcon.svg'
import MultipleImages from './logos/MultipleImages.svg'
import Video from './logos/Video.svg'
import VideoIcon from './logos/VideoIcon.svg'
import Web from './logos/Web.svg'
import WebIcon from './logos/WebIcon.svg'
import PDF from './logos/PDF.svg'
import PDFIcon from './logos/PDFIcon.svg'
import TextIllustration from './logos/Text.svg'
import TextIcon from './logos/TextIcon.svg'
import AtomIcon from './logos/AtomIcon.svg'
import QuizLevelIcon from './logos/QuizLevelIcon.svg'
import QuizCategoryIcon from './logos/QuizCategoryIcon.svg'
import QuizQuestionIcon from './logos/QuizQuestionIcon.svg'
import MemoryViewIcon from './logos/MemoryViewIcon.svg'

export const getNodeKey = ({ node }) => node.id

const search = id => ({ node }) => node.id === id

export const findNode = (id, treeData) => {
  if (!id) return {}
  const { matches } = find({ treeData, getNodeKey, searchMethod: search(id) })
  const [match] = matches || []
  if (!match) return { err: `Element with ID ${id} not found` }
  return match
}

/*
  findNodeRec
    finds node by id (recursively) - using `norm` to normalize ids
    e.g. to match `abc` with `abc-2`

    this function is needed (and we don't use a standard lookup from rst)
    so we keep track of subViews which are a bit weird in structure
*/
const findNodeRec = (id, treeData, norm = (x) => x) => {
  const inRoot = treeData.find(n => norm(n.id) === id)
  if (inRoot) return inRoot

  const inSubViews = treeData.reduce((res, n) => {
    if (res) return res
    if (Array.isArray(n.subViews)) {
      const node = findNodeRec(id, n.subViews, norm)
      if (node) return node
    }
    return res
  }, false)
  if (inSubViews) return inSubViews

  const inChildren = treeData.reduce((res, n) => {
    if (res) return res
    if (Array.isArray(n.children)) {
      const node = findNodeRec(id, n.children, norm)
      if (node) return node
    }
    return res
  }, false)
  if (inChildren) return inChildren

  return false
}

export const findNodeButAlsoInSubViews = (id, treeData, norm = (x) => x) => {
  if (!id || !treeData) return
  return findNodeRec(id, treeData, norm)
}

export const updateNode = (treeData, path, newNode) => changeNodeAtPath({
  treeData,
  path,
  getNodeKey,
  newNode,
})

export const deleteNode = (treeData, path) => removeNodeAtPath({
  treeData,
  path,
  getNodeKey,
  ignoreCollapsed: false,
})

export const addNode = (treeData, parentKey, newNode) => addNodeUnderParent({
  treeData,
  parentKey,
  getNodeKey,
  newNode,
  ignoreCollapsed: false,
  expandParent: true,
})

export const addNodeAtRoot = (treeData, newNode) => insertNode({
  treeData,
  depth: 0,
  minimumTreeIndex: 0,
  getNodeKey,
  newNode,
})

export const expandAll = (treeData, expanded) => toggleExpandedForAll({
  treeData,
  expanded,
})

export const guaranteeUserID = (id) => {
  if (id.endsWith('$user')) {
    return id // main value inside user which is linked by the main source-ref
  }
  if (!id.startsWith('usr$')) {
    return 'usr$' + id
  }
  return id
}
export const guaranteeMainID = (id) => {
  if (id.endsWith('$user')) {
    console.warn('what the fuck? - impossible state')
    return id // what the fuck is going on?
  }
  if (id.startsWith('usr$')) {
    return id.substr('usr$'.length)
  }
  return id
}

/*
  this is a stateful "fixing function"
  it needs to be stateful as we need to change the route
  the route change stuff is done with a callback on state, where it is processed by the caller
  the idea is that the function is actually pure in a sort of twisted way
  this helps to basically separate the actual routing stuff where it belongs
*/
export const fixSplitPD = (split, currentState, setState) => {
  let st = currentState

  const proc = (pd, isUserSource) => {
    function go (x) {
      const id = isUserSource ? guaranteeUserID(x.id) : guaranteeMainID(x.id)
      const r = {
        ...x,
        id,
        ...(x?.content
          ? {
              content: goGen(x.content),
            }
          : {}),
      }
      if (x?.id !== r?.id && st?.id === x?.id) {
        // this should only match once, unless there was a clash somewhere then we're fucked anyway
        st = { ...st, id }
      }
      return r
    }
    function goGen (xs) {
      if (Array.isArray(xs)) {
        return xs.map(go)
      } else {
        return go(xs)
      }
    }
    return goGen(pd)
  }
  const res = {}
  const splitEntries = Object.entries(split)
  for (const [ sourceLink, s ] of splitEntries) {
    const { pd } = s
    res[sourceLink] = {
      ...s,
      pd: proc(pd, sourceLink !== '#default#'),
    }
  }
  if (st !== currentState) {
    setState(st)
  }
  return res
}

export const PDtoTree = (rootPD, localState = {}) => {
  const solvedReferences = new Set()
  const int$augment = (obj) => {
    const expanded = (localState?.expanded || {})[obj.id]
    return {
      ...obj,
      expanded: !!expanded,
    }
  }
  const go = (pd, _source, augment = int$augment) => {
    return pd.flatMap(p => {
      let _pdSource = _source
      let _pdSourceContent = null
      let obj = p
      if (p?.type === 'ref') {
        console.log('references not supported')
        return []
      }
      if (p?.type === 'source-ref' && p?._sourceLink) {
        // there's going to be:
        // sourceLink (which simply points to where it should be, if available it means user source)
        // _template (which provides a default value)
        // the $user is added to sourceLink to guarantee *no* collisions
        // `id` in obj/rest would override the sourceLink$user value internally
        const pointerTo = `${p._sourceLink}$user`
        const elemIx = rootPD.findIndex((x) => x.id === pointerTo)
        const { type, _sourceLink, _template, content, ...rest } = obj
        let adaptedTemplate = go([_template], {
          kind: 'user',
          link: p._sourceLink,
        }, v => v)
        adaptedTemplate = (adaptedTemplate && adaptedTemplate[0]) ? adaptedTemplate[0] : _template
        _pdSourceContent = {
          kind: 'user',
          link: p._sourceLink,
        }
        if (elemIx >= 0) {
          /* we do this ordering to guarantee _template isn't overriden by accident */
          obj = { ...rootPD[elemIx], _sourceLink, _modified: true, _template: adaptedTemplate, ...rest }
          solvedReferences.add(pointerTo)
        } else {
          obj = { ..._template, _sourceLink, _template: adaptedTemplate, ...rest }
        }
      }

      /* we remove the disabled tag added by the reference stuff */
      const { content, disabled, ...data } = obj
      if (!_pdSource && obj._source) {
        _pdSource = obj._source
      }
      if (typeof content === 'string') {
        return [augment({ ...data, _source: _pdSource, children: [{ type: 'text', title: content }] })]
      } else if (Array.isArray(content)) {
        return [augment({ ...data, _source: _pdSource, children: go(content, _pdSourceContent || _pdSource) })]
      } else if (content) {
        console.warn('Unknown content detected:', content)
        return [p]
      }
      return [augment({ ...obj, _source: _pdSourceContent || _pdSource })]
    })
  }
  /* we remove the elements that had its reference resolved, because it means they are now shown somewhere else along the tree */
  return go(rootPD).filter((p) => !solvedReferences.has(p.id))
}

/*
  Things this function does:
    - makes sure that no $user ID is assigned to a non-user item
    - makes sure that an $user ID is assigned to an user item
    - convert from tree structure to PD
  Assumptions it does:
    - there is only ONE child out of an user item
      (if broken: the same ID will be generated for each child)
  TODO:
    - Unify with SplitPD for improved performance, as we're doing 2 full traversals of the PD tree
      ... trashing the GC due to the stupid number of allocations, and we're not in Haskell to abuse it that badly :P
*/
export const TreeToPD = (fullTree) => {
  const newIds = []
  const localState = {
    expanded: {},
  }

  const go = (tree, parent = null, noExpanded = false) => {
    const pd = tree.map(t => {
      const { children, expanded, ...data } = t
      if (!noExpanded) {
        localState.expanded[data.id] = expanded
      }
      if (parent?.sourceLink) {
        data.id = `${parent.sourceLink}$user`
      } else if (data.id?.endsWith('$user')) {
        data.id = generateID(`${data.type}-${generateID()}`, fullTree, newIds)
        newIds.push(data.id)
      }
      if (data._template) {
        const templ = go([data._template], t, true)
        data._template = templ && templ[0] ? templ[0] : data._template
      }
      if (children) {
        return { ...data, content: go(children, t) }
      }
      if (data.type === 'text') {
        return data.title
      }
      return t
    })
    if (pd.length === 1 && typeof pd[0] === 'string') {
      return pd[0]
    }
    return pd
  }
  return [ go(fullTree), localState ]
}

/*
  this method should take the PD exported by TreeToPD and:
  - clean any remaining source(s) left around
  - split it into the multiple sources
*/
export const SplitPD = (fullPD) => {
  const defaultSource = '#default#' // always first one
  const sourcePD = {}

  const addToPD = (source, pd) => {
    if (Array.isArray(sourcePD[source]?.pd)) {
      sourcePD[source].pd.push(pd)
    } else {
      sourcePD[source] = {
        pd: [pd],
      }
    }
  }

  const processItem = (source, pd, byRef) => {
    // when executing processItem, the pd should be a top-level item that should be added to the source PD, while the
    const go = (elem) => {
      if (elem && typeof elem === 'object') {
        const { id, type, ...rest } = elem
        /*
          if a _sourceLink is provided - this item is actually an user component
        */
        if (rest._sourceLink) {
          /*
            the process here is to:
            1- identify if the template is used
            2- if it is used, ignore the thing, reference resolution will take core of it
            3- if it isn't used, implement it as a source-ref + user component
              the reference will have the format
              {
                type: 'source-ref',
                _sourceLink: 'abcxyz',
                _template: {...},
                id: XYZ,
                disabled: false,
              }
              with the reference created having id `abcxyz$user` in this case (if changed)
          */
          const { id: _idRepeat, _sourceLink, locked, _template, _modified, ...restInternal } = elem
          if (_modified || JSON.stringify(restInternal.content) !== JSON.stringify(_template.content)) {
            processItem(_sourceLink, { type, id: `${_sourceLink}$user`, ...restInternal }, true)
          }
          return {
            type: 'source-ref',
            _sourceLink: rest._sourceLink,
            _template: rest._template,
            id,
            disabled: false,
          }
        }
        const noContent = type === 'source-ref'
        if (noContent &&
            (
              (Array.isArray(rest.content) && rest.content.length === 0) ||
              rest.content === null || rest.content === undefined
            )) {
          return {
            ...elem,
            _source: null,
          }
        }
        if (Array.isArray(rest.content)) {
          return {
            ...elem,
            _source: null,
            content: rest.content.map(go),
          }
        }
        return {
          ...elem,
          content: go(elem.content),
          _source: null,
        }
      }
      return []
    }
    let procPD = go(pd)
    if (byRef && procPD) {
      procPD = { ...procPD, disabled: true }
    }
    addToPD(source, procPD)
  }

  fullPD.forEach((x) => processItem(defaultSource, x))

  return sourcePD
}

/*
  getDetails({ node }) => null | ({
    image: Abc,
    title: 'XXX',
    icon: AbcIcon,
    color: '#aabbcc',
    template: {
      ///
    },
    allowedUnderParent: ({ parent }) => boolean
    beta,
  })

  const viewNodes = {
    viewType: [{
      title: 'XXX',
      ...
    }]
  }
  const normalNodes = (same structure as viewNodes)

*/

const neverAllow = () => false
const allowViews = (views) => (parent) =>
  !!views.find(x => x === parent?.view)

// vT = View Types
const vT = {
  GridView: 'GridView',
  SlideShowView: 'SlideShowView',
  MarkerView: 'MarkerView',
  FullscreenView: 'FullscreenView',
  ScatterView: 'ScatterView',
  ImageView: 'ImageView',
  ToggleView: 'ToggleView',
  MemoryView: 'MemoryView',
}

const nodeTypes = {
  atom: [{
    title: 'Atom',
    canPlaceNode: neverAllow,
    icon: AtomIcon,
  }],
  'menu.item': [{
    image: MenuItem,
    title: 'Menu Item',
    icon: MenuItemIcon,
    color: '#81c784',
    template: {
      type: 'menu.item',
      title: 'New Item',
    },
  }],
  'menu.link': [{
    image: MenuLink,
    title: 'Menu Link',
    icon: MenuLinkIcon,
    color: '#81c784',
    template: {
      type: 'menu.link',
      title: 'New Link Item',
      url: 'https://example.com',
    },
  }],
  'menu.header': [{
    image: MenuHeader,
    title: 'Menu Header',
    icon: MenuHeaderIcon,
    color: '#4fc3f7',
    template: {
      type: 'menu.header',
      title: 'New Header',
      variant: 'h3',
    },
  }],
  linebreak: [{
    image: LineBreak,
    title: 'Line Break',
    icon: LineBreakIcon,
    template: {
      type: 'linebreak',
      lineHeight: 0,
    },
  }],
  text: [{
    title: 'Text',
    color: '#d7ccc8',
    canPlaceNode: neverAllow,
    icon: TextIcon,
    image: TextIllustration,
    // TODO: add more
  }],
  md: [{
    title: 'Markdown Text',
    color: '#d7ccc8',
    canPlaceNode: neverAllow,
    icon: TextIcon,
    image: TextIllustration,
  }],
  richtext: [{
    title: 'Formatted Text',
    color: '#d7ccc8',
    icon: TextIcon,
    image: TextIllustration,
    template: {
      type: 'richtext',
      text: '{"blocks":[{"key":"12jlq","text":"This is an example text with italic and bold formatting!","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":29,"length":6,"style":"ITALIC"},{"offset":40,"length":4,"style":"BOLD"}],"entityRanges":[],"data":{}}],"entityMap":{}}',
    },
  }],
  article: [{
    title: 'Card',
    icon: ArticleIcon,
    canPlaceNode: neverAllow,
    // template: {
    //   type: 'article',
    //   title: 'New Article',
    //   icon: 'Star',
    // },
  }],
  image: [{
    title: 'Image',
    image: ImageIllustration,
    icon: ImageIcon,
    template: {
      type: 'image',
    },
  }],
  group: [
    {
      title: 'Multiple Images',
      canPlaceNode: allowViews([ vT.ScatterView, vT.SlideShowView, vT.ImageView, vT.GridView, vT.MarkerView ]),
      check: (node) => node.kind === 'image',
      icon: MultiImageIcon,
      image: MultipleImages,
      /* template: {
        type: 'group',
        kind: 'image',
        content: [],
      }, */
    }, {
      title: 'Multiple Media',
      canPlaceNode: (parent) => parent.view !== vT.FullscreenView,
      check: (node) => node.kind === 'media',
      icon: MultiImageIcon, // find better icon,
      image: MultipleImages,
      template: {
        type: 'group',
        kind: 'media',
        content: [],
      },
    },
  ],
  video: [{
    title: 'Video',
    icon: VideoIcon,
    image: Video,
    template: {
      type: 'video',
      autoplay: true,
    },
  }],
  pdf: [{
    title: 'PDF',
    icon: PDFIcon,
    image: PDF,
    template: {
      type: 'pdf',
    },
  }],
  web: [{
    title: 'Embedded Website',
    icon: WebIcon,
    image: Web,
    canPlaceNode: (x) => x?.view !== vT.ScatterView,
    template: {
      type: 'web',
    },
  }],
  'quiz.level': [{
    title: 'Quiz Level',
    icon: QuizLevelIcon,
    // TODO: add template so that we can add new quiz items
  }],
  'quiz.category': [{
    title: 'Quiz Category',
    icon: QuizCategoryIcon,
    // TODO: add template so that we can add new quiz items
  }],
  'quiz.question': [{
    title: 'Quiz Question',
    icon: QuizQuestionIcon,
    // TODO: add template so that we can add new quiz items
  }],
}

const views = [
  {
    view: 'GridView',
    image: GridView,
    icon: GridViewIcon,
    title: 'Grid Layout',
    template: {
      type: 'view',
      view: 'GridView',
      content: [],
    },
  },
  {
    view: 'MarkerView',
    image: MarkerView,
    icon: MarkerViewIcon,
    title: 'Interactive Graphic',
    beta: true,
    template: {
      type: 'view',
      view: 'MarkerView',
      backgroundColor: 'transparent',
      content: [],
    },
  },
  {
    view: 'ImageView',
    image: ImageView,
    icon: ImageViewIcon,
    title: 'Interactive Graphic',
  },
  {
    view: 'SlideShowView',
    image: SlideShowView,
    icon: SlideShowViewIcon,
    title: 'Gallery',
    template: {
      type: 'view',
      view: 'SlideShowView',
      content: [],
      options: { thumbnails: true },
    },
  },
  {
    view: 'FullscreenView',
    image: FullscreenView,
    icon: FullscreenViewIcon,
    title: 'Fullscreen',
    template: {
      type: 'view',
      view: 'FullscreenView',
      content: [],
    },
  },
  {
    view: 'MemoryView',
    image: MemoryView,
    icon: MemoryViewIcon,
    title: 'Memory',
    template: {
      type: 'view',
      view: 'MemoryView',
      cards: [],
      frontImageUrl: false,
    },
  },
  {
    view: 'ToggleView',
    image: ToggleViewIcon,
    icon: ToggleViewIcon,
    title: 'Multiple Layouts',
    // TODO: add template when editing sub-views actually works
  },
  {
    view: 'ScatterView',
    image: ScatterView,
    icon: ScatterViewIcon,
    title: 'Scatter',
    template: {
      type: 'view',
      view: 'ScatterView',
      options: {
        defaultSpringProperties: { width: '300px' },
        gestureProperties: {
          pinch: { scaleBounds: { min: 0.5, max: 2 } },
        },
      },
      content: [],
    },
  },
]

const flatNodes = (xs) => {
  const res = []
  for (const x of xs) {
    for (const y of x[1]) {
      res.push({ type: x[0], node: y })
    }
  }
  return res
}
export const getPossibleChildren = (parent, { presentation } = {}) => {
  // check for filter statements to show possible nodes for current parent
  let filter = parent.filter
  if (typeof filter === 'undefined') {
    filter = filterByView(parent.view)
  }

  const nodes =
    (parent.type === 'view'
      ? flatNodes(Object.entries(nodeTypes))
      : views.map(v => ({ type: v.view, node: v })))
      .filter(x => x.node?.canPlaceNode ? x.node.canPlaceNode(parent) : true)
      .filter(x => filter ? runExpression({ ...(x.node?.template || {}), type: x.type }, filter) : true)

  if (presentation?.meta?.allowedTypes) {
    return nodes.filter(node => presentation.meta.allowedTypes.includes(node.type)).map(x => x.node)
  } else {
    return nodes.map(x => x.node)
  }
}

const getView = (node) => (
  views.find(x => x.view === node.view && (x.check ? x.check(node) : true))
)
const getNode = (node) => (
  nodeTypes[node.type]?.find(x => x.check ? x.check(node) : true)
)
export const canPlaceNode = (parent, node) => {
  // check for filter statements to show possible nodes for current parent
  let filter = parent.filter
  if (typeof filter === 'undefined') {
    filter = filterByView(parent.view)
  }
  if (filter && !runExpression(node, filter)) {
    return false
  }
  const nodeDef = parent.type === 'view' ? getView(node) : getNode(node)
  if (nodeDef?.canPlaceNode && !nodeDef.canPlaceNode(parent)) {
    return false
  }
  // big assumption - if we haven't actively blacklisted it, it should be valid
  return true
}

export const resolveType = (node) => {
  const { type, view } = node
  if (view) {
    const matchedView = getView(node)
    return matchedView || { title: view, color: '#ba68c8' } // view color
  }
  const nodeDef = getNode(node)
  if (nodeDef) return nodeDef
  return { title: type, color: '#cfd8dc' } // default color
}

export const resolveNodeName = (node) => {
  return resolveType(node)?.title || node?.view || node?.type || node?.subType
}

export const NodeType = (node) => {
  const { title, icon, color } = resolveType(node)
  if (icon) {
    return (
      <Tooltip title={title}>
        <img alt={title} src={icon} style={{ width: 40, height: 40, padding: '0.1em 0' }} />
      </Tooltip>
    )
  }

  return (
    <span style={{ backgroundColor: color, borderRadius: 4, padding: '0.5em' }}>
      {title}
    </span>
  )
}

const normalizeMain = guaranteeMainID

const findID = (slug, treeData, count) => {
  const newSlug = slug + '-' + count
  const node = findNodeButAlsoInSubViews(normalizeMain(newSlug), treeData, normalizeMain)
  if (node) {
    return findID(slug, treeData, count + 1)
  }
  return newSlug
}

export const listValidItems = (treeData) => {
  const ret = []
  const go = (tr) => {
    if (tr?.type === 'menu.item' && Array.isArray(tr.children) && tr.children.length > 0) {
      ret.push(tr.id)
    }
    if (Array.isArray(tr?.children)) {
      tr.children.forEach(go)
    }
  }
  if (Array.isArray(treeData)) {
    treeData.forEach(go)
  }
  return ret
}

export const generateID = (title, treeData, extraIds = [], options = {}) => {
  const hadUsr = options?.userNode
  if (title?.startsWith('usr$') && options?.userNode) {
    title = title.substr('usr$'.length)
  }
  const handle = (xs) => hadUsr ? 'usr$' + xs : xs
  if (typeof title !== 'string' || title.length === 0) {
    return handle(Math.random().toString(36).substr(2, 5))
  }
  const slug = slugify(title.replace(/\./g, '-'), { lower: true, strict: true })
  if (treeData) {
    const tempTree = [
      ...treeData,
      ...(extraIds.map((id) => ({
        id,
      }))),
    ]
    const node = findNodeButAlsoInSubViews(normalizeMain(slug), tempTree, normalizeMain)
    if (node) {
      const matches = title.match(/^(.*)-([0-9]+)$/)
      return handle(findID(matches ? matches[1] : slug, tempTree, matches ? Number(matches[2]) : 2))
    }
  }
  return handle(slug)
}
