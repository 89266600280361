
import * as React from 'react'
import { useRef, useEffect, useState } from 'react'
import {
  useActions,
} from '@touchlay/utils'
import HereIcon from '@material-ui/icons/Room'
import IconButton from '@material-ui/core/IconButton'
import { asListItem } from './utils'

const useTimer = (ms) => {
  const timeoutRef = useRef(null)
  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [])
  return (cb) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
    timeoutRef.current = setTimeout(() => {
      cb()
      timeoutRef.current = null
    }, ms)
  }
}
export const getImageParent = (parentNode) => {
  if (parentNode?.view === 'ImageView' || parentNode?.view === 'MarkerView') {
    return parentNode
  } else if (parentNode?.view === 'ToggleView' && Array.isArray(parentNode?.subViews)) {
    return parentNode.subViews.find(v => v.view === 'ImageView' || v.view === 'MarkerView')
  } else {
    return null
  }
}

// TODO: check if we're talking about the current point, somehow?
// - we can't have multiple EditItemPoints at once currently, as they'd all receive the same action
export const EditItemPoint = asListItem(({ info, label, value, node, setData, parentNode }) => {
  const imageParent = getImageParent(parentNode)
  const call = useTimer(100)
  const [ picking, setPicking ] = useState(false)

  const dispatch = useActions('#edititem-point#', (act) => {
    if (act.type === 'action' && act.name === 'imageview.point') {
      const [ x, y ] = act.point
      /* this is needed because the position the user clicks on is actually the top left corner of the marker */
      const correctionFactor = [ 96, 306 ]
      setData({
        [label]: [ x - correctionFactor[0], y - correctionFactor[1] ],
      })
      setPicking(false)
    }
    if (act.type === 'action' && act.name === 'markerview.point') {
      setData({
        [label]: act.point,
      })
      setPicking(false)
    }
  })
  if (!imageParent) {
    // this is an impossible case if check is used properly
    return null
  }
  const startPicking = () => {
    if (picking) {
      setPicking(false)
      dispatch({
        type: 'action',
        name: 'imageview.pick',
        pick: false,
      })
      dispatch({
        type: 'action',
        name: 'markerview.pick',
        pick: false,
      })
    } else {
      setPicking(true)
      dispatch({
        type: 'action',
        name: 'history.goto.id',
        id: imageParent.id,
      })
      call(() => {
        dispatch({
          type: 'action',
          name: 'imageview.pick',
          pick: true,
        })
        dispatch({
          type: 'action',
          name: 'markerview.pick',
          pick: true,
        })
      })
    }
  }

  return (
    <IconButton
      color={picking ? 'primary' : 'default'}
      disabled={info?.disabled}
      onClick={startPicking}
    >
      <HereIcon />
    </IconButton>
  )
})

export default EditItemPoint
