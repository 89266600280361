"use strict";

var _leaflet = require("leaflet");

/* eslint-disable camelcase */
var _oldRemove = _leaflet.Map.prototype.remove;

_leaflet.Map.prototype.remove = function () {
  console.warn('disabling events');
  this._disabledEvents = true;

  for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
    args[_key] = arguments[_key];
  }

  return _oldRemove.apply(this, args);
};
/*
  HACK:
    Overly blunt solution, avoids fire(s) dispatched on removed maps to do anything
*/


var _oldFire = _leaflet.Map.prototype.fire;

_leaflet.Map.prototype.fire = function () {
  if (this._disabledEvents) {
    return;
  }

  for (var _len2 = arguments.length, args = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
    args[_key2] = arguments[_key2];
  }

  return _oldFire.apply(this, args);
};
/*
  HACK:
    These two functions are called by PosAnimation, which is unaware of Map's current status.
    Therefore, by the time the function is called (e.g. a stop is requested) the leaflet div may already have been removed, and thus crash

  https://github.com/Leaflet/Leaflet/blob/32ea41baaa93adcefd34006a12ebe3b4ee7fda7d/src/map/Map.js#L330
*/


var _old_onPanTransitionEnd = _leaflet.Map.prototype._onPanTransitionEnd;

_leaflet.Map.prototype._onPanTransitionEnd = function () {
  if (this._disabledEvents) {
    return;
  }

  for (var _len3 = arguments.length, args = new Array(_len3), _key3 = 0; _key3 < _len3; _key3++) {
    args[_key3] = arguments[_key3];
  }

  return _old_onPanTransitionEnd.apply(this, args);
};

var _old_onPanTransitionStep = _leaflet.Map.prototype._onPanTransitionStep;

_leaflet.Map.prototype._onPanTransitionStep = function () {
  if (this._disabledEvents) {
    return;
  }

  for (var _len4 = arguments.length, args = new Array(_len4), _key4 = 0; _key4 < _len4; _key4++) {
    args[_key4] = arguments[_key4];
  }

  return _old_onPanTransitionStep.apply(this, args);
};