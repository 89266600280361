import * as React from 'react'
import { useRef, useCallback } from 'react'
import TreeView from '../TreeView'
import { formatLabel, withDefault } from './utils'
import ListSubheader from '@material-ui/core/ListSubheader'

const countItems = (items) => {
  if (!items) return 0
  return items.length + items.reduce(
    (acc, i) => acc + countItems(i.children),
    0
  )
}

export const EditItemTree = ({ info, extra: _extra, label, value, setData, openSubpage, options, treeData, node }) => {
  const extra = withDefault(_extra, {
    noButtons: false,
  })

  const containerRef = useRef(null)
  const scrollLeft = useCallback((amount) => {
    const treeView = containerRef?.current?.querySelector('.ReactVirtualized__Grid')
    if (treeView) {
      treeView.scrollLeft = amount
    }
  }, [containerRef.current])

  const visibleItems = countItems(value)
  const headerHeight = 48
  const itemHeight = 62
  const buttonsHeight = extra.noButtons ? 16 : 62
  const maxItems = 4
  // show maximum of 4 items
  const height = Math.min(visibleItems, maxItems) * itemHeight + headerHeight + buttonsHeight

  const style = {
    marginTop: '16px',
    overflowX: 'auto',
    overflowY: visibleItems > maxItems ? 'auto' : 'hidden',
  }
  const topLevel = {
    node,
  }

  return (
    // this ref is not used to inject html directly, so it should be fine
    // eslint-disable-next-line react-security/no-refs
    <div ref={containerRef} style={style}>
      <ListSubheader color='primary'>{info.name || formatLabel(label)}</ListSubheader>
      <TreeView
        disabled={info?.disabled}
        fullTreeData={treeData}
        height={height - (extra.noButtons ? 0 : 48)}
        openSubpage={openSubpage}
        options={extra}
        parentNode={node}
        scrollLeft={scrollLeft}
        setData={x => setData({ [label]: x })}
        topLevel={topLevel}
        treeData={value}
      />
    </div>
  )
}

export default EditItemTree
