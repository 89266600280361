"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = modificationsReducer;

var _actions = require("../actions");

var _utils = require("./utils");

function modificationsReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case _actions.PRESENTATION_MODIFICATIONS_SUCCESS:
      return (0, _utils.updateOrInsert)(state, action.result, 'presentationId');

    default:
      return state;
  }
}