"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getChildCenter = exports.getChild = exports["default"] = void 0;

var React = _interopRequireWildcard(require("react"));

var _utils = require("../map/utils");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

/* this function is type-unsafe, but it's fine to use because we ultimately check for it dynamically */
var getChild = function getChild(content) {
  var _children = content;
  var child = Array.isArray(_children) ? _children[0] : _children;
  if (!child) return null;else return child;
};

exports.getChild = getChild;

var getChildCenter = function getChildCenter(content, error) {
  var child = getChild(content);
  if (!child) return null;
  var children = child.props ? child.props : child;
  var ch = children && Array.isArray(children.point) ? children.point : children && Array.isArray(children.points) ? (0, _utils.getCenter)(children.points) : [0, 0];

  if (ch[0] === 0 && ch[1] === 0) {
    if (error) {
      throw new Error(error);
    } else {
      return null;
    }
  }

  return ch;
};

exports.getChildCenter = getChildCenter;
var _default = {
  getChildCenter: getChildCenter
};
exports["default"] = _default;