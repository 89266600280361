"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.createURL = void 0;

var createURL = function createURL(s) {
  return '/' + s.join('/');
};

exports.createURL = createURL;
var _default = {
  createURL: createURL
};
exports["default"] = _default;