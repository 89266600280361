"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.usePathFinder = exports.findCenter = exports.distancePoints = exports["default"] = void 0;

var _react = require("react");

var _touchlayPathFinder = _interopRequireDefault(require("touchlay-path-finder"));

var _utils = require("@touchlay/utils");

var _devMode = _interopRequireDefault(require("@touchlay/utils/devMode"));

var _utils2 = require("../utils");

var _utils3 = require("./utils");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e2) { throw _e2; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e3) { didErr = true; err = _e3; }, f: function f() { try { if (!normalCompletion && it["return"] != null) it["return"](); } finally { if (didErr) throw err; } } }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

var findCenter = function findCenter(arr) {
  var x = arr.map(function (x) {
    return x[0];
  });
  var y = arr.map(function (x) {
    return x[1];
  });
  var cx = (Math.min.apply(Math, _toConsumableArray(x)) + Math.max.apply(Math, _toConsumableArray(x))) / 2;
  var cy = (Math.min.apply(Math, _toConsumableArray(y)) + Math.max.apply(Math, _toConsumableArray(y))) / 2;
  return [cx, cy];
};

exports.findCenter = findCenter;

var distancePoints = function distancePoints(x, y) {
  var d1 = x[0] - y[0];
  var d2 = x[1] - y[1];
  return Math.sqrt(d1 * d1 + d2 * d2);
};

exports.distancePoints = distancePoints;

var distanceGeoJSON = function distanceGeoJSON(point1, point2) {
  var coords1 = point1.geometry.coordinates;
  var coords2 = point2.geometry.coordinates;
  var xs = 0;
  var ys = 0;
  xs = coords2[1] - coords1[1];
  xs = xs * xs;
  ys = coords2[0] - coords1[0];
  ys = ys * ys;
  return Math.sqrt(xs + ys);
};

var distance = function distance(a, b) {
  var x = a && a.point;
  var y = b && b.geometry && b.geometry.coordinates;
  return distancePoints(x, y);
};

function nearest(targetPoint, points) {
  var nearestPoint;
  points.features.forEach(function (pt) {
    var dist;

    if (!nearestPoint) {
      nearestPoint = pt;
      dist = distanceGeoJSON(targetPoint, pt);
      if (!nearestPoint.properties) nearestPoint.properties = {};
      nearestPoint.properties.distance = dist;
    } else {
      dist = distanceGeoJSON(targetPoint, pt);

      if (dist < (nearestPoint.properties ? nearestPoint.properties.distance : 0)) {
        nearestPoint = pt;
        if (!nearestPoint.properties) nearestPoint.properties = {};
        nearestPoint.properties.distance = dist;
      }
    }
  });

  if (nearestPoint) {
    delete nearestPoint.properties.distance;
  }

  return nearestPoint;
}

var pathsToGeoJSON = function pathsToGeoJSON(paths) {
  return {
    type: 'FeatureCollection',
    features: paths.map(function (p) {
      return {
        type: 'Feature',
        properties: {
          id: p.id
        },
        geometry: {
          type: 'LineString',
          coordinates: p.points
        }
      };
    })
  };
};

var usePathFinder = function usePathFinder() {
  /*
    copied from class component on the first refactor
  */
  var pd = (0, _utils.usePD)();
  var pdRef = (0, _react.useRef)(pd);
  pdRef.current = pd;
  /* layer resolution
    - maps an id to a layer
    - keeps cache of typical points (such as here dots or elevators)
     important function: `resolveLayer`
  */

  var resolveLayer = (0, _utils3.useCached)(pd, {}, function (id) {
    return pd.find(function (d) {
      if (d.type === 'layer') {
        if (d.content) {
          return d.content.find(function (c) {
            return c.id === id;
          });
        }
      }

      return false;
    });
  }, []);
  /* pathfinder initialization
    - maps layers to Pathfinder(s) instances
    function: `getFinder`
  */

  var getFinder = (0, _utils3.useCached)(pd, {
    alwaysCached: true,
    cacheID: function cacheID(pms) {
      return pms[0] && pms[0].id || '#no-layer#';
    }
  }, function (layer) {
    var internalLayerName = layer && layer.id || '#no-layer#';
    var pathfinderInit = (0, _utils.startPerf)('pathfinder:init', "Initialize pathfinder ".concat(internalLayerName, " instance"));

    try {
      var paths = null;

      if (layer && layer.id) {
        var layerObj = pdRef.current.find(function (x) {
          return x.type === 'layer' && x.id === internalLayerName;
        });

        if (!layerObj) {
          _devMode["default"].warn("pathfinder: layer ".concat(internalLayerName, " not found - something is off"), 'pathfinder');

          return null;
        }

        paths = layerObj.content && layerObj.content.filter(function (x) {
          return x.type === 'path';
        });
      } else {
        paths = pdRef.current.filter(function (x) {
          return x.type === 'path';
        });
      }

      if (!paths) {
        _devMode["default"].warn("pathfinder: no paths were found in layer=".concat(internalLayerName), 'pathfinder');

        return null;
      }

      var geoJSON = pathsToGeoJSON(paths);
      var finder = new _touchlayPathFinder["default"](geoJSON, {
        precision: 1,
        edgeDataReduceFn: function edgeDataReduceFn(a, p) {
          return {
            id: p.id
          };
        }
      });
      return finder;
    } finally {
      (0, _utils.stopPerf)(pathfinderInit);
    }
  });
  /* pathfinder utils
    functions:
      - getElement
      - findNearestPoint
  */

  var getElement = (0, _utils3.useCached)(pd, {
    cacheID: function cacheID(pms) {
      return (pms[0] && pms[0].id || '#no-layer#') + ' ' + pms[1];
    }
  }, function (layer, id) {
    var layerContent = layer && layer.content || pdRef.current;
    var element = layerContent && layerContent.find(function (pd) {
      return pd.id === id;
    });
    return element || null;
  });

  var findNearestPoint = function findNearestPoint(point, layer) {
    var pathFinder = getFinder(true, layer);
    if (!pathFinder) return;
    var _pathFinder$_graph = pathFinder._graph,
        vertices = _pathFinder$_graph.vertices,
        sourceVertices = _pathFinder$_graph.sourceVertices;
    var points = {
      type: 'FeatureCollection',
      features: Object.keys(vertices).filter(function (nodeName) {
        return Object.keys(vertices[nodeName]).length;
      }).map(function (nodeName) {
        var vertice = sourceVertices[nodeName];
        return {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: vertice
          }
        };
      })
    };
    return nearest({
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: point
      }
    }, points);
  };

  var findPathInternal = (0, _utils3.useCached)(pd, {}, function (nearestFrom, nearestTo) {
    var layer = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;

    var s = function s(obj) {
      if (Array.isArray(obj)) {
        return obj;
      } else if (obj && obj.geometry) {
        return obj.geometry.coordinates;
      } else if (obj && obj.point) {
        return obj.point;
      } else {
        return null;
      }
    };

    var nearestFromInt = s(nearestFrom);
    var nearestToInt = s(nearestTo);

    if (!nearestFromInt || !nearestToInt) {
      _devMode["default"].error('error obtaining from/to positioning in path-finder, normalization may have failed', 'pathfinder');
    } // $FlowFixMe


    var nearestFromAct = findNearestPoint(nearestFromInt, layer); // $FlowFixMe

    var nearestToAct = findNearestPoint(nearestToInt, layer);
    var res = {
      from: s(nearestFromAct),
      to: s(nearestToAct),
      path: []
    };

    if (res.from === null || res.to === null) {
      return _devMode["default"].error('error initializing positions in path-finder', 'pathfinder');
    }

    var pathFinder = getFinder(true, layer);

    if (!pathFinder) {
      return _devMode["default"].error('pathfinder unitialized', 'pathfinder');
    }

    var path = pathFinder.findPath(nearestFromAct, nearestToAct);

    if (path !== null) {
      res.path = path.path.map(_utils2.xy);
    }

    var ret = _objectSpread(_objectSpread({}, res), {}, {
      weight: path ? path.weight : undefined
    }); // $FlowFixMe


    return ret;
  });
  var findLinks = (0, _utils3.useCached)(pd, {
    alwaysCached: true
  }, function (fromLayer, toLayer) {
    var layerLinkPairs = fromLayer && fromLayer.content && Array.isArray(fromLayer.content) && toLayer && toLayer.content && Array.isArray(toLayer.content) && fromLayer.content.map(function (pdFrom) {
      if (pdFrom && pdFrom.meta && pdFrom.meta.link) {
        var pdFromLink = pdFrom.meta.link;
        var rest = toLayer.content.filter(function (pdTo) {
          return pdTo && pdTo.id === pdFromLink;
        }).map(function (x) {
          return x.meta.link;
        });
        return rest.map(function (r) {
          return [r, pdFromLink];
        });
      }

      return null;
    }).filter(function (x) {
      return x !== null;
    });

    if (!layerLinkPairs) {
      return [];
    }

    var ret = [];

    var _iterator = _createForOfIteratorHelper(layerLinkPairs),
        _step;

    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var xs = _step.value;

        var _iterator2 = _createForOfIteratorHelper(xs),
            _step2;

        try {
          for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
            var x = _step2.value;
            ret.push(x);
          }
        } catch (err) {
          _iterator2.e(err);
        } finally {
          _iterator2.f();
        }
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }

    return ret;
  });

  var findPath = function findPath(from, to, fromLayer, toLayer) {
    var pathFinderFrom = getFinder(true, fromLayer);
    if (!pathFinderFrom) return;
    var pathFinderTo = getFinder(true, toLayer);
    if (!pathFinderTo) return;

    if (!from) {
      return _devMode["default"].error('no starting point defined', 'pathfinder');
    }

    if (!to) {
      return _devMode["default"].error('no destination point defined', 'pathfinder');
    }

    var nearestFrom = findNearestPoint(from, fromLayer);
    var nearestTo = findNearestPoint(to, toLayer);

    if (!nearestFrom) {
      return _devMode["default"].error('no nearest point found (origin)', 'pathfinder');
    }

    if (!nearestTo) {
      return _devMode["default"].error('no nearest point found (destination)', 'pathfinder');
    }

    if (fromLayer === null && toLayer === null || fromLayer != null && toLayer != null && fromLayer.id === toLayer.id) {
      var singlePerf = (0, _utils.startPerf)('pathfinder:single', 'Same-layer PathFinder');
      var ret = findPathInternal(false, nearestFrom, nearestTo, toLayer);
      (0, _utils.stopPerf)(singlePerf);

      if (ret) {
        return {
          paths: [_objectSpread(_objectSpread({}, ret), {}, {
            layer: fromLayer && fromLayer.id || null
          })]
        };
      }
    } else {
      var fullPerf = (0, _utils.startPerf)('pathfinder:multi', 'ML: Full Pathfinding');

      try {
        var multiLinksPerf = (0, _utils.startPerf)('pathfinder:multi-links', 'ML: Link gathering');
        var links = findLinks(true, fromLayer, toLayer); // the first is the heuristic weight, the rest are intermediate values which are needed for the proper calculation

        var processedLinks = [];

        var _iterator3 = _createForOfIteratorHelper(links),
            _step3;

        try {
          for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
            var _step3$value = _slicedToArray(_step3.value, 2),
                _fromLink = _step3$value[0],
                _toLink = _step3$value[1];

            var _p = getElement(true, fromLayer, _fromLink);

            var _p2 = getElement(true, toLayer, _toLink);

            if (!_p || !_p2) {
              _devMode["default"].warn("error while processing link ".concat(_fromLink, " => ").concat(_toLink, ", ignoring"), 'pathfinder');

              continue;
            }

            var distanceToLink = distance(_p, nearestFrom);
            var distanceToEnd = distance(_p2, nearestTo);

            if (!distanceToLink || !distanceToEnd) {
              _devMode["default"].warn("missing distance ".concat(distanceToLink, ", ").concat(distanceToEnd, ", ").concat(_p, ", ").concat(nearestFrom, ", ").concat(_p2, ", ").concat(nearestTo), 'pathfinder');

              continue;
            }

            var heuristicWeight = distanceToLink + distanceToEnd;
            processedLinks.push([heuristicWeight, [_p, _p2], [_fromLink, _toLink]]);
          }
        } catch (err) {
          _iterator3.e(err);
        } finally {
          _iterator3.f();
        }

        (0, _utils.stopPerf)(multiLinksPerf);
        var sortPerf = (0, _utils.startPerf)('pathfinder:multi-sorts', 'ML: Sort');
        processedLinks.sort(function (a, b) {
          return a[0] - b[0];
        });
        (0, _utils.stopPerf)(sortPerf);
        var closestPair = null;

        for (var _i = 0, _processedLinks = processedLinks; _i < _processedLinks.length; _i++) {
          var _processedLinks$_i = _slicedToArray(_processedLinks[_i], 3),
              _processedLinks$_i$ = _slicedToArray(_processedLinks$_i[1], 2),
              p0 = _processedLinks$_i$[0],
              p1 = _processedLinks$_i$[1],
              _processedLinks$_i$2 = _slicedToArray(_processedLinks$_i[2], 2),
              fromLink = _processedLinks$_i$2[0],
              toLink = _processedLinks$_i$2[1];

          var searchPerf = (0, _utils.startPerf)('pathfinder:multi-search', 'ML: Proper Search'); // we only cache the first, as the second will (in theory) vary a lot

          var _path = findPathInternal(true, nearestFrom, p0, fromLayer);

          var _path2 = findPathInternal(false, p1, nearestTo, toLayer);

          if (!_path || !_path2) {
            _devMode["default"].error("path finding problem with ".concat(fromLink, "=").concat(toLink, ", ignoring"), 'pathfinder');

            continue;
          }

          if (!_path.weight || !_path2.weight) {
            _devMode["default"].error("error finding proper path [".concat(fromLink, "=>").concat(toLink, "], weight is unavailable (assuming unreachable path)"), 'pathfinder');

            continue;
          }

          var fullWeight = _path.weight + _path2.weight;
          (0, _utils.stopPerf)(searchPerf);
          closestPair = [[_path, _path2], fullWeight, [p0, p1], [fromLink, toLink]];
          break;
        }

        if (!closestPair) {
          return _devMode["default"].error('no link found between multi-layer findPath', 'pathfinder');
        }

        var path1 = closestPair[0][0];
        var path2 = closestPair[0][1];
        return {
          paths: [// TODO: get hiddenOpacity from respective layer here
          {
            from: false,
            to: false,
            path: [].concat(_toConsumableArray(path1.path), [(0, _utils2.xy)(closestPair[2][1].point)]),
            layer: fromLayer.id
          }, _objectSpread(_objectSpread({}, path2), {}, {
            path: [(0, _utils2.xy)(closestPair[2][1].point)].concat(_toConsumableArray(path2.path)),
            layer: toLayer.id
          })],
          pairs: closestPair[3]
        };
      } finally {
        (0, _utils.stopPerf)(fullPerf);
      }
    }

    return null;
  };

  return {
    resolveLayer: resolveLayer,
    findPath: findPath
  };
};

exports.usePathFinder = usePathFinder;
var _default = {
  findCenter: findCenter,
  distancePoints: distancePoints,
  usePathFinder: usePathFinder
};
exports["default"] = _default;