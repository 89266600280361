"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

var _typeof3 = require("@babel/runtime/helpers/typeof");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.normalizeID = exports.getPDProps = exports.applyVirtualPD = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _typeof2 = _interopRequireDefault(require("@babel/runtime/helpers/typeof"));

var _typeHelpers = require("./typeHelpers");

var _types = require("./types");

var devMode = _interopRequireWildcard(require("./devMode"));

var _excluded = ["type"],
    _excluded2 = ["type", "id", "_sourceLink", "content"],
    _excluded3 = ["type", "id", "_sourceLink", "_template"],
    _excluded4 = ["id", "type", "to"];

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof3(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it["return"] != null) it["return"](); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

var propsMap = {}; // TODO: handle atom's content properly (see: renderer/src/adapter.js:contentAdapter)

propsMap.atom = {};
propsMap.article = {
  title: _types.Types.String(),
  image: _types.Types.Optional(_types.Types.File('image/png')),
  description: _types.Types.Optional(_types.Types.Text())
};
propsMap.image = {
  title: _types.Types.Optional(_types.Types.String()),
  src: _types.Types.File('image/png')
};
propsMap.video = {
  title: _types.Types.Optional(_types.Types.String()),
  src: _types.Types.File()
};
propsMap.web = {
  title: _types.Types.Optional(_types.Types.String()),
  web: _types.Types.String()
};
propsMap.pdf = {
  title: _types.Types.Optional(_types.Types.String()),
  src: _types.Types.File()
};
propsMap['menu.header'] = {
  title: _types.Types.String(),
  description: _types.Types.Text()
}; // TODO: menu.item is too dynamic-y & it has got dynamic subtypes

propsMap['menu.item'] = {
  title: _types.Types.String(),
  description: _types.Types.Text()
};
propsMap.expandable = {
  title: _types.Types.String()
};

var getPDProps = function getPDProps(x) {
  if (propsMap[x]) {
    return propsMap[x];
  } else {
    return null;
  }
};

exports.getPDProps = getPDProps;

var normalizeID = function normalizeID(_parent) {
  for (var i = _parent.length - 1; i >= 0; i--) {
    if (_parent[i] === '_') {
      return _parent.slice(i + 1, _parent.length);
    }
  }

  return _parent;
}; // TODO: maybe consider re-doing this
// export const isPresentationData = (x: mixed) => isX<PresentationData>((reify: Type<PresentationData>), x)
// export const isAugmentedPresentationData = (x: mixed) => isX<AugmentedPresentationData>((reify: Type<AugmentedPresentationData>), x)
// export const isTLAugmentedPresentationData = (x: mixed) => isX<TLAugmentedPresentationData>((reify: Type<TLAugmentedPresentationData>), x)
// export const isPresentationDataList = (x: mixed) => isX<PresentationData[]>((reify: Type<PresentationData[]>), x)
// export const isAugmentedPresentationDataList = (x: mixed) => isX<AugmentedPresentationData[]>((reify: Type<AugmentedPresentationData[]>), x)
// export const isTLAugmentedPresentationDataList = (x: mixed) => isX<TLAugmentedPresentationData[]>((reify: Type<TLAugmentedPresentationData[]>), x)

/* helpers for applyVirtualPD */


exports.normalizeID = normalizeID;

var mapIgnore = function mapIgnore(f, xs) {
  var rs = [];

  var _iterator = _createForOfIteratorHelper(xs),
      _step;

  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var x = _step.value;

      try {
        var fx = f(x);

        if (Array.isArray(fx)) {
          for (var i = 0; i < fx.length; i++) {
            rs.push(fx[i]);
          }
        } else if (fx) {
          rs.push(fx);
        }
      } catch (e) {
        devMode.warn(e, 'pd');
      }
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }

  return rs;
};
/**
 * Process virtual presentation data in top-level presentation such as:
 *  - references
 *  - groups
 *
 * @param {TLAugmentedPresentationData} pds
 * @returns {PresentationData}
 */


var applyVirtualPD = function applyVirtualPD(pds) {
  var applyFn = function applyFn(fn, obj) {
    if (Array.isArray(obj)) {
      return obj.map(fn);
    } else if (obj && (0, _typeof2["default"])(obj) === 'object') {
      return fn(obj);
    } else {
      return null;
    }
  };
  /* compiled refers to reference-less presentation data, if it's there
     we know *for sure* it has been completely handled, and it can be directly copied.
  */


  var compiled = new Map(); // const compiled: { [ID]: PresentationData } = {}

  /*
    IDs we're currently compiling, used to detect loops
  */

  var processing = new Set();

  var solveRef = function solveRef(to, def, data) {
    /*  We do this with the goal of being capable of augmenting/replacing properties on existing types
      Such as id(s), style(s), etc
    */
    var type = data.type,
        remaining = (0, _objectWithoutProperties2["default"])(data, _excluded);
    var compiledRef;

    try {
      compiledRef = compile(normalizeID(to));
    } catch (e) {
      devMode.info(e, 'pd');
    }

    if (Array.isArray(compiledRef)) {
      return compiledRef.map(function (x) {
        return _objectSpread(_objectSpread(_objectSpread({}, x), remaining), {}, {
          id: "".concat(data.id, "#").concat(x.id)
        });
      });
    }

    if (compiledRef) {
      return _objectSpread(_objectSpread({}, compiledRef), remaining);
    } else {
      return def ? compileInternal(_objectSpread({}, def)) : null;
    }
  };

  var compileInternal = function compileInternal(_data) {
    var data = _objectSpread({}, _data);

    if (data.type === 'group') {
      var type = data.type,
          id = data.id,
          _sourceLink = data._sourceLink,
          content = data.content,
          remaining = (0, _objectWithoutProperties2["default"])(data, _excluded2);

      var addExtra = function addExtra(obj) {
        return _objectSpread(_objectSpread({}, obj), remaining);
      };

      if (Array.isArray(data.content)) {
        return mapIgnore(compileInternal, data.content).map(addExtra);
      } else if (data.content && (0, _typeof2["default"])(data.content) === 'object') {
        return addExtra(compileInternal(data.content));
      } else {
        return null;
      }
    }

    if (data.type === 'source-ref') {
      var _type = data.type,
          _id = data.id,
          _sourceLink2 = data._sourceLink,
          _template = data._template,
          _remaining = (0, _objectWithoutProperties2["default"])(data, _excluded3);

      var to = "".concat(_sourceLink2, "$user");

      var _addExtra = function _addExtra(obj) {
        return _objectSpread(_objectSpread({}, obj), _remaining);
      };

      return applyFn(_addExtra, solveRef(to, _template, _objectSpread({
        id: _id
      }, _remaining)));
    }

    if (data.type === 'ref') {
      /*
        We do this with the goal of being capable of augmenting/replacing properties on existing types
        Such as id(s), style(s), etc
      */
      var _id2 = data.id,
          _type2 = data.type,
          _to = data.to,
          _remaining2 = (0, _objectWithoutProperties2["default"])(data, _excluded4);

      var compiledRef;

      try {
        compiledRef = compile(normalizeID(data.to));
      } catch (e) {
        devMode.warn(e, 'pd');
      }

      if (Array.isArray(compiledRef)) {
        return compiledRef.map(function (x) {
          return _objectSpread(_objectSpread(_objectSpread({}, x), _remaining2), {}, {
            id: "".concat(_id2, "#").concat(x.id)
          });
        });
      }

      if (compiledRef) {
        return _objectSpread(_objectSpread({}, compiledRef), _remaining2);
      } else {
        return null;
      }
    }

    if (data.content != null) {
      // three major situations can arise if data.content exists:
      // it's a string, a presentation data or a presentation data array
      // (this is checked manually, and out of the type-checker due to a bug in Flow)
      if (Array.isArray(data.content)) {
        data.content = mapIgnore(compileInternal, data.content);
      } else if (data.content && (0, _typeof2["default"])(data.content) === 'object') {
        var internal = compileInternal(data.content);
        data.content = internal || [];
      } // string does not need to be handled

    }

    return data;
  };

  var compile = function compile(x) {
    var cachedValue = compiled.get(x);

    if (cachedValue) {
      return cachedValue;
    }

    if (processing.has(x)) {
      throw new _typeHelpers.TypeValidationError('Loop detected');
    }

    var data;

    var _iterator2 = _createForOfIteratorHelper(pds),
        _step2;

    try {
      for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
        var _pd = _step2.value;
        var pd = _pd;

        if (normalizeID(pd.id) === x) {
          data = pd;
          break;
        }
      }
    } catch (err) {
      _iterator2.e(err);
    } finally {
      _iterator2.f();
    }

    if (!data) {
      throw new _typeHelpers.TypeValidationError("Reference ".concat(x, " not found in presentation data"));
    } else {
      processing.add(x);
      var ro = compileInternal(data);
      compiled.set(x, ro);
      processing["delete"](x);
      return ro;
    }
  };

  var compileID = function compileID(x) {
    var rest = compile(normalizeID(x.id));

    if (Array.isArray(rest)) {
      return rest;
      /* if an array is returned, an id should not be overriden, as the work was done by the ref resolution */
    } else if (rest) {
      return _objectSpread(_objectSpread({}, rest), {}, {
        id: x.id
      });
    } else {
      return null;
    }
  };

  var ret = mapIgnore(compileID, pds);
  return ret;
};

exports.applyVirtualPD = applyVirtualPD;