import React from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { ConfigViewWrapper, TextEdit, DeleteButton } from '@touchlay/frontend-base'

import { useItem } from './helpers/utils'

import { getLayout, updateLayout, deleteLayout } from '@touchlay/frontend-base/dist/actions'

const Layout = () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const layout = useItem((x) => x.layouts, id, getLayout)

  // Define event handlers
  const handleNameChange = (value) => dispatch(updateLayout(layout._id, { name: value }))
  const handleDataChange = (value) => dispatch(updateLayout(layout._id, { data: JSON.parse(value) }))

  return (
    <ConfigViewWrapper subTitle={'Layout'} title={layout && layout.integration}>
      {/* edit name */}
      <TextEdit
        defaultValue={layout && layout.name}
        id={'layoutname'}
        key={layout && layout.name}
        label={'Name'}
        onChange={handleNameChange}
      /><br />

      {/* edit data */}
      <TextEdit
        defaultValue={layout && JSON.stringify(layout.data, null, 4)}
        fullWidth
        id={'layoutdata'}
        key={layout && JSON.stringify(layout.data)}
        label={'Data'}
        multiline
        onChange={handleDataChange}
      />
      <DeleteButton action={(x) => dispatch(deleteLayout(x))} id={layout && layout._id} />
    </ConfigViewWrapper>
  )
}

export default Layout
