import React, { useState, useMemo, useRef, useCallback, useEffect } from 'react'
import { useActions, useTrackValue } from '@touchlay/utils'
import LoadingList from '@touchlay/frontend-base/dist/components/LoadingList'
import { usePermissions, Permissions } from '@touchlay/frontend-base/dist/permissions'
import { PDtoTree, TreeToPD, SplitPD, findNode, fixSplitPD } from './treeUtils'

import EditView from './EditView'
import TreeView from './TreeView'
import { useUndo } from './undo'

/*
  map through the tree structure, not PD
  allows returning null to remove a node
*/
const mapT = (f, xs) => {
  if (Array.isArray(xs)) {
    return xs.flatMap(x => mapT(f, x))
  } else {
    const nx = f(xs)
    if (!nx) return []
    return [{
      ...nx,
      ...(nx?.children ? { children: mapT(f, nx.children) } : {}),
    }]
  }
}

const getFirstTime = () => {
  const firstTimeTrack = {}
  const firstTime = (v) => {
    if (firstTimeTrack[v.id]) return v.expanded
    else {
      firstTimeTrack[v.id] = v.expanded
      return true
    }
  }
  return firstTime
}
/*
  There's a pipeline between sources, presentation data and tree data
  With `modeLooks` we replace the steps from
    (PD, localStorage) => (Tree, internalState)
    (Tree, internalState) => (Sources, localStorage)
  When those are generic we can implement behavior such as:
    - ignore sources we don't have access to

  LocalStorage is persisted exclusively locally and can be wiped out at any moment
      - it should NOT be used for critical data, e.g. it's fine for cosmetic behavior
*/
const modeLooks = {
  normal: (opts) => {
    return {
      PDToTree: (data, localstate) => [ PDtoTree(data, localstate), null ],
      TreeToSources: (treeData, state) => {
        const [ fullPd, newState ] = TreeToPD(treeData)
        opts.saveLocalState(newState)
        return SplitPD(fullPd)
      },
    }
  },
  readOnly: (opts) => {
    const lock = xs => mapT((v) => {
      return {
        ...v,
        meta: {
          ...(v?.meta ? v.meta : {}),
          locked: true,
        },
      }
    }, xs)
    return {
      PDToTree: (data, localstate) => {
        return [ lock(PDtoTree(data, localstate)), null ]
      },
      TreeToSources: (treeData, state) => {
        const [ fullPd, newState ] = TreeToPD(treeData)
        opts.saveLocalState(newState)
        const split = SplitPD(fullPd)
        for (const k in split) {
          split[k].ignore = true
        }
        return split
      },
    }
  },
  userLock: (opts) => {
    const firstTime = getFirstTime()
    const lock = xs => mapT((v) => {
      if (v?._source?.kind === 'user' || v?._sourceLink) {
        return v
      }
      return {
        ...v,
        expanded: firstTime(v),
        meta: {
          ...(v?.meta ? v.meta : {}),
          locked: true,
        },
      }
    }, xs)

    return {
      PDToTree: (data, localstate) => {
        return [ lock(PDtoTree(data, localstate)), null ]
      },
      TreeToSources: (treeData, state) => {
        const [ fullPd, newState ] = TreeToPD(treeData)
        opts.saveLocalState(newState)
        const split = SplitPD(fullPd)
        if (split['#default#']) {
          split['#default#'].ignore = true
        }
        return split
      },
    }
  },
  userHidden: (opts) => {
    const firstTime = getFirstTime()
    const cachedSL = {}
    const hasSourceLink = (v) => {
      if (cachedSL[v.id]) return cachedSL[v.id]
      if (v?._sourceLink) return true
      const res = (Array.isArray(v?.children) ? v.children.some(hasSourceLink) : false)
      cachedSL[v.id] = res
      return res
    }
    const hide = xs => mapT((v) => {
      if (v?._source?.kind === 'main' && !hasSourceLink(v)) {
        return null
      }
      if (v?._source?.kind === 'user' || v?._sourceLink) {
        return v
      }
      return {
        ...v,
        expanded: firstTime(v),
        meta: {
          ...(v?.meta ? v.meta : {}),
          locked: true,
        },
      }
    }, xs)

    return {
      PDToTree: (data, localstate) => {
        return [ hide(PDtoTree(data, localstate)), null ]
      },
      TreeToSources: (treeData, state) => {
        const [ fullPd, newState ] = TreeToPD(treeData)
        opts.saveLocalState(newState)
        const split = SplitPD(fullPd)
        if (split['#default#']) {
          split['#default#'].ignore = true
        }
        return split
      },
    }
  },
}

const modes = {
  fullAccess: {
    type: 'full',
    look: 'normal',
  },
  userOnly: {
    type: 'user',
    look: 'userHidden',
    noTopLevelDrop: true,
  },
  readOnly: {
    type: 'read',
    look: 'readOnly',
    noTopLevelDrop: true,
  },
}

const topLevel = {
  Direct: {
    node: {
      type: 'direct', // internal type
    },
  },
  GridView: {
    node: {
      type: 'view',
      view: 'GridView',
    },
  },
}

const getToplevel = (layout) => {
  if (!layout) return null
  if (!layout.data) return null
  const topLevelRouting =
    layout.data.find(x => x.view === 'RoutingView')
  if (!topLevelRouting) return topLevel.Direct

  const gridView = topLevelRouting.content?.find(x => x.view === 'GridView')
  if (gridView && !gridView.content) return topLevel.GridView

  return topLevel.Direct
}

export default function DataEditor ({ data, style, presentation, layout, updateData, batchedUpdates, openSubpage, subpage, subid }) {
  const presentationId = presentation?._id
  const loadLocalState = () => {
    try {
      const stateStr = window.localStorage.getItem(`${presentationId}-localstate`)
      return JSON.parse(stateStr) || {}
    } catch (err) {}
    return {}
  }
  const saveLocalState = (newState) => {
    window.localStorage.setItem(`${presentationId}-localstate`, JSON.stringify(newState))
  }
  const hasPermission = usePermissions(presentation)
  const hasWriteUser = hasPermission(Permissions.WRITE_USER)
  const hasWrite = hasPermission(Permissions.WRITE)
  const defaultMode =
    hasWriteUser
      ? modes.userOnly
      : hasWrite
        ? modes.fullAccess
        : modes.readOnly

  const topLevel = getToplevel(layout)
  const [ mode, setMode ] = useState(defaultMode)

  const conversions = useMemo(() => (modeLooks[mode?.look] || modeLooks.normal)({
    loadLocalState,
    saveLocalState,
  }), [mode?.look])

  const changeMode = (mode) => {
    setMode(mode)
  }
  const [ treeData, convState ] = conversions.PDToTree(data, loadLocalState())

  const treeDataRef = useTrackValue(treeData)
  const subidRef = useTrackValue(subid)
  const dispatch = useActions('#dashboard-dataeditor#', (act) => {
    if (act.name === 'admin.data.new' && act.pdID) {
      openSubpage('add', act.pdID)
    }
    if ((act.from !== '#dashboard-dataeditor#' && act.name === 'history.push') || (act.name === 'admin.data.open')) {
      let id = act.pdID
      if (!id && act.url && act.url !== '/') {
        const urlProc = act.url.split('/').filter(x => x !== '')
        id = urlProc[urlProc.length - 1]
        if (!isNaN(id)) { /* hack to avoid trying it on gallery items and similars... */
          id = false
        }
      }
      if (id) {
        const { err, node } = findNode(id, treeDataRef.current)
        if (err || !node) {
          /* note wasn't found! */
          return
        }
        if (act.directID) {
          openSubpage('element', id)
        } else if (node && node.children && node.children[0] && node.children[0].type === 'view') {
          const elem = node.children[0]
          if (elem.view === 'FullscreenView' && elem.children && elem.children[0]) {
            openSubpage('element', elem.children[0].id)
          } else {
            openSubpage('element', node.children[0].id)
          }
        } else {
          openSubpage('element', id)
        }
      } else if (act.url === '/') {
        openSubpage()
      }
    }
    if (act.name === 'toggleview.switched') {
      const [ pdID, currentView ] = [ act.toId, act.toView ]
      const { err, node } = findNode(pdID, treeData)
      if (err || !node) {
        /* note wasn't found! */
        return
      }
      const subView = Array.isArray(node?.subViews) && node.subViews.find((x) => x?.id === currentView)
      // esteban's sync debugger:
      // console.warn({ pdID, currentView, subView, act, node })
      if (subView && (subView.view === 'ImageView' || subView.view === 'MarkerView')) {
        dispatch({
          type: 'action',
          name: 'history.goto.id',
          id: subidRef.current,
        })
      }
    }
  }, [])

  const syncSubid = (id) => {
    if (id) {
      const { err, node, path } = findNode(id, treeDataRef.current)
      if (err || !node) {
        /* node not found */
        return
      }
      const { node: parent } = findNode(path[path.length - 2], treeDataRef.current)
      // console.log({ behavior: 'syncing', id, node, parent })
      /* select menu.items/points directly if they're part of a MarkerView */
      if (node.type === 'view' || (parent && parent.type === 'view' && parent.view === 'MarkerView')) {
        dispatch({
          type: 'action',
          name: 'history.goto.id',
          id,
        })
        return
      } else if (parent) {
        dispatch({
          type: 'action',
          name: 'history.goto.id',
          id: parent.id,
        })
        return
      }
    }
    dispatch({
      type: 'action',
      name: 'history.push',
      url: '/',
    })
  }

  const openSubpageSynchronized = (subpage, subid, replace) => {
    if (subpage === 'element') {
      syncSubid(subid)
    }
    openSubpage(subpage, subid, replace)
  }

  const openBatchRef = useRef(null)
  const openSubpageSyncBatch = (subpage, subid, replace) => {
    openBatchRef.current = [ subpage, subid, replace ]
  }
  const runSubpageBatch = () => {
    if (openBatchRef.current) {
      openSubpageSynchronized(...openBatchRef.current)
    }
    openBatchRef.current = null
  }

  useEffect(() => {
    if (subpage === 'element') {
      syncSubid(subid)
    }
  }, [presentation]) // eslint-disable-line

  useEffect(() => {
    if (!subpage) {
      syncSubid(subid)
    }
  }, [ presentation, subpage, subid ]) // eslint-disable-line

  const containerRef = useRef(null)
  const scrollLeft = useCallback((amount) => {
    const treeView = containerRef?.current?.querySelector('.ReactVirtualized__Grid')
    treeView.scrollLeft = amount
  }, [containerRef])

  const manualUpdate = useCallback((splitFixed, cb) => {
    batchedUpdates(({ _internal, setData, process }) => {
      const proc = new Set()
      const splitEntries = Object.entries(splitFixed)
      for (const [ sourceLink, s ] of splitEntries) {
        const { pd, ignore } = s
        if (sourceLink === '#default#') {
          if (!ignore) {
            setData(pd, {
              kind: 'main',
            })
          }
        } else {
          if (!ignore) {
            setData(pd, {
              kind: 'user',
              link: sourceLink,
            })
          }
          proc.add(sourceLink)
        }
      }
      _internal.localRef.forEach((loc) => {
        // TODO: use devmode logger here
        // console.warn('checking sources..', loc)
        if (loc.kind === 'user' && !proc.has(loc.link)) {
          // console.warn('cleaning empty sourceLink', loc.link)
          setData([], {
            kind: 'user',
            link: loc.link,
          })
        }
      })
      if (cb) {
        cb()
      }
    })
  }, [])
  const history = useUndo({ setData: manualUpdate })
  // TODO: implement debouncing
  function handleUpdate (treeDataNew, cb) {
    const currentState = subpage === 'element' ? { id: subid } : {}
    const setState = ({ id }) => {
      if (id) {
        // console.warn('switching to ', id, 'from', subid)
        // this is an awful hack - but it is needed because at this point
        // we'd get overriden by another openSubpage somewhere else...
        openSubpageSyncBatch('element', id, true)
      }
    }
    const split = conversions.TreeToSources(treeDataNew, convState)
    const splitFixed = fixSplitPD(split, currentState, setState)
    history.push(splitFixed)
    manualUpdate(splitFixed, cb)
    runSubpageBatch()
  }
  useEffect(() => {
    const split = conversions.TreeToSources(treeData, convState)
    const splitFixed = fixSplitPD(split, {}, () => null)
    history.replaceCurrent(splitFixed)
  }, [])

  if (!presentation || !treeData) {
    return <LoadingList name='data' />
  }

  // TODO: fix the 100vh, but the sortable-tree-view is not particulary
  //       responsive, so this won't be easy
  return (
    // this ref is not used to inject html directly, so it should be fine
    // eslint-disable-next-line react-security/no-refs
    <div ref={containerRef} style={{ height: 'calc(100vh - 128px)', overflow: 'auto' }}>
      {
        subpage
          ? (
            <EditView
              changeMode={changeMode}
              id={subid}
              key={'editview'}
              layout={layout?.data}
              mode={mode}
              openSubpage={openSubpageSynchronized}
              openSubpageBatch={openSubpageSyncBatch}
              presentation={presentation}
              presentationStyle={style}
              setData={handleUpdate}
              subpage={subpage}
              topLevel={topLevel}
              treeData={treeData}
            />
            )
          : (
            <TreeView
              changeMode={changeMode}
              history={history}
              mode={mode}
              openSubpage={openSubpageSynchronized}
              openSubpageBatch={openSubpageSyncBatch}
              presentation={presentation}
              scrollLeft={scrollLeft}
              setData={handleUpdate}
              topLevel={topLevel}
              treeData={treeData}
            />
            )
      }
    </div>
  )
}
