"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.uploadToken = exports.updateTokenElement = exports.getTokenStyle = exports.getTokenSource = exports.getTokenPresentation = exports.getTokenLayout = exports.getTokenData = exports.deleteTokenElement = exports.createTokenElement = exports.createToken = exports.createAccessToken = exports.CREATE_TOKEN_SUCCESS = exports.CREATE_TOKEN_REQUEST = exports.CREATE_TOKEN_FAILURE = void 0;

var _utils = require("./utils");

var _ = require("./");

var _urls = require("../urls");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

// TODO: accept optional `token` argument in frontend-base util
var tokenHeader = function tokenHeader(token, state) {
  if (token) {
    return {
      headers: new Headers({
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json'
      })
    };
  }

  return (0, _utils.tokenHeader)(token);
};

var CREATE_TOKEN_REQUEST = 'CREATE_TOKEN_REQUEST';
exports.CREATE_TOKEN_REQUEST = CREATE_TOKEN_REQUEST;
var CREATE_TOKEN_SUCCESS = 'CREATE_TOKEN_SUCCESS';
exports.CREATE_TOKEN_SUCCESS = CREATE_TOKEN_SUCCESS;
var CREATE_TOKEN_FAILURE = 'CREATE_TOKEN_FAILURE';
exports.CREATE_TOKEN_FAILURE = CREATE_TOKEN_FAILURE;

var createToken = function createToken(metadata) {
  return (0, _utils.thunkCreator)(function (state) {
    return {
      types: [CREATE_TOKEN_REQUEST, CREATE_TOKEN_SUCCESS, CREATE_TOKEN_FAILURE],
      promise: function promise(token) {
        return fetch("".concat(_urls.API_URL, "/token"), _objectSpread(_objectSpread({}, tokenHeader(token)), {}, {
          method: 'POST',
          body: JSON.stringify({
            expires: false,
            metadata: metadata
          })
        }));
      }
    };
  });
};

exports.createToken = createToken;

var createAccessToken = function createAccessToken(accessInfo) {
  var additionalMetadata = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return createToken(Object.assign({}, additionalMetadata, {
    a: accessInfo
  }));
};

exports.createAccessToken = createAccessToken;

var getTokenPresentation = function getTokenPresentation(presentationId, token) {
  return (0, _utils.thunkCreator)(function (state) {
    return {
      types: [_.PRESENTATION_REQUEST, _.PRESENTATION_SUCCESS, _.PRESENTATION_FAILURE],
      _id: presentationId,
      promise: function promise(token) {
        return fetch("".concat(_urls.API_URL, "/presentations/").concat(presentationId), tokenHeader(token, state));
      }
    };
  });
};

exports.getTokenPresentation = getTokenPresentation;

var getTokenData = function getTokenData(presentationId, token) {
  return (0, _utils.thunkCreator)(function (state) {
    return {
      types: [_.PRESENTATION_DATA_REQUEST, _.PRESENTATION_DATA_SUCCESS, _.PRESENTATION_DATA_FAILURE],
      _id: presentationId,
      promise: function promise(token) {
        return fetch("".concat(_urls.API_URL, "/presentations/").concat(presentationId, "/presentation.json"), tokenHeader(token, state));
      }
    };
  });
};

exports.getTokenData = getTokenData;

var getTokenStyle = function getTokenStyle(presentationId, token) {
  return (0, _utils.thunkCreator)(function (state) {
    return {
      types: [_.PRESENTATION_STYLE_REQUEST, _.PRESENTATION_STYLE_SUCCESS, _.PRESENTATION_STYLE_FAILURE],
      _id: presentationId,
      promise: function promise(token) {
        return fetch("".concat(_urls.API_URL, "/presentations/").concat(presentationId, "/style.json"), tokenHeader(token, state));
      }
    };
  });
};

exports.getTokenStyle = getTokenStyle;

var getTokenLayout = function getTokenLayout(presentationId, token) {
  return (0, _utils.thunkCreator)(function (state) {
    return {
      types: [_.PRESENTATION_LAYOUT_REQUEST, _.PRESENTATION_LAYOUT_SUCCESS, _.PRESENTATION_LAYOUT_FAILURE],
      _id: presentationId,
      promise: function promise(token) {
        return fetch("".concat(_urls.API_URL, "/presentations/").concat(presentationId, "/layout.json"), tokenHeader(token, state));
      }
    };
  });
};

exports.getTokenLayout = getTokenLayout;

var createTokenElement = function createTokenElement(json, tag, token) {
  return (0, _utils.thunkCreator)(function (state) {
    return {
      types: [_.CREATE_ELEMENT_REQUEST, _.CREATE_ELEMENT_SUCCESS, _.CREATE_ELEMENT_FAILURE],
      promise: function promise(token) {
        return fetch("".concat(_urls.CUSTOMDATA_URL, "/json"), Object.assign({
          method: 'POST',
          body: JSON.stringify({
            data: json,
            tag: tag
          })
        }, tokenHeader(token, state)));
      }
    };
  });
};

exports.createTokenElement = createTokenElement;

var updateTokenElement = function updateTokenElement(elementId, json, token) {
  return (0, _utils.thunkCreator)(function (state) {
    return {
      types: [_.UPDATE_ELEMENT_REQUEST, _.UPDATE_ELEMENT_SUCCESS, _.UPDATE_ELEMENT_FAILURE],
      promise: function promise(token) {
        return fetch("".concat(_urls.CUSTOMDATA_URL, "/json/").concat(elementId), Object.assign({
          method: 'POST',
          body: JSON.stringify({
            data: json
          })
        }, tokenHeader(token, state)));
      }
    };
  });
};

exports.updateTokenElement = updateTokenElement;

var deleteTokenElement = function deleteTokenElement(id, type, token) {
  return (0, _utils.thunkCreator)(function (state) {
    return {
      types: [_.DELETE_ELEMENT_REQUEST, _.DELETE_ELEMENT_SUCCESS, _.DELETE_ELEMENT_FAILURE],
      promise: function promise(token) {
        return fetch("".concat(_urls.CUSTOMDATA_URL, "/").concat(type, "/").concat(id), Object.assign({
          method: 'DELETE'
        }, tokenHeader(token, state)));
      }
    };
  });
};

exports.deleteTokenElement = deleteTokenElement;

var uploadToken = function uploadToken(file, token) {
  var form = new FormData();
  form.append('file', file); // form.append('tag', user)

  return (0, _utils.thunkCreator)(function (state) {
    return {
      types: [_.UPLOAD_REQUEST, _.UPLOAD_SUCCESS, _.UPLOAD_FAILURE],
      promise: function promise(token) {
        return fetch("".concat(_urls.CUSTOMDATA_URL, "/upload"), {
          method: 'POST',
          headers: {
            Authorization: tokenHeader(token, state).headers.get('Authorization')
          },
          body: form
        });
      }
    };
  });
};

exports.uploadToken = uploadToken;

var getTokenSource = function getTokenSource(sourceId, token) {
  return (0, _utils.thunkCreator)(function (state) {
    return {
      types: [_.SOURCE_REQUEST, _.SOURCE_SUCCESS, _.SOURCE_FAILURE],
      promise: function promise(token) {
        return fetch("".concat(_urls.API_URL, "/sources/") + sourceId, tokenHeader(token, state));
      }
    };
  });
};

exports.getTokenSource = getTokenSource;