"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = filesReducer;

var _actions = require("../actions");

function filesReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case _actions.GET_FILES_SUCCESS:
      {
        return action.result;
      }

    default:
      return state;
  }
}