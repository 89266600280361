"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getPresentationData = exports.PRESENTATION_DATA_SUCCESS = exports.PRESENTATION_DATA_REQUEST = exports.PRESENTATION_DATA_FAILURE = void 0;

var _utils = require("./utils");

var PRESENTATION_DATA_REQUEST = 'PRESENTATION_DATA_REQUEST';
exports.PRESENTATION_DATA_REQUEST = PRESENTATION_DATA_REQUEST;
var PRESENTATION_DATA_SUCCESS = 'PRESENTATION_DATAUCCESS';
exports.PRESENTATION_DATA_SUCCESS = PRESENTATION_DATA_SUCCESS;
var PRESENTATION_DATA_FAILURE = 'PRESENTATION_DATA_FAILURE';
exports.PRESENTATION_DATA_FAILURE = PRESENTATION_DATA_FAILURE;

var getPresentationData = function getPresentationData(sourceId) {
  return (0, _utils.thunkCreator)(function (state) {
    return {
      types: [PRESENTATION_DATA_REQUEST, PRESENTATION_DATA_SUCCESS, PRESENTATION_DATA_FAILURE],
      promise: function promise(token) {
        return fetch("".concat(process.env.REACT_APP_API_URL, "/presentationdata/") + sourceId, (0, _utils.tokenHeader)(token));
      }
    };
  });
};

exports.getPresentationData = getPresentationData;