"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.uploadPresentationStats = exports.updatePresentation = exports.publishPresentation = exports.getPresentations = exports.getPresentationStyle = exports.getPresentationStats = exports.getPresentationPreviewData = exports.getPresentationLayout = exports.getPresentationInfo = exports.getPresentation = exports.disconnectSource = exports.deletePresentation = exports.createPresentation = exports.connectSource = exports.UPLOAD_PRESENTATION_STATS_SUCCESS = exports.UPLOAD_PRESENTATION_STATS_REQUEST = exports.UPLOAD_PRESENTATION_STATS_FAILURE = exports.UPDATE_PRESENTATION_SUCCESS = exports.UPDATE_PRESENTATION_REQUEST = exports.UPDATE_PRESENTATION_FAILURE = exports.PUBLISH_PRESENTATION_SUCCESS = exports.PUBLISH_PRESENTATION_REQUEST = exports.PUBLISH_PRESENTATION_FAILURE = exports.PRESENTATION_SUCCESS = exports.PRESENTATION_STYLE_SUCCESS = exports.PRESENTATION_STYLE_REQUEST = exports.PRESENTATION_STYLE_FAILURE = exports.PRESENTATION_STATS_SUCCESS = exports.PRESENTATION_STATS_REQUEST = exports.PRESENTATION_STATS_FAILURE = exports.PRESENTATION_REQUEST = exports.PRESENTATION_PREVIEW_DATA_SUCCESS = exports.PRESENTATION_PREVIEW_DATA_REQUEST = exports.PRESENTATION_PREVIEW_DATA_FAILURE = exports.PRESENTATION_LAYOUT_SUCCESS = exports.PRESENTATION_LAYOUT_REQUEST = exports.PRESENTATION_LAYOUT_FAILURE = exports.PRESENTATION_INFO_SUCCESS = exports.PRESENTATION_INFO_REQUEST = exports.PRESENTATION_INFO_FAILURE = exports.PRESENTATION_FAILURE = exports.PRESENTATIONS_SUCCESS = exports.PRESENTATIONS_REQUEST = exports.PRESENTATIONS_FAILURE = exports.DISCONNECT_SOURCE_SUCCESS = exports.DISCONNECT_SOURCE_REQUEST = exports.DISCONNECT_SOURCE_FAILURE = exports.DELETE_PRESENTATION_SUCCESS = exports.DELETE_PRESENTATION_REQUEST = exports.DELETE_PRESENTATION_FAILURE = exports.CREATE_PRESENTATION_SUCCESS = exports.CREATE_PRESENTATION_REQUEST = exports.CREATE_PRESENTATION_FAILURE = exports.CONNECT_SOURCE_SUCCESS = exports.CONNECT_SOURCE_REQUEST = exports.CONNECT_SOURCE_FAILURE = void 0;

var _utils = require("./utils");

var PRESENTATIONS_REQUEST = 'PRESENTATIONS_REQUEST';
exports.PRESENTATIONS_REQUEST = PRESENTATIONS_REQUEST;
var PRESENTATIONS_SUCCESS = 'PRESENTATIONS_SUCCESS';
exports.PRESENTATIONS_SUCCESS = PRESENTATIONS_SUCCESS;
var PRESENTATIONS_FAILURE = 'PRESENTATIONS_FAILURE';
exports.PRESENTATIONS_FAILURE = PRESENTATIONS_FAILURE;

var getPresentations = function getPresentations() {
  return (0, _utils.thunkCreator)(function (state) {
    return {
      types: [PRESENTATIONS_REQUEST, PRESENTATIONS_SUCCESS, PRESENTATIONS_FAILURE],
      promise: function promise(token) {
        return fetch("".concat(process.env.REACT_APP_API_URL, "/presentations"), (0, _utils.tokenHeader)(token));
      }
    };
  });
};

exports.getPresentations = getPresentations;
var PRESENTATION_REQUEST = 'PRESENTATION_REQUEST';
exports.PRESENTATION_REQUEST = PRESENTATION_REQUEST;
var PRESENTATION_SUCCESS = 'PRESENTATION_SUCCESS';
exports.PRESENTATION_SUCCESS = PRESENTATION_SUCCESS;
var PRESENTATION_FAILURE = 'PRESENTATION_FAILURE';
exports.PRESENTATION_FAILURE = PRESENTATION_FAILURE;

var getPresentation = function getPresentation(presentationId) {
  return (0, _utils.thunkCreator)(function (state) {
    return {
      types: [PRESENTATION_REQUEST, PRESENTATION_SUCCESS, PRESENTATION_FAILURE],
      promise: function promise(token) {
        return fetch("".concat(process.env.REACT_APP_API_URL, "/presentations/") + presentationId, (0, _utils.tokenHeader)(token));
      }
    };
  });
};

exports.getPresentation = getPresentation;
var CREATE_PRESENTATION_REQUEST = 'CREATE_PRESENTATION_REQUEST';
exports.CREATE_PRESENTATION_REQUEST = CREATE_PRESENTATION_REQUEST;
var CREATE_PRESENTATION_SUCCESS = 'CREATE_PRESENTATION_SUCCESS';
exports.CREATE_PRESENTATION_SUCCESS = CREATE_PRESENTATION_SUCCESS;
var CREATE_PRESENTATION_FAILURE = 'CREATE_PRESENTATION_FAILURE';
exports.CREATE_PRESENTATION_FAILURE = CREATE_PRESENTATION_FAILURE;

var createPresentation = function createPresentation(name, options) {
  return (0, _utils.thunkCreator)(function (state) {
    return {
      types: [CREATE_PRESENTATION_REQUEST, CREATE_PRESENTATION_SUCCESS, CREATE_PRESENTATION_FAILURE],
      promise: function promise(token) {
        return fetch("".concat(process.env.REACT_APP_API_URL, "/create"), Object.assign({
          method: 'POST',
          body: JSON.stringify({
            presentation: {
              name: name,
              type: options.type,
              kind: options.kind
            },
            layout: options && options.layout || {},
            style: options && options.style || {},
            sources: options && options.sources || [],
            options: {
              publishOnCreate: options.publishOnCreate === undefined ? true : options.publishOnCreate
            }
          })
        }, (0, _utils.tokenHeader)(token)));
      }
    };
  });
};

exports.createPresentation = createPresentation;
var UPDATE_PRESENTATION_REQUEST = 'UPDATE_PRESENTATION_REQUEST';
exports.UPDATE_PRESENTATION_REQUEST = UPDATE_PRESENTATION_REQUEST;
var UPDATE_PRESENTATION_SUCCESS = 'UPDATE_PRESENTATION_SUCCESS';
exports.UPDATE_PRESENTATION_SUCCESS = UPDATE_PRESENTATION_SUCCESS;
var UPDATE_PRESENTATION_FAILURE = 'UPDATE_PRESENTATION_FAILURE';
exports.UPDATE_PRESENTATION_FAILURE = UPDATE_PRESENTATION_FAILURE;

var updatePresentation = function updatePresentation(presentationId, change) {
  return (0, _utils.thunkCreator)(function (state) {
    return {
      types: [UPDATE_PRESENTATION_REQUEST, UPDATE_PRESENTATION_SUCCESS, UPDATE_PRESENTATION_FAILURE],
      promise: function promise(token) {
        return fetch("".concat(process.env.REACT_APP_API_URL, "/presentations/") + presentationId, Object.assign({
          method: 'POST',
          body: JSON.stringify(change)
        }, (0, _utils.tokenHeader)(token)));
      }
    };
  });
};

exports.updatePresentation = updatePresentation;
var DELETE_PRESENTATION_REQUEST = 'DELETE_PRESENTATION_REQUEST';
exports.DELETE_PRESENTATION_REQUEST = DELETE_PRESENTATION_REQUEST;
var DELETE_PRESENTATION_SUCCESS = 'DELETE_PRESENTATION_SUCCESS';
exports.DELETE_PRESENTATION_SUCCESS = DELETE_PRESENTATION_SUCCESS;
var DELETE_PRESENTATION_FAILURE = 'DELETE_PRESENTATION_FAILURE';
exports.DELETE_PRESENTATION_FAILURE = DELETE_PRESENTATION_FAILURE;

var deletePresentation = function deletePresentation(presentationId) {
  return (0, _utils.thunkCreator)(function (state) {
    return {
      types: [DELETE_PRESENTATION_REQUEST, DELETE_PRESENTATION_SUCCESS, DELETE_PRESENTATION_FAILURE],
      promise: function promise(token) {
        return fetch("".concat(process.env.REACT_APP_API_URL, "/presentations/") + presentationId, Object.assign({
          method: 'DELETE'
        }, (0, _utils.tokenHeader)(token)));
      }
    };
  });
};

exports.deletePresentation = deletePresentation;
var CONNECT_SOURCE_REQUEST = 'CONNECT_SOURCE_REQUEST';
exports.CONNECT_SOURCE_REQUEST = CONNECT_SOURCE_REQUEST;
var CONNECT_SOURCE_SUCCESS = 'CONNECT_SOURCE_SUCCESS';
exports.CONNECT_SOURCE_SUCCESS = CONNECT_SOURCE_SUCCESS;
var CONNECT_SOURCE_FAILURE = 'CONNECT_SOURCE_FAILURE';
exports.CONNECT_SOURCE_FAILURE = CONNECT_SOURCE_FAILURE;

var connectSource = function connectSource(presentationId, sourceId) {
  return (0, _utils.thunkCreator)(function (state) {
    return {
      types: [CONNECT_SOURCE_REQUEST, CONNECT_SOURCE_SUCCESS, CONNECT_SOURCE_FAILURE],
      promise: function promise(token) {
        return fetch("".concat(process.env.REACT_APP_API_URL, "/presentations/").concat(presentationId, "/connect/").concat(sourceId), Object.assign({
          method: 'POST'
        }, (0, _utils.tokenHeader)(token)));
      }
    };
  });
};

exports.connectSource = connectSource;
var DISCONNECT_SOURCE_REQUEST = 'DISCONNECT_SOURCE_REQUEST';
exports.DISCONNECT_SOURCE_REQUEST = DISCONNECT_SOURCE_REQUEST;
var DISCONNECT_SOURCE_SUCCESS = 'DISCONNECT_SOURCE_SUCCESS';
exports.DISCONNECT_SOURCE_SUCCESS = DISCONNECT_SOURCE_SUCCESS;
var DISCONNECT_SOURCE_FAILURE = 'DISCONNECT_SOURCE_FAILURE';
exports.DISCONNECT_SOURCE_FAILURE = DISCONNECT_SOURCE_FAILURE;

var disconnectSource = function disconnectSource(presentationId, sourceId) {
  return (0, _utils.thunkCreator)(function (state) {
    return {
      types: [DISCONNECT_SOURCE_REQUEST, DISCONNECT_SOURCE_SUCCESS, DISCONNECT_SOURCE_FAILURE],
      promise: function promise(token) {
        return fetch("".concat(process.env.REACT_APP_API_URL, "/presentations/").concat(presentationId, "/disconnect/").concat(sourceId), Object.assign({
          method: 'POST'
        }, (0, _utils.tokenHeader)(token)));
      }
    };
  });
};

exports.disconnectSource = disconnectSource;
var PRESENTATION_PREVIEW_DATA_REQUEST = 'PRESENTATION_PREVIEW_DATA_REQUEST';
exports.PRESENTATION_PREVIEW_DATA_REQUEST = PRESENTATION_PREVIEW_DATA_REQUEST;
var PRESENTATION_PREVIEW_DATA_SUCCESS = 'PRESENTATION_PREVIEW_DATA_SUCCESS';
exports.PRESENTATION_PREVIEW_DATA_SUCCESS = PRESENTATION_PREVIEW_DATA_SUCCESS;
var PRESENTATION_PREVIEW_DATA_FAILURE = 'PRESENTATION_PREVIEW_DATA_FAILURE';
exports.PRESENTATION_PREVIEW_DATA_FAILURE = PRESENTATION_PREVIEW_DATA_FAILURE;

var getPresentationPreviewData = function getPresentationPreviewData(presentationId) {
  return (0, _utils.thunkCreator)(function (state) {
    return {
      types: [PRESENTATION_PREVIEW_DATA_REQUEST, PRESENTATION_PREVIEW_DATA_SUCCESS, PRESENTATION_PREVIEW_DATA_FAILURE],
      _id: presentationId,
      promise: function promise(token) {
        return fetch("".concat(process.env.REACT_APP_API_URL, "/presentations/").concat(presentationId, "/pd.json"), (0, _utils.tokenHeader)(token));
      }
    };
  });
};

exports.getPresentationPreviewData = getPresentationPreviewData;
var PRESENTATION_INFO_REQUEST = 'PRESENTATION_INFO_REQUEST';
exports.PRESENTATION_INFO_REQUEST = PRESENTATION_INFO_REQUEST;
var PRESENTATION_INFO_SUCCESS = 'PRESENTATION_INFO_SUCCESS';
exports.PRESENTATION_INFO_SUCCESS = PRESENTATION_INFO_SUCCESS;
var PRESENTATION_INFO_FAILURE = 'PRESENTATION_INFO_FAILURE';
exports.PRESENTATION_INFO_FAILURE = PRESENTATION_INFO_FAILURE;

var getPresentationInfo = function getPresentationInfo(presentationId) {
  return (0, _utils.thunkCreator)(function (state) {
    return {
      types: [PRESENTATION_INFO_REQUEST, PRESENTATION_INFO_SUCCESS, PRESENTATION_INFO_FAILURE],
      _id: presentationId,
      promise: function promise(token) {
        return fetch("".concat(process.env.REACT_APP_API_URL, "/presentations/").concat(presentationId, "/presentation.json"), (0, _utils.tokenHeader)(token));
      }
    };
  });
};

exports.getPresentationInfo = getPresentationInfo;
var PRESENTATION_STYLE_REQUEST = 'PRESENTATION_STYLE_REQUEST';
exports.PRESENTATION_STYLE_REQUEST = PRESENTATION_STYLE_REQUEST;
var PRESENTATION_STYLE_SUCCESS = 'PRESENTATION_STYLE_SUCCESS';
exports.PRESENTATION_STYLE_SUCCESS = PRESENTATION_STYLE_SUCCESS;
var PRESENTATION_STYLE_FAILURE = 'PRESENTATION_STYLE_FAILURE';
exports.PRESENTATION_STYLE_FAILURE = PRESENTATION_STYLE_FAILURE;

var getPresentationStyle = function getPresentationStyle(presentationId) {
  return (0, _utils.thunkCreator)(function (state) {
    return {
      types: [PRESENTATION_STYLE_REQUEST, PRESENTATION_STYLE_SUCCESS, PRESENTATION_STYLE_FAILURE],
      _id: presentationId,
      promise: function promise(token) {
        return fetch("".concat(process.env.REACT_APP_API_URL, "/presentations/").concat(presentationId, "/style.json"), (0, _utils.tokenHeader)(token));
      }
    };
  });
};

exports.getPresentationStyle = getPresentationStyle;
var PRESENTATION_LAYOUT_REQUEST = 'PRESENTATION_LAYOUT_REQUEST';
exports.PRESENTATION_LAYOUT_REQUEST = PRESENTATION_LAYOUT_REQUEST;
var PRESENTATION_LAYOUT_SUCCESS = 'PRESENTATION_LAYOUT_SUCCESS';
exports.PRESENTATION_LAYOUT_SUCCESS = PRESENTATION_LAYOUT_SUCCESS;
var PRESENTATION_LAYOUT_FAILURE = 'PRESENTATION_LAYOUT_FAILURE';
exports.PRESENTATION_LAYOUT_FAILURE = PRESENTATION_LAYOUT_FAILURE;

var getPresentationLayout = function getPresentationLayout(presentationId) {
  return (0, _utils.thunkCreator)(function (state) {
    return {
      types: [PRESENTATION_LAYOUT_REQUEST, PRESENTATION_LAYOUT_SUCCESS, PRESENTATION_LAYOUT_FAILURE],
      _id: presentationId,
      promise: function promise(token) {
        return fetch("".concat(process.env.REACT_APP_API_URL, "/presentations/").concat(presentationId, "/layout.json"), (0, _utils.tokenHeader)(token));
      }
    };
  });
};

exports.getPresentationLayout = getPresentationLayout;
var PRESENTATION_STATS_REQUEST = 'PRESENTATION_STATS_REQUEST';
exports.PRESENTATION_STATS_REQUEST = PRESENTATION_STATS_REQUEST;
var PRESENTATION_STATS_SUCCESS = 'PRESENTATION_STATS_SUCCESS';
exports.PRESENTATION_STATS_SUCCESS = PRESENTATION_STATS_SUCCESS;
var PRESENTATION_STATS_FAILURE = 'PRESENTATION_STATS_FAILURE';
exports.PRESENTATION_STATS_FAILURE = PRESENTATION_STATS_FAILURE;

var getPresentationStats = function getPresentationStats(presentationId) {
  return (0, _utils.thunkCreator)(function (state) {
    return {
      types: [PRESENTATION_STATS_REQUEST, PRESENTATION_STATS_SUCCESS, PRESENTATION_STATS_FAILURE],
      _id: presentationId,
      promise: function promise(token) {
        return fetch("".concat(process.env.REACT_APP_API_URL, "/presentations/").concat(presentationId, "/stats.json"), (0, _utils.tokenHeader)(token));
      }
    };
  });
};

exports.getPresentationStats = getPresentationStats;
var UPLOAD_PRESENTATION_STATS_REQUEST = 'UPLOAD_PRESENTATION_STATS_REQUEST';
exports.UPLOAD_PRESENTATION_STATS_REQUEST = UPLOAD_PRESENTATION_STATS_REQUEST;
var UPLOAD_PRESENTATION_STATS_SUCCESS = 'UPLOAD_PRESENTATION_STATS_SUCCESS';
exports.UPLOAD_PRESENTATION_STATS_SUCCESS = UPLOAD_PRESENTATION_STATS_SUCCESS;
var UPLOAD_PRESENTATION_STATS_FAILURE = 'UPLOAD_PRESENTATION_STATS_FAILURE';
exports.UPLOAD_PRESENTATION_STATS_FAILURE = UPLOAD_PRESENTATION_STATS_FAILURE;

var uploadPresentationStats = function uploadPresentationStats(presentationId, chunk) {
  return (0, _utils.thunkCreator)(function (state) {
    return {
      types: [UPLOAD_PRESENTATION_STATS_REQUEST, UPLOAD_PRESENTATION_STATS_SUCCESS, UPLOAD_PRESENTATION_STATS_FAILURE],
      promise: function promise(token) {
        return fetch("".concat(process.env.REACT_APP_API_URL, "/presentations/").concat(presentationId, "/stats.json"), Object.assign({
          method: 'POST',
          body: JSON.stringify(chunk)
        }, (0, _utils.tokenHeader)(token)));
      }
    };
  });
};

exports.uploadPresentationStats = uploadPresentationStats;
var PUBLISH_PRESENTATION_REQUEST = 'PUBLISH_PRESENTATION_REQUEST';
exports.PUBLISH_PRESENTATION_REQUEST = PUBLISH_PRESENTATION_REQUEST;
var PUBLISH_PRESENTATION_SUCCESS = 'PUBLISH_PRESENTATION_SUCCESS';
exports.PUBLISH_PRESENTATION_SUCCESS = PUBLISH_PRESENTATION_SUCCESS;
var PUBLISH_PRESENTATION_FAILURE = 'PUBLISH_PRESENTATION_FAILURE';
exports.PUBLISH_PRESENTATION_FAILURE = PUBLISH_PRESENTATION_FAILURE;

var publishPresentation = function publishPresentation(presentationId) {
  return (0, _utils.thunkCreator)(function (state) {
    return {
      types: [PUBLISH_PRESENTATION_REQUEST, PUBLISH_PRESENTATION_SUCCESS, PUBLISH_PRESENTATION_FAILURE],
      promise: function promise(token) {
        return fetch("".concat(process.env.REACT_APP_API_URL, "/presentations/").concat(presentationId, "/publish"), Object.assign({
          method: 'POST'
        }, (0, _utils.tokenHeader)(token)));
      }
    };
  });
};

exports.publishPresentation = publishPresentation;