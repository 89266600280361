"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useLeafletAvailable = exports["default"] = void 0;

var _reactLeaflet = require("react-leaflet");

var useLeafletAvailable = function useLeafletAvailable() {
  var leaflet = (0, _reactLeaflet.useLeaflet)();
  return !!(leaflet && leaflet.map);
};

exports.useLeafletAvailable = useLeafletAvailable;

var IfLeafletAvailable = function IfLeafletAvailable(props) {
  var leafletAvailable = useLeafletAvailable();

  if (leafletAvailable) {
    return props.children || null;
  } else {
    return null;
  }
};

var _default = IfLeafletAvailable;
exports["default"] = _default;