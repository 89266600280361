"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = require("react");

var _reactLeaflet = require("react-leaflet");

var useOptions = function useOptions(map, val, cb) {
  (0, _react.useEffect)(function () {
    if (map && val !== undefined) {
      cb(map, val);
    }
  }, [!!map, val]);
};

var DynamicProperties = function DynamicProperties(_ref) {
  var minZoom = _ref.minZoom,
      maxZoom = _ref.maxZoom,
      zoomDelta = _ref.zoomDelta,
      zoomSnap = _ref.zoomSnap,
      maxBoundsViscocity = _ref.maxBoundsViscocity;
  var leaflet = (0, _reactLeaflet.useLeaflet)();
  var map = leaflet && leaflet.map;
  useOptions(map, minZoom, function (map, v) {
    map.setMinZoom(v);
  });
  useOptions(map, maxZoom, function (map, v) {
    map.setMaxZoom(v);
  });
  useOptions(map, zoomDelta, function (map, v) {
    map.options.zoomDelta = v;
  });
  useOptions(map, zoomSnap, function (map, v) {
    map.options.zoomSnap = v;
  });
  useOptions(map, maxBoundsViscocity, function (map, v) {
    map.options.maxBoundsViscocity = maxBoundsViscocity;
  });
  return null;
};

var _default = DynamicProperties;
exports["default"] = _default;