"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateSource = exports.getSources = exports.getSource = exports.getIntegrations = exports.deleteSource = exports.createSource = exports.UPDATE_SOURCE_SUCCESS = exports.UPDATE_SOURCE_REQUEST = exports.UPDATE_SOURCE_FAILURE = exports.SOURCE_SUCCESS = exports.SOURCE_REQUEST = exports.SOURCE_FAILURE = exports.SOURCES_SUCCESS = exports.SOURCES_REQUEST = exports.SOURCES_FAILURE = exports.INTEGRATIONS_SUCCESS = exports.INTEGRATIONS_REQUEST = exports.INTEGRATIONS_FAILURE = exports.DELETE_SOURCE_SUCCESS = exports.DELETE_SOURCE_REQUEST = exports.DELETE_SOURCE_FAILURE = exports.CREATE_SOURCE_SUCCESS = exports.CREATE_SOURCE_REQUEST = exports.CREATE_SOURCE_FAILURE = void 0;

var _utils = require("./utils");

var SOURCES_REQUEST = 'SOURCES_REQUEST';
exports.SOURCES_REQUEST = SOURCES_REQUEST;
var SOURCES_SUCCESS = 'SOURCES_SUCCESS';
exports.SOURCES_SUCCESS = SOURCES_SUCCESS;
var SOURCES_FAILURE = 'SOURCES_FAILURE';
exports.SOURCES_FAILURE = SOURCES_FAILURE;

var getSources = function getSources() {
  return (0, _utils.thunkCreator)(function (state) {
    return {
      types: [SOURCES_REQUEST, SOURCES_SUCCESS, SOURCES_FAILURE],
      promise: function promise(token) {
        return fetch("".concat(process.env.REACT_APP_API_URL, "/sources"), (0, _utils.tokenHeader)(token));
      }
    };
  });
};

exports.getSources = getSources;
var SOURCE_REQUEST = 'SOURCE_REQUEST';
exports.SOURCE_REQUEST = SOURCE_REQUEST;
var SOURCE_SUCCESS = 'SOURCE_SUCCESS';
exports.SOURCE_SUCCESS = SOURCE_SUCCESS;
var SOURCE_FAILURE = 'SOURCE_FAILURE';
exports.SOURCE_FAILURE = SOURCE_FAILURE;

var getSource = function getSource(sourceId) {
  return (0, _utils.thunkCreator)(function (state) {
    return {
      types: [SOURCE_REQUEST, SOURCE_SUCCESS, SOURCE_FAILURE],
      promise: function promise(token) {
        return fetch("".concat(process.env.REACT_APP_API_URL, "/sources/") + sourceId, (0, _utils.tokenHeader)(token));
      }
    };
  });
};

exports.getSource = getSource;
var CREATE_SOURCE_REQUEST = 'CREATE_SOURCE_REQUEST';
exports.CREATE_SOURCE_REQUEST = CREATE_SOURCE_REQUEST;
var CREATE_SOURCE_SUCCESS = 'CREATE_SOURCE_SUCCESS';
exports.CREATE_SOURCE_SUCCESS = CREATE_SOURCE_SUCCESS;
var CREATE_SOURCE_FAILURE = 'CREATE_SOURCE_FAILURE';
exports.CREATE_SOURCE_FAILURE = CREATE_SOURCE_FAILURE;

var createSource = function createSource(integration, config, name, meta) {
  return (0, _utils.thunkCreator)(function (state) {
    return {
      types: [CREATE_SOURCE_REQUEST, CREATE_SOURCE_SUCCESS, CREATE_SOURCE_FAILURE],
      promise: function promise(token) {
        return fetch("".concat(process.env.REACT_APP_API_URL, "/sources"), Object.assign({
          method: 'POST',
          body: JSON.stringify({
            integration: integration,
            config: config,
            name: name,
            meta: meta
          })
        }, (0, _utils.tokenHeader)(token)));
      }
    };
  });
};

exports.createSource = createSource;
var DELETE_SOURCE_REQUEST = 'DELETE_SOURCE_REQUEST';
exports.DELETE_SOURCE_REQUEST = DELETE_SOURCE_REQUEST;
var DELETE_SOURCE_SUCCESS = 'DELETE_SOURCE_SUCCESS';
exports.DELETE_SOURCE_SUCCESS = DELETE_SOURCE_SUCCESS;
var DELETE_SOURCE_FAILURE = 'DELETE_SOURCE_FAILURE';
exports.DELETE_SOURCE_FAILURE = DELETE_SOURCE_FAILURE;

var deleteSource = function deleteSource(sourceId) {
  return (0, _utils.thunkCreator)(function (state) {
    return {
      types: [DELETE_SOURCE_REQUEST, DELETE_SOURCE_SUCCESS, DELETE_SOURCE_FAILURE],
      promise: function promise(token) {
        return fetch("".concat(process.env.REACT_APP_API_URL, "/sources/") + sourceId, Object.assign({
          method: 'DELETE'
        }, (0, _utils.tokenHeader)(token)));
      }
    };
  });
};

exports.deleteSource = deleteSource;
var UPDATE_SOURCE_REQUEST = 'UPDATE_SOURCE_REQUEST';
exports.UPDATE_SOURCE_REQUEST = UPDATE_SOURCE_REQUEST;
var UPDATE_SOURCE_SUCCESS = 'UPDATE_SOURCE_SUCCESS';
exports.UPDATE_SOURCE_SUCCESS = UPDATE_SOURCE_SUCCESS;
var UPDATE_SOURCE_FAILURE = 'UPDATE_SOURCE_FAILURE';
exports.UPDATE_SOURCE_FAILURE = UPDATE_SOURCE_FAILURE;

var updateSource = function updateSource(sourceId, change) {
  return (0, _utils.thunkCreator)(function (state) {
    return {
      types: [UPDATE_SOURCE_REQUEST, UPDATE_SOURCE_SUCCESS, UPDATE_SOURCE_FAILURE],
      promise: function promise(token) {
        return fetch("".concat(process.env.REACT_APP_API_URL, "/sources/") + sourceId, Object.assign({
          method: 'POST',
          body: JSON.stringify(change)
        }, (0, _utils.tokenHeader)(token)));
      }
    };
  });
};

exports.updateSource = updateSource;
var INTEGRATIONS_REQUEST = 'INTEGRATIONS_REQUEST';
exports.INTEGRATIONS_REQUEST = INTEGRATIONS_REQUEST;
var INTEGRATIONS_SUCCESS = 'INTEGRATIONS_SUCCESS';
exports.INTEGRATIONS_SUCCESS = INTEGRATIONS_SUCCESS;
var INTEGRATIONS_FAILURE = 'INTEGRATIONS_FAILURE';
exports.INTEGRATIONS_FAILURE = INTEGRATIONS_FAILURE;

var getIntegrations = function getIntegrations() {
  return (0, _utils.thunkCreator)(function (state) {
    return {
      types: [INTEGRATIONS_REQUEST, INTEGRATIONS_SUCCESS, INTEGRATIONS_FAILURE],
      promise: function promise(token) {
        return fetch("".concat(process.env.REACT_APP_API_URL, "/integrations"), (0, _utils.tokenHeader)(token));
      }
    };
  });
};

exports.getIntegrations = getIntegrations;