"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var json = {
  feedback: {
    question: {
      wayfinder: 'Is this map useful?',
      presentation: 'Is this presentation useful?',
      "default": 'Is this software useful?'
    },
    thanks: 'Thanks for your feedback!'
  },
  search: {
    title: 'Search',
    hintText: 'Search',
    hintTextLocal: 'Search %%',
    noResults: 'No results found. Please try a different search term.',
    noInput: 'Use the search field above and the results will appear here.',
    global: 'Search all areas'
  },
  common: {
    name: 'Name',
    time: 'Time',
    noInfo: 'No information available',
    send: 'Send'
  },
  pdf: {
    loading: 'Loading document...'
  },
  web: {
    loading: 'Loading content...'
  },
  video: {
    unsupported: 'Video playback is not supported'
  },
  timetable: {
    noEvents: 'There are currently no active events'
  }
};
var _default = json;
exports["default"] = _default;