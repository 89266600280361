"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _linker = require("./linker");

Object.keys(_linker).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _linker[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _linker[key];
    }
  });
});

var _deprecated = require("./deprecated");

Object.keys(_deprecated).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _deprecated[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _deprecated[key];
    }
  });
});