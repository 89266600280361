"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

/**
 * This provides functions to resolve `meta.parent` relations of presentation
 * data.
 *
 * Traverse PD while adding parentObj to meta property while possible
 *
 * @name handleMetaParent
 */
var compilePD = function compilePD(ctx) {
  return function (pd) {
    var work = _objectSpread({}, pd);

    if (ctx) {
      work.meta = _objectSpread(_objectSpread({}, work.meta), {}, {
        parentObj: {
          type: ctx.type,
          id: ctx.id
        }
      });
    }

    var content = work.content;

    if (content) {
      if (content == null) return work;else if (typeof content === 'string') return work;else if (Array.isArray(content)) return _objectSpread(_objectSpread({}, work), {}, {
        content: compileInt(content, pd)
      });else return _objectSpread(_objectSpread({}, work), {}, {
        content: compilePD(pd)(content)
      });
    }

    return work;
  };
};

var compileInt = function compileInt(pds, ctx) {
  return pds.map(compilePD(ctx));
};

var compile = function compile(pds) {
  return compileInt(pds);
};

var _default = compile;
exports.default = _default;