"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CUSTOMDATA_URL = exports.API_URL = void 0;

if (!process.env.REACT_APP_API_URL) {
  throw new Error('please define the REACT_APP_API_URL environment variable');
} // TODO: use API_URL from here everywhere in this project


var API_URL = process.env.REACT_APP_API_URL;
exports.API_URL = API_URL;
var CUSTOMDATA_URL = process.env.REACT_APP_CUSTOMDATA_URL ? process.env.REACT_APP_CUSTOMDATA_URL : API_URL.replace('backend', 'customdata');
exports.CUSTOMDATA_URL = CUSTOMDATA_URL;