import React, { useRef, useContext } from 'react'

const defaultUndoState = {
  undo: [],
  current: null,
  redo: [],
}

const UndoCtx = React.createContext({
  undoStateRef: { current: defaultUndoState },
})

export const UndoContext = ({ children }) => {
  const undoStateRef = useRef(defaultUndoState)

  return (
    <UndoCtx.Provider value={{ undoStateRef }}>
      {children}
    </UndoCtx.Provider>
  )
}

export const useUndoContext = () => {
  const { undoStateRef } = useContext(UndoCtx)
  return undoStateRef
}
