export function processChunk ({ actions }) {
  return actions
    .filter(({ act }) => act && act.type === 'action' && act.name === 'history.push')
    .map(({ ts, act }) => {
      return { ts, from: act.from, pdID: act.pdID }
    })
}

export function processClicks (actions) {
  return actions.filter(({ pdID }) => !!pdID).reduce((acc, act) => {
    if (!acc[act.pdID]) {
      acc[act.pdID] = 1
    } else {
      acc[act.pdID] += 1
    }
    return acc
  }, {})
}

export function sortValues (labels, valuesX, valuesY) {
  const arrayOfObj = labels.map((label, i) => {
    return { label, valueX: valuesX[i], valueY: valuesY[i] }
  })
  const sortedArrayOfObj = arrayOfObj.sort((a, b) => {
    return b.valueX - a.valueX
  })
  const newLabels = []
  const newValuesX = []
  const newValuesY = []
  sortedArrayOfObj.forEach(({ label, valueX, valueY }) => {
    newLabels.push(label)
    newValuesX.push(valueX)
    newValuesY.push(valueY)
  })

  return [ newLabels, newValuesX, newValuesY ]
}
