"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _styles = require("@material-ui/core/styles");

var _default = (0, _styles.makeStyles)({
  defaultArea: {
    transform: 'translateZ(0)',
    willChange: 'fillOpacity, strokeOpacity'
  },
  selectedArea: {
    animationDuration: '1s',
    animationName: '$glow-stroke, $glow-fill',
    animationIterationCount: 'infinite',
    animationDirection: 'alternate',
    zIndex: '99999'
  },
  selectedPath: {
    animationDuration: '1s',
    animationName: '$glow-stroke',
    animationIterationCount: 'infinite',
    animationDirection: 'alternate',
    zIndex: '99999'
  },
  selectedLocation: {
    animationDuration: '1s',
    animationName: '$glow-location',
    animationIterationCount: 'infinite',
    animationDirection: 'alternate',
    zIndex: '99999'
  },
  hereIcon: {
    transform: 'scale(3)',
    border: 'none',
    outline: 'none'
  },
  hereDot: {
    fill: 'transparent',
    animation: '$here-pulse 1.5s ease infinite'
  },
  pathfinderPath: {
    strokeDasharray: '4000',
    strokeDashoffset: '4000',
    animation: '$dash 3s ease-in-out forwards',
    animationDirection: 'alternate'
  },
  '@keyframes glow-location': {
    '0%': {
      filter: 'drop-shadow(0 0 0 red)'
    },
    '100%': {
      filter: 'drop-shadow(5px 0px 0.6rem red)'
    }
  },
  '@keyframes glow-stroke': {
    '0%': {
      strokeOpacity: '1',
      strokeWidth: '4px'
    },
    '100%': {
      strokeOpacity: '0.3',
      strokeWidth: '1px'
    }
  },
  '@keyframes glow-fill': {
    '0%': {
      fillOpacity: '0.2'
    },
    '100%': {
      fillOpacity: '0.7'
    }
  },
  '@keyframes here-pulse': {
    '0%': {
      opacity: '1',
      r: '17.5'
    },
    '100%': {
      opacity: '0',
      r: '31.5'
    }
  },
  '@keyframes dash': {
    from: {
      strokeDashoffset: '4000'
    },
    to: {
      strokeDashoffset: '0'
    }
  }
});

exports["default"] = _default;