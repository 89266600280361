"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = authReducer;

var _actions = require("../actions");

function authReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case _actions.PASSWORD_RESET_REQUEST:
    case _actions.AUTH_REQUEST:
      return {
        isAuthenticating: true
      };

    case _actions.AUTH_SUCCESS:
      return {
        isAuthenticating: false,
        isAuthenticated: true,
        statusText: 'You have been successfully logged in!'
      };

    case _actions.PASSWORD_RESET_SUCCESS:
      return {
        isAuthenticating: false,
        statusText: 'We have sent you an email! Please follow the link there to reset your password.'
      };

    case _actions.PASSWORD_RESET_ERROR:
    case _actions.AUTH_ERROR:
      {
        var _action$error, _action$error2;

        var errorText = (action === null || action === void 0 ? void 0 : (_action$error = action.error) === null || _action$error === void 0 ? void 0 : _action$error.code) === 'auth/user-not-found' ? 'User with given email address not found.' : action === null || action === void 0 ? void 0 : (_action$error2 = action.error) === null || _action$error2 === void 0 ? void 0 : _action$error2.message;
        return {
          isAuthenticating: false,
          errorText: errorText
        };
      }

    case _actions.USER_INFO_SUCCESS:
    case _actions.UPDATE_USER_INFO_SUCCESS:
      return Object.assign({}, state, {
        isAuthenticating: false,
        user: action.result
      });

    case _actions.EXPIRED_TOKEN:
    case _actions.UNAUTHORIZED:
      return {
        isAuthenticating: false,
        isAuthenticated: false,
        user: null,
        token: null,
        expires: null
      };

    case _actions.LOGOUT_SUCCESS:
      return {
        isAuthenticating: false,
        isAuthenticated: false,
        user: null,
        token: null,
        expires: null,
        statusText: 'Successfully logged out!'
      };

    default:
      return state;
  }
}