"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.unpick = exports.pick = exports.flatKeys = void 0;

var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _toArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toArray"));

var _typeof2 = _interopRequireDefault(require("@babel/runtime/helpers/typeof"));

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var ARRAY_SYNTAX = /(.*)\[([0-9]+)\]$/;

var pickParser = function pickParser(o, k) {
  var arr = k.match(ARRAY_SYNTAX);

  if (Array.isArray(arr)) {
    // array syntax matched
    var _arr = (0, _slicedToArray2["default"])(arr, 3),
        key = _arr[1],
        arrIndex = _arr[2];

    return o && key in o ? o[key][arrIndex] : undefined;
  } // default case


  if (o && k in o) {
    return o[k];
  }
};

var pick = function pick(obj, key) {
  return typeof key === 'string' ? key.split('.').reduce(function (o, k) {
    return (0, _typeof2["default"])(o) === 'object' && pickParser(o, k) || undefined;
  }, obj) : undefined;
};

exports.pick = pick;

var generateObj = function generateObj(_ref, val) {
  var _ref2 = (0, _toArray2["default"])(_ref),
      key = _ref2[0],
      rest = _ref2.slice(1);

  return (0, _defineProperty2["default"])({}, key, rest.length > 0 ? generateObj(rest, val) : val);
};

var unpick = function unpick(key, val) {
  return key ? generateObj(key.split('.'), val) : undefined;
};

exports.unpick = unpick;

var flatKeys = function flatKeys(obj, removeRoot, prefix) {
  var keys = Object.keys(obj);
  var toBeRemoved = [];
  var recursiveKeys = keys.reduce(function (acc, key) {
    if (Array.isArray(obj[key])) {
      return acc; // ignore arrays
    }

    if ((0, _typeof2["default"])(obj[key]) === 'object') {
      if (removeRoot) {
        // if specified, remove the root key for this nested object
        toBeRemoved.push(key);
      }

      var newKeys = flatKeys(obj[key], removeRoot, prefix ? prefix + '.' + key : key);
      return [].concat((0, _toConsumableArray2["default"])(acc), (0, _toConsumableArray2["default"])(newKeys));
    }

    return acc;
  }, []);
  var prefixedKeys = keys.map(function (key) {
    return prefix ? prefix + '.' + key : key;
  });
  return [].concat((0, _toConsumableArray2["default"])(prefixedKeys), (0, _toConsumableArray2["default"])(recursiveKeys)).filter(function (key) {
    return !toBeRemoved.includes(key);
  });
};

exports.flatKeys = flatKeys;