"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.filterByView = exports.default = exports.componentNameByType = exports.componentMapByView = void 0;
var viewToFilter = {
  MapView: "type == 'layer' || type == 'ad.flag' || type == 'ad.flyover' || type == 'ad.bubble'",
  SidebarView: "type == 'area' || type == 'location' || type == 'ad.menu' || type == 'ad.banner'",
  FullscreenView: "type !== 'menu.item' && type !== 'menu.item-shared' && type !== 'menu.header'",
  PathFinderView: "type == 'path'",
  MarkerView: "type == 'menu.item'",
  SlideShowView: "type == 'image'",
  ClockView: 'false',
  MemoryView: 'false',
  QuizLevelView: 'false',
  FeedbackView: 'false',
  LegendView: 'false',
  RoutingView: 'false',
  // just renders sub-views, no PD
  UpButtonView: 'false',
  LinkView: 'false' // other views (like GridView) could theoretically render any components

};
/**
 * Maps views to their default filters, if not assigned, an empty filter is returned
 *
 * @alias defaultFilters
 */

var filterByView = function filterByView(view) {
  if (viewToFilter[view]) {
    return viewToFilter[view];
  }

  return null;
};

exports.filterByView = filterByView;
var viewToComponentMap = {
  SidebarView: {
    article: 'SidebarDetail',
    area: 'ListItem',
    location: 'ListItem'
  },
  QuizView: {
    'quiz.level': 'QuizLevel',
    'quiz.category': 'Simple',
    // we do props inspection, the rendered component is irrelevant
    'quiz.question': 'Simple'
  },
  ImageView: {
    'menu.item': 'HubMarker'
  },
  MarkerView: {
    'menu.item': 'HubMarkerNEW'
  }
};

var componentMapByView = function componentMapByView(view) {
  if (viewToComponentMap[view]) {
    return viewToComponentMap[view];
  }

  return null;
}; // TODO: refactor presentation data types and components


exports.componentMapByView = componentMapByView;
var dataTypeToComponentType = {
  atom: 'Simple',
  md: 'Markdown',
  richtext: 'RichText',
  article: 'Card',
  area: 'MapArea',
  path: 'MapPath',
  location: 'MapLocation',
  layer: 'MapLayer',
  expandable: 'Expandable',
  here: 'MapHere',
  calendar: 'Calendar',
  'ad.flag': 'AdFlag',
  'ad.menu': 'AdMenu',
  'ad.flyover': 'AdFlyOver',
  'ad.banner': 'AdBanner',
  'ad.bubble': 'AdBubble',
  'ad.banneritem': 'AdBannerItem',
  'sidebar.detail': 'SidebarDetail',
  // left for compatibility with old PD - should be deleted
  timetable: 'TimeTable',
  image: 'Picture',
  video: 'Video',
  web: 'Web',
  pdf: 'Pdf',
  'menu.header': 'MenuHeader',
  'menu.item': 'MenuItem',
  'menu.link': 'MenuLink',
  linebreak: 'LineBreak',
  slideshow: 'SlideShow',
  screensaver: 'Screensaver',
  'hub.marker': 'HubMarker',
  '#trigger-error#': null
};
/**
 * Mapping of presentation data types to component types (names). Provides a
 * function to check if the mapping exists. If not, it falls back to the Simple
 * component.
 *
 * @alias componentMap
 */

var componentNameByType = function componentNameByType(type, options) {
  var map = {};

  if (options && options.componentMap) {
    map = options.componentMap;

    if (typeof options.componentMap === 'string') {
      map = viewToComponentMap[options.componentMap];
    }
  }

  if (map && type in map) {
    return options.componentMap[type];
  }

  if (type in dataTypeToComponentType) {
    return dataTypeToComponentType[type];
  }

  return 'Simple';
};

exports.componentNameByType = componentNameByType;
var _default = {
  componentNameByType: componentNameByType,
  filterByView: filterByView,
  componentMapByView: componentMapByView
};
exports.default = _default;