// function that help to follow the structure used for sizing images on customdata
// structure: /files/{:id}-{:size}?-.{:ext|:webp}?

// we need to keep this in sync with customdata config options
export const MEDIA_SIZES = {
  medium: 460,
  large: 690,
  xlarge: 960,
  hd: 1920,
  '2k': 2560,
  '4k': 3840,
}

// construct url based on parts
export function constructUrl ({ id, size, extension }) {
  if (!id) return false
  return '/files/' + id +
    (size ? '-' + size : '') +
    (extension ? '.' + extension : '')
}

// get parts of the url
export function destructUrl (url) {
  const parts = /(\/files\/)([a-f\d]{24})(-\w+)?(\.\w+)?/i.exec(url)
  if (parts) {
    return {
      id: parts[2],
      size: parts[3] && parts[3].replace('-', ''),
      extension: parts[4] && parts[4].replace('.', ''),
    }
  } else {
    // console.warn('Supplied URL does not match customdata structure.')
    return false
  }
}

// get url with size parameter based on existing url
export function getSizedUrl (url, size, webp) {
  const parts = destructUrl(url)

  // reuse basepath of url if it's a full path
  let basePath = ''
  if (url.startsWith('http')) {
    basePath = url.split('/files/')
    basePath.pop()
    basePath = basePath.join('/')
  }

  return basePath + constructUrl({ id: parts.id, size, extension: webp ? 'webp' : parts.extension })
}

// generate all possible/preset size urls and return them with the width
export function getSrcSetUrls (url, webp) {
  return Object.entries(MEDIA_SIZES).map(([ name, size ]) =>
    [ size, getSizedUrl(url, name, webp), destructUrl(url).extension ]
  )
}
